import styled from 'styled-components'

import { Sprite } from 'src/shared/ui/Sprite'
import { secondaryGrey } from 'src/theme/colors'

import { ActionTitle } from './ActionTitle'
import { IconWrapper } from './IconWrapper'

interface WebsiteButtonProps {
  url?: string
  title?: string
}

export function WebsiteButton({ url = '', title = '' }: WebsiteButtonProps) {
  if (!url) return null

  return (
    <Root>
      <Link
        href={url}
        target="_blank"
        rel="noopener"
        data-test="actions.websiteButton"
      >
        <IconWrapper>
          <Sprite name="misc/globe" />
        </IconWrapper>
      </Link>

      <ActionTitle>{title}</ActionTitle>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Link = styled.a`
  color: ${secondaryGrey};
`
