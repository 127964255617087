import React, { useEffect, memo } from 'react'

import PropTypes from 'prop-types'

import useChatQuery from 'src/apollo/hooks/useChatQuery'
import useChatReadMutation from 'src/apollo/hooks/useChatReadMutation'
import Spinner from 'src/components/Spinner'
import Chat from 'src/sections/SectionNavigator/Chat'
import ChatThreadHeader from 'src/sections/SectionNavigator/NavigatorHeader/ChatThreadHeader'
import { SpinnerWrapper } from 'src/sections/SectionNavigator/styled'
import usePageVisibility from 'src/utils/hooks/api/usePageVisibility'

function ChatThread({
  chatId = '',
  setNavigatorsSubject,
  navigateToInbox,
  navigatorsSubject,
  hasInboxTotalCount,
  isLoadingData,
  isNavigatorOnline,
}) {
  const variables = {
    id: chatId,
    first: 15,
  }

  const { handleChatRead } = useChatReadMutation()
  const isPageVisible = usePageVisibility()
  const {
    chatData,
    refetchChatThread,
    subscribeChatThreadToEvent,
    chatDataLoading,
    fetchMore,
  } = useChatQuery(variables)

  useEffect(() => {
    // if chat thread was archived it must closed
    if (chatData.isInactive) {
      navigateToInbox()
    }
  }, [chatData.isInactive])

  useEffect(() => {
    refetchChatThread()
  }, [])

  useEffect(() => {
    if (isPageVisible) {
      refetchChatThread()
    }
  }, [isPageVisible])

  useEffect(() => {
    setNavigatorsSubject(chatData.subject)
  }, [chatData.subject])

  useEffect(() => {
    const unsubscribeChatThreadToEvent =
      subscribeChatThreadToEvent(handleChatRead)

    return unsubscribeChatThreadToEvent
  }, [handleChatRead, isPageVisible])

  return (
    <>
      <ChatThreadHeader
        navigateToInbox={navigateToInbox}
        hasInboxTotalCount={hasInboxTotalCount}
        chatId={chatId}
        navigatorsSubject={navigatorsSubject}
        isNavigatorOnline={isNavigatorOnline}
      />
      {isLoadingData && (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      )}
      {!isLoadingData && (
        <Chat
          chatId={chatId}
          chatData={chatData}
          chatDataLoading={chatDataLoading}
          fetchMore={fetchMore}
        />
      )}
    </>
  )
}

export default memo(ChatThread)

ChatThread.propTypes = {
  chatId: PropTypes.string,
  setNavigatorsSubject: PropTypes.func.isRequired,
  navigateToInbox: PropTypes.func.isRequired,
  navigatorsSubject: PropTypes.string.isRequired,
  hasInboxTotalCount: PropTypes.bool.isRequired,
  isNavigatorOnline: PropTypes.bool.isRequired,
  isLoadingData: PropTypes.bool.isRequired,
}
