import { TypedDocumentNode, gql } from '@apollo/client'

import { INSURANCE_INFO_FRAGMENT } from '../insuranceInfo'

import { InsuranceInfoQueryResult, InsuranceInfoQueryVariables } from './types'

export const INSURANCE_INFO_QUERY: TypedDocumentNode<
  InsuranceInfoQueryResult,
  InsuranceInfoQueryVariables
> = gql`
  query insuranceInfoQuery {
    currentUser {
      id
      insuranceInfo: insurance_info {
        ...insuranceInfo
      }
    }
  }
  ${INSURANCE_INFO_FRAGMENT}
`
