import { ForwardedRef, forwardRef, useCallback, useMemo } from 'react'

import { useUnit } from 'effector-react'
import styled from 'styled-components'

import { $$chat } from 'src/entities/chat'
import { TimeFormatter } from 'src/shared/lib/formatters'
import { Sprite } from 'src/shared/ui/Sprite'
import {
  featurePaleBlue,
  primaryBlue,
  primaryDarkNavy,
  secondaryGrey,
  secondaryLightGrey,
} from 'src/theme/colors'
import { text4_10, textLink1_16 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { ChatBrief } from '../../api/chatBrief'

import { ChatStatus } from './ChatStatus'
import { MessagePreview } from './MessagePreview'

interface ChatPreviewProps {
  chat: ChatBrief
}

function ChatPreviewComponent(
  { chat }: ChatPreviewProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const open = useUnit($$chat.open)

  const updatedAt = useMemo(
    () => TimeFormatter.updatedAt(chat.updatedAt),
    [chat.updatedAt],
  )

  const onClick = useCallback(() => open(chat.id), [open, chat.id])

  return (
    <Card
      ref={ref}
      onClick={onClick}
      data-test="chatPreview"
      data-unread={chat.isUnread}
    >
      <Subject>{chat.subject}</Subject>
      <ChatStatus chat={chat} />

      <UpdatedAt>{updatedAt}</UpdatedAt>

      {chat.preview && <MessagePreview preview={chat.preview} />}

      <Arrow />
    </Card>
  )
}

const Card = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-template-rows: max-content 1fr;

  grid-template-areas:
    'title badge time'
    'preview preview preview';

  row-gap: ${convertPxToRem(4)};
  column-gap: ${convertPxToRem(8)};

  align-items: center;
  justify-items: start;

  padding: ${convertPxToRem(18, 24)};

  cursor: pointer;
  position: relative;
  color: ${secondaryGrey};

  &:not(:last-child) {
    border-bottom: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  }

  &[data-unread='true'] {
    background-color: ${featurePaleBlue};
  }
`

const Subject = styled.span`
  ${textLink1_16};
  line-height: ${convertPxToRem(22)};

  grid-area: title;

  color: ${primaryDarkNavy};
`

const UpdatedAt = styled.span`
  ${text4_10};
  text-align: right;

  grid-area: time;

  padding-right: ${convertPxToRem(8)};
`

const Arrow = styled(Sprite).attrs({ name: 'misc/chevron' })`
  position: absolute;

  width: ${convertPxToRem(12)};
  height: ${convertPxToRem(12)};

  color: ${primaryBlue};

  top: ${convertPxToRem(23)};
  right: ${convertPxToRem(16)};
`

export const ChatPreview = forwardRef(ChatPreviewComponent)
