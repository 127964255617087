import styled from 'styled-components'

import { NEW_THREAD_ID } from 'src/entities/chat'
import { secondaryBackgroundGrey } from 'src/theme/colors'

import { ThreadIdContext } from '../lib/ThreadIdContext'

import { ChatActions } from './Actions'
import { ChatContent } from './ChatContent'
import { ChatHeader } from './Header/ChatHeader'
import { ChatPresets } from './Presets'

interface ChatThreadProps {
  id: string
}

export function ChatThread({ id }: ChatThreadProps) {
  const content = id === NEW_THREAD_ID ? <ChatPresets /> : <ChatContent />

  return (
    <ThreadIdContext.Provider value={id}>
      <Wrapper>
        <ChatHeader />

        {content}

        <ChatActions />
      </Wrapper>
    </ThreadIdContext.Provider>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: max-content 1fr max-content;
  grid-template-columns: 1fr;

  grid-auto-flow: column;

  height: 100%;

  background-color: ${secondaryBackgroundGrey};
`
