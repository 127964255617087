import React from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Spinner from 'src/components/Spinner'
import ExclamationIcon from 'src/components/SvgIcons/ExclamationIcon'
import { FILE_SCAN_STATUS } from 'src/constants/uploadConstants'
import {
  secondaryGrey,
  primaryOrange,
  statusErrorRed,
  statusActive,
} from 'src/theme/colors'
import { text3_12 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

export default function ChatAttachmentScanStatus({ scanStatus, isSelfSent }) {
  const { t } = useTranslation()
  const i18n = {
    scanStatusInfected: t('translation.shared.scan_status.infected'),
    scanStatusFailed: t('translation.shared.scan_status.failed'),
    scanStatusUploading: t('translation.shared.scan_status.uploading'),
    scanStatusSucceess: t('translation.shared.scan_status.delivered'),
    scanStatusNotAvailable: t('translation.shared.scan_status.not_available'),
    scanStatusSanitizationFailed: t(
      'translation.shared.scan_status.sanitization_failed',
    ),
  }
  const STATUSES = {
    [FILE_SCAN_STATUS.CLEAN]: {
      text: i18n.scanStatusSucceess,
      textColor: statusActive,
      displayErrImg: false,
      displayLoader: false,
    },
    [FILE_SCAN_STATUS.SANITIZED]: {
      text: i18n.scanStatusSucceess,
      textColor: statusActive,
      displayErrImg: false,
      displayLoader: false,
    },
    [FILE_SCAN_STATUS.NOT_CHECKED]: {
      text: '',
      textColor: secondaryGrey,
      displayErrImg: false,
      displayLoader: false,
    },
    [FILE_SCAN_STATUS.STARTED]: {
      text: i18n.scanStatusUploading,
      textColor: secondaryGrey,
      displayErrImg: false,
      displayLoader: true,
    },
    [FILE_SCAN_STATUS.INVALID_LINK]: {
      text: isSelfSent ? i18n.scanStatusFailed : i18n.scanStatusNotAvailable,
      textColor: statusErrorRed,
      displayErrImg: true,
      displayLoader: false,
    },
    [FILE_SCAN_STATUS.INFECTED]: {
      text: isSelfSent ? i18n.scanStatusInfected : i18n.scanStatusNotAvailable,
      textColor: statusErrorRed,
      displayErrImg: true,
      displayLoader: false,
    },
    [FILE_SCAN_STATUS.SANITIZATION_FAILED]: {
      text: i18n.scanStatusSanitizationFailed,
      textColor: statusErrorRed,
      displayErrImg: true,
      displayLoader: false,
    },
  }

  return (
    <ScanStatusWrapper isSelfSent={isSelfSent}>
      {STATUSES[scanStatus]?.displayErrImg && (
        <IconContainer>
          <ExclamationIcon
            width={convertPxToRem(12)}
            height={convertPxToRem(12)}
            fill={statusErrorRed}
          />
        </IconContainer>
      )}
      {STATUSES[scanStatus]?.displayLoader && (
        <SpinnerContainer>
          <Spinner size="smallest" color={primaryOrange} />
        </SpinnerContainer>
      )}
      <TextWrapper color={STATUSES[scanStatus]?.textColor}>
        {STATUSES[scanStatus]?.text}
      </TextWrapper>
    </ScanStatusWrapper>
  )
}

const ScanStatusWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: ${({ isSelfSent }) =>
    isSelfSent
      ? `${convertPxToRem(-12, 0, 20, 8)}`
      : `${convertPxToRem(-12, 8, 20, 0)}`};
`
const SpinnerContainer = styled.div`
  flex: 0;
`
const IconContainer = styled.div`
  margin-right: ${convertPxToRem(4)};
`
const TextWrapper = styled.span`
  color: ${({ color }) => color && color};
  ${text3_12};
`
ChatAttachmentScanStatus.propTypes = {
  scanStatus: PropTypes.string.isRequired,
  isSelfSent: PropTypes.bool.isRequired,
}
