import React, { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import {
  AppointmentDoctor,
  DoctorAddress,
} from '../../api/appointmentQuery/types'
import {
  pickDoctorAddress,
  pickDoctorInitials,
  pickDoctorSpecialty,
} from '../../lib/doctor'

import { AppointmentProfile } from './AppointmentProfile'
import {
  AppointmentProfileImage,
  AppointmentProfileInitials,
} from './AppointmentProfileImage'

interface AppointmentDoctorInfoProps {
  doctor: AppointmentDoctor
  savedAddress: DoctorAddress

  showRatingTooltip: () => void
}

export function AppointmentDoctorInfo({
  doctor,
  savedAddress,
  showRatingTooltip,
}: AppointmentDoctorInfoProps) {
  const { t } = useTranslation()

  const { image, rating, cost } = doctor

  const specialty = useMemo(() => pickDoctorSpecialty(doctor), [doctor])
  const initials = useMemo(() => pickDoctorInitials(doctor), [doctor])

  const { address, distance } = useMemo(
    () => pickDoctorAddress(doctor.addresses, savedAddress),
    [doctor.addresses, savedAddress],
  )

  const i18n = {
    name: t('translation.appointments.assignee.doctor', doctor),
  }

  const avatar = image ? (
    <AppointmentProfileImage src={image} alt={initials} />
  ) : (
    <AppointmentProfileInitials>{initials}</AppointmentProfileInitials>
  )

  return (
    <AppointmentProfile
      name={i18n.name}
      specialty={specialty}
      image={avatar}
      rating={rating}
      cost={cost.userPays}
      address={address}
      distance={distance}
      onRatingToolipClicked={showRatingTooltip}
      dataTestProp="appointmentDoctorProfile"
    />
  )
}
