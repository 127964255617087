import { memo } from 'react'

import styled, { css } from 'styled-components'

import Spinner from 'src/components/Spinner'
import { FILE_SCAN_STATUS } from 'src/constants/uploadConstants'
import { useShortLink } from 'src/entities/userUpload'
import { UploadFileType } from 'src/shared/api/types'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import ChatAttachmentWrapper from './ChatAttachmentWrapper'

interface LinkDataProps {
  isPdf: boolean
  attachedFileUrl: string
  scanStatus: string
}

interface ChatAttachmentFileLinkProps {
  linkData: LinkDataProps
  messageId: string
  hasMessageBody: boolean
  isSelfSent: boolean
}

function ChatAttachmentFileLink({
  linkData,
  hasMessageBody,
  isSelfSent,
  messageId,
}: ChatAttachmentFileLinkProps) {
  const { isPdf, scanStatus, attachedFileUrl } = linkData

  const { fileSrc, isLoading } = useShortLink({
    id: messageId,
    type: UploadFileType.ChatAttachment,
    link: attachedFileUrl,
  })

  const isUnavailableFile = [
    FILE_SCAN_STATUS.INFECTED,
    FILE_SCAN_STATUS.INVALID_LINK,
    FILE_SCAN_STATUS.SANITIZATION_FAILED,
  ].includes(scanStatus)

  if (isUnavailableFile) {
    return (
      <FileWrapper
        data-message-body={hasMessageBody}
        data-self-sent={isSelfSent}
        data-unavailable={isUnavailableFile}
      >
        {!isLoading && (
          <ChatAttachmentWrapper
            isPdf={isPdf}
            isUnavailableFile={isUnavailableFile}
            fileSrc={fileSrc}
          />
        )}
      </FileWrapper>
    )
  }

  if (isLoading) {
    return (
      <Overlay>
        <Spinner size="small" />
      </Overlay>
    )
  }

  return (
    <FileLink
      href={fileSrc as string}
      target="_blank"
      rel="noreferrer noopener"
      data-message-body={hasMessageBody}
      data-self-sent={isSelfSent}
      data-unavailable={isUnavailableFile}
    >
      {!isLoading && (
        <ChatAttachmentWrapper
          isPdf={isPdf}
          isUnavailableFile={isUnavailableFile}
          fileSrc={fileSrc}
        />
      )}
    </FileLink>
  )
}

const Wrapper = css`
  margin-top: 0;
  align-self: flex-start;
  cursor: pointer;

  &[data-message-body='true'] {
    margin-top: ${convertPxToRem(16)};
  }

  &[data-self-sent='true'] {
    align-self: flex-end;
  }

  &[data-unavailable='true'] {
    cursor: default;
  }
`

const FileWrapper = styled.div`
  ${Wrapper}
`

const FileLink = styled.a`
  ${Wrapper}
`

const Overlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default memo(ChatAttachmentFileLink)
