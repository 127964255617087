import { pathOr } from 'ramda'

import { featurePaleYellow, secondarySoftGrey } from 'src/theme/colors'

export const LABEL_COLOR_REFILL_STATUS = {
  ready_to_refill: featurePaleYellow,
  no_refills: secondarySoftGrey,
  too_early: secondarySoftGrey,
  pending: featurePaleYellow,
  approved: featurePaleYellow,
  denied: featurePaleYellow,
  dismissed: featurePaleYellow,
  withdrawn: featurePaleYellow,
  partial_approval: featurePaleYellow,
}

export const getRefillColor = (refStatus, reqStatus) => {
  if (refStatus) {
    return LABEL_COLOR_REFILL_STATUS[refStatus]
  }
  return LABEL_COLOR_REFILL_STATUS[reqStatus]
}

export const extractCareProgramData = (program) => ({
  id: program?.id || '',
  title: program?.title || '',
  status: program?.status || '',
})

export const extractCareProgramsData = (data) => {
  const carePrograms = data?.carePrograms?.nodes || []
  return carePrograms.map(extractCareProgramData)
}

export const extractMedicationPricesData = (data) => {
  const medicationPrices = pathOr({}, ['medicationPrices'], data)
  const topPharmacies = pathOr([], ['top_pharmacies'], medicationPrices)
  const mailPharmacies = medicationPrices?.mail_pharmacies ?? []
  const mailOpportunities = medicationPrices?.mail_opportunities ?? []
  return {
    mailOrder: {
      title: medicationPrices?.mail_order_subscription?.title ?? '',
      description: medicationPrices?.mail_order_subscription?.description ?? '',
      ctaName: medicationPrices?.mail_order_subscription?.cta_name ?? '',
      ctaUrl: medicationPrices?.mail_order_subscription?.cta_url ?? '',
    },
    hasMailOrderData: Boolean(medicationPrices?.mail_order_subscription),
    mailPharmacies,
    mailOpportunities: mailOpportunities.map((item) => ({
      current: item?.current ?? false,
      name: item?.name ?? '',
      deliveryTime: item?.delivery_time ?? '',
      daysSupply: Number(item?.days_supply) || 0,
      price: {
        priceId: item?.price?.price_id ?? '',
        newPriceAmount: parseNumberToFixedFloat(item?.price?.new_price_amount),
        currentPriceAmount: parseNumberToFixedFloat(
          item?.price?.current_price_amount,
        ),
        savingsAmount: parseNumberToFixedFloat(item?.price?.savings_amount),
        isBestPrice: item?.price?.is_best_price ?? false,
      },
    })),
    topPharmacies: topPharmacies.map((item) => ({
      current: item?.current ?? false,
      name: item?.name ?? '',
      nabp: item?.nabp ?? '',
      phoneNumber: item?.phone_number ?? '',
      address: {
        address: item?.address?.address ?? '',
        addressLine1: item?.address?.address_line_1 ?? '',
        addressLine2: item?.address?.address_line_2 ?? '',
        street: item?.address?.street ?? '',
        city: item?.address?.city ?? '',
        state: item?.address?.state ?? '',
        zip: item?.address?.zip ?? '',
        latitude: item?.address?.latitude ?? '',
        longitude: item?.address?.longitude ?? '',
      },
      workSchedule: item?.work_schedule.map((schedule) => ({
        dayOfWeek: schedule.day_of_week ?? '',
        openHour: schedule.open_hour ?? '',
        closeHour: schedule.close_hour ?? '',
      })),
      price: {
        patientPayType: item?.price?.patient_pay_type ?? '',
        patientPayAmount: parseNumberToFixedFloat(
          item?.price?.patient_pay_amount,
        ),
        ingredientCost: parseNumberToFixedFloat(item?.price?.ingredient_cost),
        planPayAmount: parseNumberToFixedFloat(item?.price?.plan_pay_amount),
        planName: item?.price?.plan_name ?? '',
        isBestPrice: Boolean(item?.price?.is_best_price),
      },
      pharmacyPrice: {
        insurancePay: parseNumberToFixedFloat(
          item?.pharmacy_price?.insurance_pay,
        ),
        isBestPrice: Boolean(item?.pharmacy_price?.is_best_price),
        patientPay: parseNumberToFixedFloat(item?.pharmacy_price?.patient_pay),
        priceId: item?.pharmacy_price?.price_id,
      },
    })),
    lastPaid: parseNumberToFixedFloat(
      pathOr('0', ['last_paid'], medicationPrices),
    ),
  }
}

export const extractMedicationPriceBreakdownData = (data) => ({
  patientPayType: data?.medicationPriceBreakdown?.patient_pay_type ?? '',
  patientPayAmount: parseNumberToFixedFloat(
    data?.medicationPriceBreakdown?.patient_pay_amount,
  ),
  ingredientCost: parseNumberToFixedFloat(
    data?.medicationPriceBreakdown?.ingredient_cost,
  ),
  planPayAmount: parseNumberToFixedFloat(
    data?.medicationPriceBreakdown?.plan_pay_amount,
  ),
  planName: data?.medicationPriceBreakdown?.plan_name ?? '',
})

const parseNumberToFixedFloat = (value) => {
  const parsedValue = parseFloat(value)
  if (!!parsedValue && !Number.isNaN(parsedValue)) {
    return parsedValue.toFixed(2)
  }
  return '0'
}

export const DEFAULT_ZIP = '10001'

export const getIdFromURL = (url) => {
  const parts = url.split('/')
  return {
    medication_id: parts[parts.length - 2],
    employee_id: parts[parts.length - 3],
  }
}

export const UNSPECIFIED_ENUM_VALUE = 'unspecified'
