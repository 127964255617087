import { useCallback, useState } from 'react'

import moment from 'moment'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import CameraModal from 'src/components/AttachComponents/CameraModal'
import { isMobilePlatform } from 'src/components/AttachComponents/uploadHelpers'
import {
  FILE_SIZE_LIMIT_BYTES,
  IMG_PDF_UPLOAD_TYPES,
  IMG_PDF_UPLOAD_TYPES_ARRAY,
} from 'src/constants/uploadConstants'
import { NativeAlert } from 'src/shared/ui/alerts/NativeAlert'
import { NativeAlertAction } from 'src/shared/ui/alerts/NativeAlertAction'
import {
  secondaryLightGrey,
  primaryWhite,
  secondaryGrey,
} from 'src/theme/colors'
import { textLink2_14 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function ModalActionsChatThread({
  sendMessage,
  disabled,
  id,
  cameraTheme,
  fileContainer,
  setIsShowModal,
}) {
  const { t } = useTranslation()
  const i18n = {
    takePhotoButton: t('translation.shared.buttons.button_take_photo'),
    attachFilesButton: t('translation.chat.thread.input.button_attach'),
    sizeError: t('translation.chat.thread.error_message'),
    buttonText: t('translation.shared.buttons.okay'),
    largeFileErrorDescription: t(
      'translation.chat.large_file_error.description',
    ),
    largeFileErrorTitle: t('translation.chat.large_file_error.title'),
    wrongFileFormatTitle: t(
      'translation.shared.special.wrong_file_format_title',
    ),
    wrongFileFormatDescription: t(
      'translation.shared.special.wrong_file_format_description',
    ),
    buttonOk: t('translation.shared.buttons.button_ok'),
  }

  const [isShowWebCamModal, setIsShowWebCamModal] = useState(false)
  const [imgSrc, setImgSrc] = useState(null)

  // eslint-disable-next-line no-unused-vars
  const [uploadFileError, setUploadFileError] = useState('')

  const [isWrongFileFormat, setIsWrongFileFormat] = useState(false)

  const sendMessageWithFile = (file) => {
    // fileContainer is a ref
    // eslint-disable-next-line no-param-reassign
    fileContainer.current = file
    sendMessage()

    setIsShowModal(false)
  }

  const handleErrorClick = useCallback(() => {
    setUploadFileError('')
  }, [])

  const onFileAttached = async (event) => {
    const file = event.target?.files?.[0]

    if (!IMG_PDF_UPLOAD_TYPES_ARRAY.includes(file?.type)) {
      setIsWrongFileFormat(true)
      return
    }

    const isCorrectSize = file?.size <= FILE_SIZE_LIMIT_BYTES
    if (!isCorrectSize) {
      setUploadFileError(i18n.sizeError)
      return
    }

    sendMessageWithFile(file)
  }

  const openWebCamModal = useCallback(() => {
    setIsShowWebCamModal((isShowModal) => !isShowModal)
  }, [setIsShowWebCamModal])

  const goBack = useCallback(() => {
    setIsShowWebCamModal(false)
    setImgSrc(null)
  }, [setIsShowWebCamModal])

  const confirmAndSend = () => {
    const fileName = `${moment().format('YYYY-M-DD_h-mm-ss')}.jpeg`
    const file = new File([imgSrc], fileName, {
      type: 'image/jpeg',
    })
    sendMessageWithFile(file)
  }
  const openMobileCameraModal = (event) => {
    const file = event.target.files[0]
    const fileName = `${moment().format('YYYY-M-DD_h-mm-ss')}.jpeg`
    if (!file.name) {
      file.name = fileName
    }

    sendMessageWithFile(file)
  }

  const handleModalDismiss = useCallback(() => setIsWrongFileFormat(false), [])

  return (
    <Root data-test="modalActionsChatThread">
      <ButtonActionsWrapper>
        <AttachFile
          data-test="modalActionsChatThread.openWebCamModal"
          onClick={() => !isMobilePlatform && openWebCamModal()}
        >
          <StyledLabel htmlFor="camera-input">
            {i18n.takePhotoButton}
          </StyledLabel>
          {isMobilePlatform && (
            <input
              id="camera-input"
              style={{ display: 'none' }}
              type="file"
              accept="images*/"
              capture="environment"
              onChange={openMobileCameraModal}
              disabled={disabled}
            />
          )}
        </AttachFile>

        {Boolean(uploadFileError) && (
          <NativeAlert
            dataTestProp="modalActionsChatThread.uploadFileError"
            title={i18n.largeFileErrorTitle}
            description={i18n.largeFileErrorDescription}
          >
            <NativeAlertAction
              data-test="modalActionsChatThread.uploadFileErrorAction"
              mode="secondary"
              onClick={handleErrorClick}
            >
              {i18n.buttonText}
            </NativeAlertAction>
          </NativeAlert>
        )}

        <AttachFile>
          <StyledLabel htmlFor={id}>{i18n.attachFilesButton}</StyledLabel>
          <Input
            data-test="modalActionsChatThread.attachFile"
            id={id}
            type="file"
            accept={IMG_PDF_UPLOAD_TYPES}
            onChange={onFileAttached}
            disabled={disabled}
          />
        </AttachFile>
      </ButtonActionsWrapper>
      {isShowWebCamModal && (
        <CameraModal
          setImgSrc={setImgSrc}
          attachFile={onFileAttached}
          cameraTheme={cameraTheme}
          goBack={goBack}
          imgSrc={imgSrc}
          confirmPhoto={confirmAndSend}
        />
      )}
      {isWrongFileFormat && (
        <NativeAlert
          dataTestProp="modalActionsChatThread.isWrongFileFormat"
          title={i18n.wrongFileFormatTitle}
          description={i18n.wrongFileFormatDescription}
        >
          <NativeAlertAction
            data-test="modalActionsChatThread.isWrongFileFormatAction"
            mode="secondary"
            onClick={handleModalDismiss}
          >
            {i18n.buttonOk}
          </NativeAlertAction>
        </NativeAlert>
      )}
    </Root>
  )
}

const Root = styled.div`
  position: absolute;
  overflow: auto;
  display: flex;
  border-radius: ${convertPxToRem(8)};
  box-shadow: ${convertPxToRem(0, 4, 15)} rgb(0, 0, 0, 0.15);
  bottom: ${convertPxToRem(59)};
  z-index: 1;
`
const AttachFile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${primaryWhite};
  height: ${convertPxToRem(52)};
  cursor: pointer;
  user-select: none;
  :first-child {
    border-bottom: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  }
`
const ButtonActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: ${convertPxToRem(196)};
  min-height: ${convertPxToRem(104)};
`
const Input = styled.input`
  display: none;
`
const StyledLabel = styled.label`
  color: ${secondaryGrey};
  ${textLink2_14};
  text-align: left;
  padding-left: ${convertPxToRem(20)};
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
`

ModalActionsChatThread.propTypes = {
  sendMessage: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  cameraTheme: PropTypes.string.isRequired,
  fileContainer: PropTypes.shape({
    current: PropTypes.instanceOf(File),
  }).isRequired,
  setIsShowModal: PropTypes.func.isRequired,
}

export default ModalActionsChatThread
