import { useCallback } from 'react'

import { useMutation } from '@apollo/client'
import moment from 'moment'
import { pathOr } from 'ramda'

import CHAT_START_MUTATION from 'src/apollo/mutations/CHAT_START_MUTATION'

const useChatStartMutation = () => {
  const [chatStartM, { loading }] = useMutation(CHAT_START_MUTATION)

  const handleCreateChat = useCallback(async () => {
    const today = moment().format('M/D/YY')

    const newChat = await chatStartM({
      variables: { attributes: { subject: `${today} Chat` } },
    })

    return pathOr('', ['data', 'startChat', 'id'], newChat)
  }, [chatStartM])

  return { handleCreateChat, chatStartLoading: loading }
}

export default useChatStartMutation
