import { StoreWritable, attach, createEvent, sample, scopeBind } from 'effector'

import { $client } from './client'
import { DarklyFlag } from './flag'

/**
 * Subscribe the flag to changes in LaunchDarkly via streaming.
 *
 * Once the flag is aquired, `watchFlag` creates a _permanent_ subscription
 * and will update the `$value` dynamically.
 */
export function watchFlag<T>({ aquire, $value, __: { key } }: DarklyFlag<T>) {
  const push = createEvent<T>()

  const subscribeFx = attach({
    source: $client,
    effect(client) {
      if (!client) return

      const callback = scopeBind(push, { safe: true })
      client.on(`change:${key}`, callback)
    },
  })

  sample({ clock: aquire, target: subscribeFx })
  sample({ clock: push, target: $value as StoreWritable<T> })
}
