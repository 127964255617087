import { ReactNode } from 'react'

import { useUnit } from 'effector-react'

import { DarklyFlag } from './flag'

interface DarklyShowProps {
  flag: DarklyFlag<boolean>

  fallback?: ReactNode
  children: ReactNode | ReactNode[]
}

function Show({ flag, fallback = null, children }: DarklyShowProps) {
  const isEnabled = useUnit(flag.$value)

  if (!isEnabled) return fallback

  return children
}

interface DarklyChooseProps {
  flag: DarklyFlag<boolean>
  enabled: ReactNode
  disabled: ReactNode
}

function Choose({ flag, enabled, disabled }: DarklyChooseProps) {
  const isEnabled = useUnit(flag.$value)

  return isEnabled ? enabled : disabled
}

export const Darkly = { Show, Choose }
