import React from 'react'

import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'

import ButtonBottom from 'src/components/Buttons/ButtonBottom'
import LogoIcon from 'src/components/SvgIcons/LogoIcon'
import {
  primaryLightBlue,
  primaryWhite,
  secondaryBackgroundGrey,
  secondaryGrey,
} from 'src/theme/colors'
import { heading2_29, heading6_16_Medium } from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import { PHONE_FORMATS, formatPhone } from 'src/utils/phoneHelpers'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

export function CrashModal() {
  const { t } = useTranslation()

  const i18n = {
    title: t('translation.shared.crashed.title'),
    email: t('translation.auth.signin.contact'),
    phone: t('translation.auth.failed.contact_phone'),
    description: t('translation.shared.crashed.description'),
    refresh: t('translation.shared.crashed.refresh'),
  }

  const phoneCallUri = formatPhone(i18n.phone, PHONE_FORMATS.uri) ?? ''
  const refreshPage = () => window.location.replace('/')

  return (
    <Background>
      <Wrapper data-test="crashModal">
        <LogoIcon />

        <Header>{i18n.title}</Header>

        <Message>{i18n.description}</Message>
        <Trans
          parent={Message}
          t={t}
          i18nKey="translation.shared.crashed.contact_us"
          values={{ email: i18n.email, phone: i18n.phone }}
          components={{
            emailLink: <ContactLink href={`mailto:${i18n.email}`} />,
            phoneLink: <ContactLink href={phoneCallUri} />,
          }}
        />

        <ButtonBottom
          dataTestProp="crashModal.refresh"
          theme="primary"
          title={i18n.refresh}
          handleClick={refreshPage}
        />
      </Wrapper>
    </Background>
  )
}

const Background = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: ${secondaryBackgroundGrey};
  width: 100%;
  height: 100%;
`

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 100%;
  grid-template-rows:
    ${convertPxToRem(188)}
    max-content
    max-content
    max-content;

  gap: ${convertPxToRem(16)};

  background: ${primaryWhite};

  width: ${convertPxToRem(500)};
  height: ${convertPxToRem(666)};

  border-radius: ${convertPxToRem(44)};
  padding: ${convertPxToRem(30, 32, 26, 32)};

  text-align: center;
  justify-items: center;

  > svg {
    align-self: center;

    width: ${convertPxToRem(100)};
    height: ${convertPxToRem(100)};
  }

  > button {
    align-self: end;
  }

  ${mediaQueryFor.mobile} {
    height: 100%;
    width: 100%;
    border-radius: none;

    grid-template-rows:
      1fr
      max-content
      max-content
      1fr;
  }
`

const Header = styled.h1`
  ${heading2_29};
`

const Message = styled.p`
  ${heading6_16_Medium};

  color: ${secondaryGrey};

  max-width: ${convertPxToRem(284)};
`

const ContactLink = styled.a`
  color: ${primaryLightBlue};
`
