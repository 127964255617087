import { useCallback } from 'react'

import { list, variant } from '@effector/reflect'
import { WindowLocation, useLocation, useNavigate } from '@reach/router'
import styled from 'styled-components'

import { ROUTES } from 'src/constants/routesConstants'
import { $specialtyPharmacies } from 'src/entities/drugDetails'
import { HoverButton } from 'src/features/HoverButton'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { primaryDarkNavy } from 'src/theme/colors'
import { capsHeading1_12 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { $sortedOtherPharmacies } from '../../model'
import { PharmaciesSorting } from '../PharmaciesSorting'

import { DrugDetailsLocation } from './DrugDetailsLocation'
import { NotAvailableMessage } from './NotAvailableMessage'
import { PharmacySelector } from './PharmacySelector'

interface LocationState {
  drugName?: string
}

function DrugDetailsOtherPharmaciesView() {
  const t = useScopedTranslation('translation.medications.drug_search.labels')

  const navigate = useNavigate()
  const { state } = useLocation() as WindowLocation<LocationState>
  const { drugName = '' } = state ?? {}

  const navigateToMap = useCallback(() => {
    void navigate(ROUTES.DRUG_SEARCH_DETAILS_MAP, { state: { drugName } })
  }, [drugName, navigate])

  return (
    <Section>
      {/* Will be replaced with the common widget in NEX-18282 */}
      <DrugDetailsLocation drugName={drugName} />

      <SectionHeader>
        <Title>{t('retail_pharmacies')}</Title>
        <PharmaciesSorting />
      </SectionHeader>
      <PharmaciesList />
      <MapButtonContainer>
        <MapButton>
          <HoverButton.Map onClick={navigateToMap} dataTestProp="mapButton" />
        </MapButton>
      </MapButtonContainer>
    </Section>
  )
}

function EmptyOtherPharmacies() {
  const t = useScopedTranslation('translation.medications.drug_search.labels')

  return (
    <Section>
      <Title>{t('other_pharmacies')}</Title>

      <NotAvailableMessage />
    </Section>
  )
}

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const Title = styled.span`
  display: flex;

  color: ${primaryDarkNavy};
  ${capsHeading1_12};
  text-transform: uppercase;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertPxToRem(8)};
`

const MapButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

const MapButton = styled(HoverButton)`
  position: fixed;
  bottom: ${convertPxToRem(40)};
`

const PharmaciesList = list({
  view: PharmacySelector,
  source: $sortedOtherPharmacies,
  mapItem: {
    pharmacyData: (pharmacy) => pharmacy,
  },
  getKey: (pharmacy) => pharmacy.priceId,
})

const DrugDetailsOtherPharmacies = variant({
  if: $sortedOtherPharmacies.map((pharmacies) => pharmacies.length > 0),
  then: DrugDetailsOtherPharmaciesView,
  else: EmptyOtherPharmacies,
})

export const OtherPharmaciesList = variant({
  if: $specialtyPharmacies.map((pharmacies) => pharmacies.length === 0),
  then: DrugDetailsOtherPharmacies,
})
