import { ForwardedRef, forwardRef, useMemo } from 'react'

import ReactLinkify from 'react-linkify'
import styled from 'styled-components'

import { linkDecorator } from 'src/shared/ui/LinkDecorator'
import { Linkify } from 'src/utils/locationHelpers'
import { linkifyPhoneNumbersInText } from 'src/utils/phoneHelpers'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { ChatMessage } from '../../api/chatMessage'

import { MessageBubble } from './MessageBubble'
import { UpdatedAt } from './UpdatedAt'

interface TextMessageProps {
  message: ChatMessage
}

function TextMessageComponent(
  { message }: TextMessageProps,
  ref: ForwardedRef<HTMLElement>,
) {
  const body = useMemo(
    () => linkifyPhoneNumbersInText(message.body ?? ''),
    [message.body],
  )

  return (
    <Wrapper
      data-owned={message.isOwned}
      // @ts-expect-error: ref HTMLDivElement is actually assignable to ref HTMLElement
      ref={ref}
    >
      <MessageBubble>
        <ReactLinkify matchDecorator={match} componentDecorator={linkDecorator}>
          {body}
        </ReactLinkify>
      </MessageBubble>

      <UpdatedAt at={message.updatedAt!} />
    </Wrapper>
  )
}

const match = (text: string) => Linkify.match(text)

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;

  gap: ${convertPxToRem(8)};

  align-items: start;

  &[data-owned='true'] {
    align-items: end;
  }

  &:not(:first-of-type) > ${UpdatedAt} {
    display: none;
  }
`

export const TextMessage = forwardRef(TextMessageComponent)
