import React, { memo, useCallback, useState } from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Spinner from 'src/components/Spinner'
import InboxItem from 'src/sections/SectionNavigator/Inbox/InboxItem'
import { secondaryGrey } from 'src/theme/colors'
import { text2_14 } from 'src/theme/fonts'
import usePagination from 'src/utils/hooks/usePagination'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function InboxList({
  searchValue = '',
  inboxData = [],
  navigateToChatThread = () => {},
  newMessagesIdsList = [],
  hasSearchInboxData = false,
  inboxFetchMore = () => {},
  inboxPageInfo = {},
  inboxFetchMoreLoading = false,
}) {
  const { t } = useTranslation()
  const i18n = {
    emptySearch: t('translation.chat.inbox.with_messages.empty_search'),
  }

  const [scrollRef, setScrollRef] = useState({ current: null })
  const isShowDefaultText = !!searchValue && !hasSearchInboxData
  const lastItem = inboxData.length - 1
  usePagination({
    dataPath: ['chats'],
    fetchMore: inboxFetchMore,
    pageInfo: inboxPageInfo,
    scrollRef: scrollRef.current,
    fetchMoreLoading: inboxFetchMoreLoading,
  })

  const containerRef = useCallback((node) => {
    if (node !== null) {
      setScrollRef({ current: node })
    }
  }, [])

  return (
    <List data-test="chatsList" ref={containerRef}>
      {isShowDefaultText ? (
        <SearchDefaultText>{i18n.emptySearch}</SearchDefaultText>
      ) : (
        inboxData.map((item, index) => (
          <InboxItem
            key={item.chatId}
            item={item}
            newMessagesIdsList={newMessagesIdsList}
            navigateToChatThread={navigateToChatThread}
            isLastItem={lastItem === index}
            nextMessageId={
              index !== lastItem ? inboxData[index + 1]?.chatId : ''
            }
          />
        ))
      )}
      {inboxFetchMoreLoading && <Spinner />}
    </List>
  )
}

export default memo(InboxList)

InboxList.propTypes = {
  searchValue: PropTypes.string,
  inboxFetchMoreLoading: PropTypes.bool,
  hasSearchInboxData: PropTypes.bool,
  navigateToChatThread: PropTypes.func,
  inboxData: PropTypes.arrayOf(PropTypes.shape({})),
  inboxFetchMore: PropTypes.func,
  inboxPageInfo: PropTypes.shape({}),
  newMessagesIdsList: PropTypes.arrayOf(PropTypes.string),
}

const List = styled.ul`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
`
const SearchDefaultText = styled.span`
  padding: 0 ${convertPxToRem(24)};
  color: ${secondaryGrey};
  ${text2_14}
`
