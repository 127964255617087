import React from 'react'

import { AppointmentFacility } from '../../api/appointmentQuery/types'

import { AppointmentProfile } from './AppointmentProfile'

interface AppointmentFacilityInfoProps {
  facility: AppointmentFacility

  showRatingTooltip: () => void
}

export function AppointmentFacilityInfo({
  facility,
  showRatingTooltip,
}: AppointmentFacilityInfoProps) {
  const { name, address, distance, cost, rating } = facility

  return (
    <AppointmentProfile
      name={name}
      rating={rating}
      cost={cost.userPays}
      address={address}
      distance={distance}
      onRatingToolipClicked={showRatingTooltip}
      dataTestProp="appointmentFacilityProfile"
    />
  )
}
