import { InsuranceInfo } from 'src/entities/insurance'
import { BillClaimCategory, BillClaimStatus } from 'src/shared/api/types'

// TODO: remove this when we support graphql types via separate package
interface IGraphQLBillClaimsList {
  billClaims: {
    pageInfo: {
      endCursor: string
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor: string
      totalCount: string
    }
    nodes: IGraphQLBillClaim[]
  }
}

interface IGraphQLBillClaim {
  id: string

  amount_saved: number
  originally_billed: number

  claim_category: BillClaimCategory
  claim_category_name: string

  created_at: string
  resolved_at: string | null

  medical_bills: IGraphQLMedicalBill[]

  focus_request: string // TODO: Make a enum
  focus_request_other: string | null

  notes: string | null
  status: BillClaimStatus

  status_display_name?: string
}

interface IGraphQLMedicalBill {
  id: string

  content_size: number
  content_type: string

  short_url: string
  scan_status: string // TODO: make a enum

  name?: string
}

export function extractFile(file: IGraphQLMedicalBill) {
  return {
    id: file.id,
    name: file?.name || '',
    size: file.content_size,
    type: file.content_type,
    uri: file.short_url,
    scanStatus: file.scan_status,
  }
}

export function extractBillClaimItem(item: IGraphQLBillClaim) {
  const status = item?.status ?? ''

  return {
    id: item?.id ?? '',
    category: item?.claim_category ?? 'medical',
    title: item?.claim_category_name ?? '',
    serverNotes: item?.notes ?? '',
    amountSaved: item?.amount_saved ?? '',
    resolvedAt: item?.resolved_at ?? '',
    isClaimResolved: status === BillClaimStatus.Resolved,
    isClaimSubmitted: status === BillClaimStatus.Pending,
    serverFiles: item?.medical_bills?.map(extractFile) ?? [],
    statusDisplayName: item?.status_display_name ?? '', // TODO: add statusDisplayName ,when BE will support display_name or remove
    status,
    created: item?.created_at ?? '',
  }
}

export const STEP = {
  FIRST_TIME_CLAIM: 'FIRST_TIME_CLAIM',
  TYPE_OF_CLAIM: 'TYPE_OF_CLAIM',
  TYPE_OF_HELP: 'TYPE_OF_HELP',
  UPLOAD: 'UPLOAD',
  SUBMIT: 'SUBMIT',
  INSURANCE: 'INSURANCE',
}

export function extractBillClaimsData(data: IGraphQLBillClaimsList) {
  const billClaimsData = data?.billClaims?.nodes ?? []
  // we receive totalCount from BE as "string" type
  const totalCount = data?.billClaims?.pageInfo?.totalCount ?? ''
  const pageInfo = data?.billClaims?.pageInfo ?? {}

  const claimsData = billClaimsData.map(extractBillClaimItem)

  return { claimsData, pageInfo, totalCount }
}

export const BILLING_CLAIM_TYPES: Record<
  Uppercase<BillClaimCategory>,
  BillClaimCategory
> = {
  MEDICAL: BillClaimCategory.Medical,
  DENTAL: BillClaimCategory.Dental,
  VISION: BillClaimCategory.Vision,
  PHARMACY: BillClaimCategory.Pharmacy,
}

export const BILL_CLAIM_STATUS: Record<
  Uppercase<BillClaimStatus>,
  BillClaimStatus
> = {
  PENDING: BillClaimStatus.Pending,
  IN_PROGRESS: BillClaimStatus.InProgress,
  RESOLVED: BillClaimStatus.Resolved,
}

export function isShowInsuranceStep<
  V extends Record<string, unknown>,
  T extends Record<BillClaimCategory, V>,
>(infoData: T, typeOfClaim: BillClaimCategory) {
  if (!infoData) return

  const insuranceDataOfType =
    infoData[typeOfClaim.toLowerCase() as BillClaimCategory]
  const insuranceValues = Object.values(insuranceDataOfType)
  // check is there empty fields in data
  return insuranceValues.some((item) => !item)
}

// TODO: delete after refactoring BillingClaim questionnaire (NEX-16132)
export function prepareInsuranceInfo(info: InsuranceInfo) {
  return {
    medical: {
      name: info.medical.name,
      id: info.medical.id,
      group_id: info.medical.groupId,
    },
    dental: {
      name: info.dental.name,
      id: info.dental.id,
      group_id: info.dental.groupId,
    },
    vision: {
      name: info.vision.name,
      id: info.vision.id,
      group_id: info.vision.groupId,
    },
    pharmacy: {
      name: info.pharmacy.name,
      bin: info.pharmacy.bin,
      pcn: info.pharmacy.pcn,
      group_id: info.pharmacy.groupId,
    },
  }
}
