import { ForwardedRef, forwardRef } from 'react'

import styled from 'styled-components'

import { secondaryGrey } from 'src/theme/colors'
import { heading8_12 } from 'src/theme/fonts'

import { ChatFileAttachment, ChatMessage } from '../../api/chatMessage'

import { Attachment } from './Attachment'
import { TextMessage } from './TextMessage'

interface MessageProps {
  message: ChatMessage
}

type MessageWithFile = ChatMessage & { attachment: ChatFileAttachment }

function MessageComponent(
  { message }: MessageProps,
  ref: ForwardedRef<HTMLElement>,
) {
  if (!message.attachment) return <TextMessage ref={ref} message={message} />

  switch (message.attachment.kind) {
    case 'ChatMessageFileType':
      return <Attachment ref={ref} message={message as MessageWithFile} />

    case 'SystemNotificationType':
      return <System ref={ref}>{message.attachment.message}</System>
  }
}

const System = styled.span`
  ${heading8_12};

  color: ${secondaryGrey};
`

export const Message = forwardRef(MessageComponent)
