import React from 'react'

import { reflect } from '@effector/reflect'
import styled from 'styled-components'

import ExclamationIcon from 'src/components/SvgIcons/ExclamationIcon'
import { $isDrugCovered } from 'src/entities/drugInfo'
import { useScopedTranslation } from 'src/shared/hooks/useScopedTranslation'
import { NoticeBubble } from 'src/shared/ui/NoticeBubble'
import {
  featurePaleYellow,
  primaryOrange,
  statusActive,
} from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface WarningProps {
  isDrugCovered: boolean
}

function WarningComponent({ isDrugCovered }: WarningProps) {
  const t = useScopedTranslation('translation.medications.drug_search')

  const i18n = {
    warning: t('messages.alternative_medication_info'),
    notCovered: t('messages.not_covered_info'),
  }

  return (
    <Bubble
      icon={
        <ExclamationIcon fill={isDrugCovered ? statusActive : primaryOrange} />
      }
      isDrugCovered={isDrugCovered}
    >
      {isDrugCovered ? i18n.warning : i18n.notCovered}
    </Bubble>
  )
}

const Bubble = styled(NoticeBubble)<{ isDrugCovered: boolean }>`
  margin-bottom: ${convertPxToRem(16)};

  ${({ isDrugCovered }) =>
    !isDrugCovered && `background-color: ${featurePaleYellow};`}
`

export const Warning = reflect({
  view: WarningComponent,
  bind: {
    isDrugCovered: $isDrugCovered,
  },
})
