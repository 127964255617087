import React from 'react'

import PropTypes from 'prop-types'

import { primaryWhite } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function HelpMessageIcon({
  width = convertPxToRem(16),
  height = convertPxToRem(16),
  fill = primaryWhite,
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M15.7401 0.150567C15.5228 -0.0201498 15.2251 -0.0463051 14.9814 0.0839002L0.550086 7.76457H0.550086C0.0453792 8.02858 -0.149741 8.65175 0.114274 9.15646C0.226329 9.37067 0.410074 9.53871 0.63342 9.63123L2.80275 10.5512V10.5513C2.80275 10.607 2.81246 10.6623 2.83144 10.7146L4.4981 15.212C4.67561 15.6887 5.13206 16.0037 5.64075 16.0006C5.94113 15.9979 6.23017 15.8855 6.45342 15.6846C6.47942 15.6612 8.31342 13.6326 8.72008 13.1819C8.76766 13.1292 8.84349 13.1126 8.90875 13.1406L11.0134 14.0332C11.2917 14.1509 11.6069 14.1444 11.8801 14.0152C12.1513 13.8871 12.3542 13.6483 12.4368 13.3599L15.9727 0.867232C16.0479 0.603262 15.9559 0.320075 15.7401 0.150565V0.150567ZM3.89075 10.6979L10.3521 5.32257C10.4105 5.26332 10.5059 5.26265 10.5652 5.32107C10.5693 5.32512 10.5731 5.3294 10.5768 5.3339C10.6409 5.38279 10.6532 5.47441 10.6043 5.53853C10.5998 5.54447 10.5948 5.55005 10.5894 5.55523L5.76408 11.4586C5.70886 11.5258 5.67271 11.6066 5.65942 11.6926L5.23742 14.3259L3.89075 10.6979Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill={fill} />
        </clipPath>
      </defs>
    </svg>
  )
}

HelpMessageIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
}

export default HelpMessageIcon
