import React from 'react'

import PropTypes from 'prop-types'
import Linkify from 'react-linkify'
import styled from 'styled-components'

import { FILE_SCAN_STATUS } from 'src/constants/uploadConstants'
import ChatAttachmentFileLink from 'src/sections/SectionNavigator/Chat/ChatAttachmentFileLink'
import LinkDecorator from 'src/shared/ui/LinkDecorator'
import {
  primaryDarkNavy,
  secondaryLightGrey,
  primaryBlue,
  statusErrorRed,
  featurePaleBlue,
  secondaryBackgroundGrey,
} from 'src/theme/colors'
import { text1_16, textLink1_16 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function ChatMessage({
  splitMessage = [],
  linkData = {},
  hasAttachment = false,
  hasMessageBody = false,
  isSelfSent = false,
  messageId,
}) {
  const isUnavailableFile =
    linkData?.scanStatus === FILE_SCAN_STATUS.INFECTED ||
    linkData?.scanStatus === FILE_SCAN_STATUS.INVALID_LINK

  const componentDecorator = (decoratedHref, decoratedText, key) => (
    <LinkDecorator
      decoratedHref={decoratedHref}
      decoratedText={decoratedText}
      key={key}
    />
  )

  return (
    <Linkify componentDecorator={componentDecorator}>
      <Message isSelfSent={isSelfSent}>
        <Body isUnavailableFile={isUnavailableFile} isSelfSent={isSelfSent}>
          {hasMessageBody &&
            splitMessage.map(({ kind, display, link }) =>
              kind === 'phone' ? (
                <TextPhone
                  href={link}
                  data-test="chat.message.phoneNumberBtn"
                  key={display}
                >
                  {display}
                </TextPhone>
              ) : (
                <Text isSelfSent={isSelfSent} key={display}>
                  {display}
                </Text>
              ),
            )}
          {hasAttachment && (
            <ChatAttachmentFileLink
              messageId={messageId}
              linkData={linkData}
              isSelfSent={isSelfSent}
              hasMessageBody={hasMessageBody}
            />
          )}
        </Body>
      </Message>
    </Linkify>
  )
}

const Message = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-self: ${({ isSelfSent }) => (isSelfSent ? 'flex-end' : 'flex-start')};
  max-width: 80%;
  margin-bottom: ${convertPxToRem(20)};
`

const Body = styled.div`
  background: ${({ isSelfSent }) =>
    isSelfSent ? featurePaleBlue : secondaryLightGrey};
  border: ${({ isUnavailableFile }) =>
    isUnavailableFile
      ? `${convertPxToRem(1)} solid ${statusErrorRed}`
      : `${convertPxToRem(1)} solid ${secondaryBackgroundGrey}`};
  border-radius: ${({ isSelfSent }) =>
    isSelfSent
      ? `${convertPxToRem(8, 0, 8, 8)}`
      : `${convertPxToRem(0, 8, 8)}`};
  padding: ${convertPxToRem(16)};
  color: ${primaryDarkNavy};
  word-break: break-word;
  ${text1_16};
  position: relative;
  display: flex;
  flex-direction: column;
`
const Text = styled.span`
  white-space: pre-line;
`
const TextPhone = styled.a`
  color: ${primaryBlue};
  ${textLink1_16};
`

ChatMessage.propTypes = {
  splitMessage: PropTypes.arrayOf(
    PropTypes.shape({
      kind: PropTypes.string,
      display: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
  linkData: PropTypes.shape({
    isPdf: PropTypes.bool.isRequired,
    attachedFileUrl: PropTypes.string.isRequired,
    scanStatus: PropTypes.string.isRequired,
  }).isRequired,
  messageId: PropTypes.string.isRequired,
  hasAttachment: PropTypes.bool,
  hasMessageBody: PropTypes.bool,
  isSelfSent: PropTypes.bool,
}

export default React.memo(ChatMessage)
