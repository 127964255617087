import React, { memo } from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import styled from 'styled-components'

import ButtonCall40 from 'src/components/Buttons/ButtonCall40'
import ButtonClose40 from 'src/components/Buttons/ButtonClose40'
import NavigatorIconLightTheme from 'src/components/NavigatorIcons/NavigatorIconLightTheme'
import {
  Root,
  HeaderBox,
  NavigatorTitleBox,
  NavigatorTitle,
  NavigatorSubTitle,
  TitleBox,
  Title,
  ButtonBackWrapper,
} from 'src/sections/SectionNavigator/styled'
import { setIsShowThirdSectionModal } from 'src/store/slices/common'
import { secondaryGrey } from 'src/theme/colors'
import { text1_16 } from 'src/theme/fonts'

function ChatNewHeader({
  hasInboxTotalCount,
  navigateToInbox,
  navigatedFromInbox,
  isNavigatorOnline,
  setNavigatedFromInbox,
  hideThirdSectionModal = () => {},
}) {
  const { t } = useTranslation()
  const i18n = {
    navigatorTitle: t('translation.chat.thread.navigator_title'),
    navigatorSubTitle: t('translation.chat.thread.navigator_sub_title'),
    helpTitle: t('translation.chat.thread.title'),
    helpSubTitle: t('translation.chat.thread.description'),
  }

  const handleOnClick = () => {
    navigateToInbox()
    if (navigatedFromInbox) {
      setNavigatedFromInbox(false)
    } else {
      hideThirdSectionModal()
    }
  }

  return (
    <>
      <Root hasInboxTotalCount={hasInboxTotalCount}>
        <HeaderBox>
          <ButtonBackWrapper>
            <ButtonClose40 onClick={handleOnClick} withShadow />
          </ButtonBackWrapper>
          <NavigatorBox>
            <NavigatorIconLightTheme isPreventOnClick />

            <NavigatorTitleBox>
              <NavigatorTitle>{i18n.navigatorTitle}</NavigatorTitle>

              {!!isNavigatorOnline && (
                <NavigatorSubTitle>{i18n.navigatorSubTitle}</NavigatorSubTitle>
              )}
            </NavigatorTitleBox>
          </NavigatorBox>

          <NavigatorHelp>
            <ButtonCall40 withShadow />
          </NavigatorHelp>
        </HeaderBox>

        <TitleBox>
          <Title>{i18n.helpTitle}</Title>

          <SubTitle>{i18n.helpSubTitle}</SubTitle>
        </TitleBox>
      </Root>
    </>
  )
}

const NavigatorBox = styled.div`
  display: flex;
`
const SubTitle = styled.div`
  color: ${secondaryGrey};
  ${text1_16}
`
export const NavigatorHelp = styled.div`
  display: flex;
`

ChatNewHeader.propTypes = {
  hasInboxTotalCount: PropTypes.bool.isRequired,
  isNavigatorOnline: PropTypes.bool.isRequired,
  hideThirdSectionModal: PropTypes.func,
  navigateToInbox: PropTypes.func.isRequired,
  navigatedFromInbox: PropTypes.func.isRequired,
  setNavigatedFromInbox: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  hideThirdSectionModal: () => dispatch(setIsShowThirdSectionModal(false)),
})

export default connect(null, mapDispatchToProps)(memo(ChatNewHeader))
