import { createEvent, createStore } from 'effector'
import { debounce } from 'patronum'

export type PopupType = 'success' | 'error'

export interface PopupMessageConfig {
  type: PopupType
  content: React.ReactNode
}

export const HIDE_TIMEOUT_MS = 2000

export const popupMessageShown = createEvent<PopupMessageConfig>()

export const popupMessageHidden = createEvent()

export const $popup = createStore<PopupMessageConfig | null>(null)
  .on(popupMessageShown, (_, config) => config)
  .reset(popupMessageHidden)

export const openWith = (config: PopupMessageConfig) =>
  popupMessageShown.prepend<void>(() => config)

export const $isOpened = $popup.map((state) => state !== null)

debounce({
  source: popupMessageShown,
  timeout: HIDE_TIMEOUT_MS,
  target: popupMessageHidden,
})
