import { memo, useContext } from 'react'

import styled from 'styled-components'

import { NEW_THREAD_ID } from 'src/entities/chat'
import { InboxStatus } from 'src/features/InboxStatus'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { ThreadIdContext } from '../../lib/ThreadIdContext'

import { ChatCloseButton } from './ChatCloseButton'
import { ChatStatus } from './ChatStatus'
import { ContextActions } from './ContextActions'

function ChatHeaderComponent() {
  const chatId = useContext(ThreadIdContext)!

  return (
    <Wrapper>
      <ChatCloseButton />

      <InboxStatus />

      <ChatStatus />

      {chatId !== NEW_THREAD_ID && <ContextActions />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  gap: ${convertPxToRem(12)};

  padding: ${convertPxToRem(24)};
`

export const ChatHeader = memo(ChatHeaderComponent)
