import { memo, useState, useEffect, useMemo } from 'react'

import { useLocation } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import styled, { css } from 'styled-components'

import Spinner from 'src/components/Spinner'
import { ConversationIcon } from 'src/components/SvgIcons/ConversationIcon'
import EmptyInboxIcon from 'src/components/SvgIcons/EmptyInboxIcon'
import NavigateToIcon from 'src/components/SvgIcons/NavigateToIcon'
import { FeatureFlag } from 'src/constants/featureFlags'
import { ROUTES } from 'src/constants/routesConstants'
import { useOrganizationPhoneQuery } from 'src/entities/organization'
import { TUTORIAL_FIRST_STEP } from 'src/sections/SectionDashboard/dashboardHelpers'
import { BlankInboxHeader } from 'src/sections/SectionNavigator/NavigatorHeader/BlankInboxHeader'
import { SpinnerWrapper } from 'src/sections/SectionNavigator/styled'
import { Sprite } from 'src/shared/ui/Sprite'
import { RootState } from 'src/store'
import {
  primaryWhite,
  secondaryGrey,
  secondaryLightGrey,
  primaryBlue,
} from 'src/theme/colors'
import { textLink2_14, text2_14 } from 'src/theme/fonts'
import { formatPhone, PHONE_FORMATS } from 'src/utils/phoneHelpers'
import { convertPxToRem } from 'src/utils/responsiveHelpers'
import { checkPathName } from 'src/utils/routeHelpers'

interface ButtonCallEmptyInboxProps {
  number: string
  title: string
  formattedNumber: string
  isFirstTutorialStep?: boolean
  dataTestProp: string
}

function ButtonCallEmptyInbox({
  number,
  title,
  formattedNumber,
  isFirstTutorialStep = false,
  dataTestProp,
}: ButtonCallEmptyInboxProps) {
  return (
    <CallButtonBox
      href={
        isFirstTutorialStep
          ? undefined
          : (formatPhone(number, PHONE_FORMATS.uri) ?? undefined)
      }
      data-test={`${dataTestProp}.link`}
    >
      <Sprite
        name="misc/call"
        height={convertPxToRem(24)}
        width={convertPxToRem(24)}
      />
      <WrapperTitleCallButton>
        <Title>{title}</Title>
        <PhoneNumber>{formattedNumber}</PhoneNumber>
      </WrapperTitleCallButton>
    </CallButtonBox>
  )
}

interface EmptyInbox extends ConnectedProps<typeof connector> {
  navigateToChatNew: () => void
  hasInboxTotalCount: boolean
  isLoadingData: boolean
}

function EmptyInbox({
  navigateToChatNew,
  hasInboxTotalCount,
  isLoadingData,
  isFirstTutorialStep = false,
  hasNavFeaturesAccess = false,
  hasPBMFeaturesAccess = false,
}: EmptyInbox) {
  const { t } = useTranslation()
  const i18n = {
    chatWithHealthGuide: t(
      'translation.chat.inbox.without_messages.button_chat',
    ),
    chatWithPharmacist: t(
      'translation.chat.inbox.without_messages.button_chat_pharmacist',
    ),
    callYourHealthGuide: t(
      'translation.chat.inbox.without_messages.button_call_health_guide',
    ),
    callYourPharmacist: t(
      'translation.chat.inbox.without_messages.button_call_pharmacy_guide',
    ),
    remoteText: t('translation.chat.inbox.without_messages.message'),
  }

  const phone = useOrganizationPhoneQuery()

  const hasOnlyPBMFeatureAccess = hasPBMFeaturesAccess && !hasNavFeaturesAccess

  const location = useLocation()

  const [data, setData] = useState({
    chatTitle: '',
  })

  const formattedNavigatorPhone = useMemo(
    () => formatPhone(phone.navigator, PHONE_FORMATS.national)!,
    [phone.navigator],
  )

  const formattedPharmacistPhone = useMemo(
    () => formatPhone(phone.pharmacist, PHONE_FORMATS.national)!,
    [phone.pharmacist],
  )

  useEffect(() => {
    const isMedCabinetRoute = checkPathName(ROUTES.HOME_MEDICATIONS, location)
    if (hasOnlyPBMFeatureAccess || isMedCabinetRoute) {
      setData({
        chatTitle: i18n.chatWithPharmacist,
      })
    }
    if (!hasOnlyPBMFeatureAccess && !isMedCabinetRoute) {
      setData({
        chatTitle: i18n.chatWithHealthGuide,
      })
    }
  }, [hasOnlyPBMFeatureAccess, location.pathname])

  const handleChatButtonClick = () => {
    if (isFirstTutorialStep) return
    navigateToChatNew()
  }

  return (
    <>
      <BlankInboxHeader hasInboxTotalCount={hasInboxTotalCount} />
      {isLoadingData && (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      )}
      {!isLoadingData && (
        <Root>
          <ButtonsWrapper isCurrentTutorialStep={isFirstTutorialStep}>
            <ChatButtonBox onClick={handleChatButtonClick}>
              <ChatIcon>
                <ConversationIcon
                  width={convertPxToRem(20)}
                  height={convertPxToRem(20)}
                />
              </ChatIcon>
              <Title>{data.chatTitle}</Title>
              <NextArrowIcon>
                <NavigateToIcon />
              </NextArrowIcon>
            </ChatButtonBox>

            {!hasOnlyPBMFeatureAccess && (
              <ButtonCallEmptyInbox
                isFirstTutorialStep={isFirstTutorialStep}
                number={formattedNavigatorPhone}
                title={i18n.callYourHealthGuide}
                formattedNumber={formattedNavigatorPhone}
                dataTestProp="navigatorPhone"
              />
            )}

            {hasPBMFeaturesAccess && (
              <ButtonCallEmptyInbox
                isFirstTutorialStep={isFirstTutorialStep}
                number={formattedPharmacistPhone}
                title={i18n.callYourPharmacist}
                formattedNumber={formattedPharmacistPhone}
                dataTestProp="pharmacistPhone"
              />
            )}
          </ButtonsWrapper>
          <Wrapper>
            <EmptyInboxIcon />
          </Wrapper>
          <RemoteText>{i18n.remoteText}</RemoteText>
        </Root>
      )}
    </>
  )
}

const Wrapper = styled.div`
  padding-top: ${convertPxToRem(54)};
  margin-bottom: ${convertPxToRem(45)};
`

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${convertPxToRem(16, 24)};
`

const PrimaryButton = css`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: ${convertPxToRem(44)};
  padding-left: ${convertPxToRem(16)};
  border-radius: ${convertPxToRem(32)};
  cursor: pointer;
  background: ${primaryWhite};
  color: ${secondaryGrey};
  border: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  ${text2_14};
  margin-bottom: ${convertPxToRem(20)};
`

const ChatButtonBox = styled.div`
  ${PrimaryButton};
`

const ButtonsWrapper = styled.div<{ isCurrentTutorialStep: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  z-index: ${({ isCurrentTutorialStep }) => isCurrentTutorialStep && 2};
  background-color: ${({ isCurrentTutorialStep }) =>
    isCurrentTutorialStep && primaryWhite};
  border-radius: ${({ isCurrentTutorialStep }) =>
    isCurrentTutorialStep && convertPxToRem(20)};
  box-shadow: ${({ isCurrentTutorialStep }) =>
    isCurrentTutorialStep && `${convertPxToRem(0, 0, 0, 7)} ${primaryWhite}`};
`

const CallButtonBox = styled.a`
  ${PrimaryButton};
  :active,
  :hover,
  :focus {
    outline: none;
  }
  :last-child {
    margin-bottom: 0;
  }
`

const ChatIcon = styled.div`
  display: flex;
  padding-right: ${convertPxToRem(8)};
  background-position: center;
  background-repeat: no-repeat;
`

const Title = styled.div`
  ${text2_14};
`

const NextArrowIcon = styled.div<{ url?: string }>`
  position: absolute;
  right: ${convertPxToRem(14)};
  width: ${convertPxToRem(16)};
  height: ${convertPxToRem(16)};
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${({ url }) => `url(${url})`};
`

const RemoteText = styled.div`
  color: ${secondaryGrey};
  ${text2_14};
`

const WrapperTitleCallButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: ${convertPxToRem(8)};
`

const PhoneNumber = styled.div`
  padding-left: ${convertPxToRem(5)};
  color: ${primaryBlue};
  ${textLink2_14};
`

const mapStateToProps = ({ tutorial, features }: RootState) => ({
  isFirstTutorialStep:
    tutorial.tutorialStep === TUTORIAL_FIRST_STEP && !tutorial.isCompleted,
  hasNavFeaturesAccess: features.featureFlags[FeatureFlag.CORE_NAV],
  hasPBMFeaturesAccess: features.featureFlags[FeatureFlag.PBM],
})

const connector = connect(mapStateToProps)
export default connector(memo(EmptyInbox))
