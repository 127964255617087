// eslint-disable-next-line import/prefer-default-export
export const debounce = (fn, delay) => {
  let timerId
  return (...args) => {
    if (timerId) {
      clearTimeout(timerId)
    }
    timerId = setTimeout(() => {
      fn(...args)
      timerId = null
    }, delay)
  }
}

export const DEBOUNCE = {
  DELAY_500: 500,
  DELAY_1000: 1000,
}
