import { attach, sample, split } from 'effector'
import { createGate } from 'effector-react'
import { and, pending } from 'patronum'

import { iterableModel } from 'src/entities/iterable'
import {
  $dismissingCardId,
  $dismissingCardType,
  activityFeed,
  cardDismissionConfirmed,
  cardDismissionReset,
} from 'src/sections/SectionHome/Activity/model/core'
import { $$activityFeedErrorModal } from 'src/sections/SectionHome/Activity/model/feedError'
import type { PreparedGraphQLErrors } from 'src/utils/errorHelpers'

import { dismissIterableFx, getInAppMessagesFx } from './iterable/core'

interface DeclineCustomVariables {
  feed_item_type: string
}

// TODO: add types from GraphQL when they arrive
interface ActivityDismissState {
  dismissFeedItem: (variables: { id: string }) => Promise<unknown>
  handleDismissCustomFeedItem: (
    variables: DeclineCustomVariables,
  ) => Promise<unknown>
}

export const ActivityDismissGate = createGate<ActivityDismissState>({
  name: 'ActivityDismissGate',
  domain: activityFeed,
})

export const dismissCustomActivityCardFx = attach({
  source: {
    handlers: ActivityDismissGate.state,
    cardType: $dismissingCardId,
  },
  effect: ({ handlers: { handleDismissCustomFeedItem }, cardType }) =>
    handleDismissCustomFeedItem({ feed_item_type: cardType }),
})

export const dismissActivityCardFx = attach({
  source: {
    handlers: ActivityDismissGate.state,
    id: $dismissingCardId,
  },
  effect: ({ handlers: { dismissFeedItem }, id }) => dismissFeedItem({ id }),
})

export const dismissIterableCardFx = attach({
  source: $dismissingCardId,
  effect: dismissIterableFx,
  mapParams: (_: void, id) => id,
})

export const $dismissPending = pending({
  effects: [dismissCustomActivityCardFx, dismissActivityCardFx],
  of: 'some',
})

sample({
  clock: [
    dismissActivityCardFx.finally,
    dismissCustomActivityCardFx.finally,
    dismissIterableCardFx.finally,
  ],
  fn: () => undefined,
  target: cardDismissionReset,
})

sample({
  clock: [dismissActivityCardFx.doneData, dismissCustomActivityCardFx.doneData],

  filter: (result): result is PreparedGraphQLErrors =>
    Boolean(result) && Object.hasOwn(result as object, 'graphQLErrors'),

  fn: ({ graphQLErrors }: PreparedGraphQLErrors) => graphQLErrors.message,

  target: $$activityFeedErrorModal.setError,
})

sample({
  clock: [ActivityDismissGate.open, iterableModel.$hasAuthorization],
  filter: and(iterableModel.$hasAuthorization, ActivityDismissGate.status),
  target: getInAppMessagesFx,
})

split({
  source: cardDismissionConfirmed,
  match: $dismissingCardType,
  cases: {
    custom: dismissCustomActivityCardFx,
    ordinary: dismissActivityCardFx,
    iterable: dismissIterableCardFx,
  },
})
