interface EnvironmentConfiguration {
  API_URL: string
  GATEWAY_URL: string
  REST_URL: string
  WS_URL: string
  TRANSLATIONS_URL: string

  CONNECT_APOLLO_DEVTOOLS: boolean

  ITERABLE_KEY: string | null
  ITERABLE_PACKAGE: string

  DATADOG_TOKEN: string | null
  DATADOG_ID: string | null

  LAUNCH_DARKLY_KEY: string | null
}

export default function getApplicationConfig(): EnvironmentConfiguration {
  return {
    API_URL: import.meta.env.PUBLIC_API_URL,
    GATEWAY_URL: import.meta.env.PUBLIC_GATEWAY_URL,
    REST_URL: import.meta.env.PUBLIC_REST_URL,
    WS_URL: import.meta.env.PUBLIC_WS_URL,
    TRANSLATIONS_URL: import.meta.env.PUBLIC_TRANSLATIONS_URL,

    CONNECT_APOLLO_DEVTOOLS: import.meta.env.PUBLIC_APOLLO_DEVTOOLS === 'yes',

    ITERABLE_PACKAGE: import.meta.env.PUBLIC_ITERABLE_PACKAGE,
    ITERABLE_KEY: import.meta.env.PUBLIC_ITERABLE_KEY ?? null,

    DATADOG_TOKEN: import.meta.env.PUBLIC_DATADOG_TOKEN ?? null,
    DATADOG_ID: import.meta.env.PUBLIC_DATADOG_ID ?? null,

    LAUNCH_DARKLY_KEY: import.meta.env.PUBLIC_LAUNCH_DARKLY_KEY ?? null,
  }
}
