import { createDomain, sample } from 'effector'

import client from 'src/apollo/client'

import {
  DRUG_CONFIGURATION_QUERY,
  DrugConfiguration,
  DrugInformation,
} from './api/drugConfigurationQuery'

const DrugConfigurationEntity = createDomain('DrugConfigurationEntity')

export const drugConfigurationReset = DrugConfigurationEntity.event<void>()

export const $drugConfigurationOptions = DrugConfigurationEntity.store<
  DrugConfiguration[]
>([]).reset(drugConfigurationReset)

export const $isDrugCovered = DrugConfigurationEntity.store<boolean>(false)

export const loadConfigOptionsFx = DrugConfigurationEntity.effect<
  { drugName: string },
  DrugInformation
>()

export const $hasDrugConfigurationError =
  DrugConfigurationEntity.store<boolean>(false)

loadConfigOptionsFx.use(async ({ drugName }) => {
  const { data } = await client.query({
    query: DRUG_CONFIGURATION_QUERY,
    variables: { drugName },
  })

  return data?.drugConfiguration?.drug
})

sample({
  source: loadConfigOptionsFx.doneData,
  fn: (data) => data?.configurations ?? [],
  target: $drugConfigurationOptions,
})

sample({
  source: loadConfigOptionsFx.doneData,
  fn: (data) => !data?.isAlternativesOnly,
  target: $isDrugCovered,
})

sample({
  source: loadConfigOptionsFx.doneData,
  fn: (data) => !data,
  target: $hasDrugConfigurationError,
})
