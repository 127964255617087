import { reflect } from '@effector/reflect'
import { useUnit } from 'effector-react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import iphoneWithLogo from 'src/assets/images/iphone_with_logo@3x.png'
import SwipeDownIcon from 'src/components/SvgIcons/SwipeDownIcon'
import { $$geolocation, RequestMode } from 'src/entities/geolocation'
import { $$sessionStorage } from 'src/shared/storage/session'
import { ContentCenter } from 'src/theme/baseStyles'
import {
  modalBlackOpacity,
  primaryWhite,
  primaryBlue,
  secondaryGrey,
} from 'src/theme/colors'
import { heading4_21, text1_16, text2_14_Medium } from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { shareYourLocationModal } from '../model'

interface IShareYourLocationModalProps {
  isMobileModal?: boolean
  requestCoordinates: () => void
}

function ShareYourLocationModal({
  isMobileModal = false,
  requestCoordinates,
}: IShareYourLocationModalProps) {
  const { t } = useTranslation()

  const { closeLocationModal } = useUnit($$sessionStorage)

  const openNextModal = useUnit(shareYourLocationModal.next)

  const handleClose = () => {
    closeLocationModal()
    openNextModal()
  }

  const handleRequest = () => {
    requestCoordinates()
    openNextModal()
  }

  const i18n = {
    title: t('translation.auth.location.title'),
    description: t('translation.auth.location.description'),
    buttonConfirm: t('translation.auth.location.confirm'),
    buttonDismiss: t('translation.auth.location.dismiss'),
  }

  return (
    <Root onClick={handleClose} data-test="shareLocationModal">
      {isMobileModal && <SwipeDownIcon />}

      <ModalContainer onClick={(e) => e.stopPropagation()}>
        <HeaderContainer>
          <ImageWrapper>
            <Image
              src={iphoneWithLogo}
              alt="Phone with geo tag on the screen"
            />
          </ImageWrapper>
        </HeaderContainer>

        <Title>{i18n.title}</Title>
        <Description>{i18n.description}</Description>

        <ConfirmButton onClick={handleRequest}>
          {i18n.buttonConfirm}
        </ConfirmButton>
        <DismissButton onClick={handleClose}>
          {i18n.buttonDismiss}
        </DismissButton>
      </ModalContainer>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: absolute;

  background-color: ${modalBlackOpacity};
  z-index: 2;

  width: 100%;
  height: 100%;

  ${mediaQueryFor.mobile} {
    justify-content: end;
  }
`

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;

  height: max-content;
  max-width: ${convertPxToRem(500)};

  background: ${primaryWhite};
  padding: ${convertPxToRem(42, 24, 12, 24)};

  border-radius: ${convertPxToRem(20, 20, 20, 20)};

  ${mediaQueryFor.mobile} {
    border-radius: ${convertPxToRem(20, 20, 0, 0)};
  }
`

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const Image = styled.img`
  width: ${convertPxToRem(66)};
  height: ${convertPxToRem(120)};
`

const HeaderContainer = styled.div`
  align-items: center;
  margin-bottom: ${convertPxToRem(16)};
`

const Title = styled.span`
  ${heading4_21};
  text-align: center;
  margin-bottom: ${convertPxToRem(16)};
`

const Description = styled.div`
  ${text1_16};
  margin-bottom: ${convertPxToRem(32)};
  color: ${secondaryGrey};
`

const ConfirmButton = styled.button`
  ${ContentCenter};
  ${text2_14_Medium};
  background-color: ${primaryBlue};
  color: ${primaryWhite};
  outline: none;
  border: none;

  width: 100%;
  height: ${convertPxToRem(48)};

  margin-top: ${convertPxToRem(32)};
  border-radius: ${convertPxToRem(20)};

  cursor: pointer;
  text-align: center;
  vertical-align: middle;

  flex-grow: 0;
`

const DismissButton = styled.button`
  ${ContentCenter};
  ${text2_14_Medium};
  font-weight: 500;
  outline: none;
  border: none;
  background: transparent;

  color: ${primaryBlue};

  width: 100%;
  height: ${convertPxToRem(40)};

  margin-top: ${convertPxToRem(16)};

  cursor: pointer;
  text-align: center;
  vertical-align: middle;

  flex-grow: 0;
`

export const ShareYourLocation = reflect({
  view: ShareYourLocationModal,
  bind: {
    requestCoordinates: $$geolocation.coordinatesRequested.prepend(
      () => RequestMode.Manual,
    ),
  },
})
