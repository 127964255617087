import { TypedDocumentNode, gql } from '@apollo/client'

import { FeedItem } from './types'

export const FEED_ITEM_FRAGMENT: TypedDocumentNode<
  FeedItem,
  Record<string, never>
> = gql`
  fragment feedItem on FeedItem {
    id
    category
    cardType: card_type
    createdAt: created_at
    updatedAt: updated_at
    title
    description
    label
    callToAction: call_to_action
    imageUrl: image_url
    url
    resource {
      ... on CarePlan {
        __typename
        id
        isArchived: archived
        imageUrl: image_url
        carePlanStatus: status
      }
      ... on BillClaim {
        __typename
        id
        billClaimStatus: status
      }
      ... on Appointment {
        __typename
        id
        appointmentStatus: status
      }
    }
  }
`
