import React, { memo } from 'react'

import PropTypes, { string } from 'prop-types'
import styled from 'styled-components'

import { secondaryGrey, primaryDarkNavy } from 'src/theme/colors'
import { fontInterRegular, text3_12 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

const ChatDisplayDate = ({ displayDay }) => (
  <Date>
    <Day>{displayDay.day} </Day>
    <Time>{displayDay.dayTime}</Time>
  </Date>
)

export default memo(ChatDisplayDate)

ChatDisplayDate.propTypes = {
  displayDay: PropTypes.objectOf(string).isRequired,
}

const Date = styled.div`
  display: flex;
  flex-shrink: 0;
  margin-bottom: ${convertPxToRem(8)};
  align-self: center;
  color: ${secondaryGrey};
  ${text3_12};
  font-family: ${fontInterRegular};
`
const Day = styled.div`
  color: ${primaryDarkNavy};
  margin-right: ${convertPxToRem(4)};
`
const Time = styled.div``
