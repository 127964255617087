import { useState, useCallback, useContext, MouseEventHandler } from 'react'

import { useTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import styled, { ThemeProvider } from 'styled-components'

import LogoIcon from 'src/components/SvgIcons/LogoIcon'
import {
  TUTORIAL_SECOND_STEP,
  getHelpButtonPosition,
} from 'src/sections/SectionDashboard/dashboardHelpers'
import GetHelpTabs from 'src/sections/SectionDashboard/GetHelpTabs'
import { HeaderAnimationContext } from 'src/sections/SectionHome/HeaderAnimationContext'
import { Sprite } from 'src/shared/ui/Sprite'
import { RootState } from 'src/store'
import { modalBg, primaryWhite, primaryBlue } from 'src/theme/colors'
import { textLink2_14, heading4_21 } from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

type SideBarMobileProps = ConnectedProps<typeof connector>

function SideBarMobile({
  isCurrentTutorialStep = false,
  isSequoiaUser = false,
  hasMobileToolbar = true,
}: SideBarMobileProps) {
  const { t } = useTranslation()
  const i18n = {
    getHelpTitle: t('translation.home.tabbar.get_help'),
    quickLinksTitle: t('translation.home.tabbar.description'),
    home: t('translation.home.tabbar.home'),
    carePlans: t('translation.home.tabbar.care_plans'),
  }
  const [isShowGetHelpModal, setIsShowGetHelpModal] = useState(false)

  const { isChangeGetHelpBtn } = useContext(HeaderAnimationContext)

  const handleShow: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    if (isCurrentTutorialStep) return
    setIsShowGetHelpModal(!isShowGetHelpModal)
  }, [isCurrentTutorialStep, isShowGetHelpModal])

  return (
    <ThemeProvider theme={{ isShowGetHelpModal }}>
      <ModalOverlay onClick={() => setIsShowGetHelpModal(false)} />
      <MainContainer isCurrentTutorialStep={isCurrentTutorialStep}>
        {isShowGetHelpModal && (
          <ModalContainer isCurrentTutorialStep={isCurrentTutorialStep}>
            <ModalContent>
              <QuickLinksTitle>{i18n.quickLinksTitle}</QuickLinksTitle>
              <GetHelpTabs />
            </ModalContent>
          </ModalContainer>
        )}
        <GetHelpBtn
          isChangeGetHelpBtn={isChangeGetHelpBtn}
          isShowGetHelpModal={isShowGetHelpModal}
          onClick={handleShow}
          isSequoiaUser={isSequoiaUser}
          hasMobileToolbar={hasMobileToolbar}
          isCurrentTutorialStep={isCurrentTutorialStep}
        >
          {isShowGetHelpModal ? (
            <CloseIconWrapper>
              <Sprite name="nav/close" />
            </CloseIconWrapper>
          ) : (
            <>
              <LogoIcon
                width={convertPxToRem(22)}
                height={convertPxToRem(22)}
              />
              <TextGetHelp isChangeGetHelpBtn={isChangeGetHelpBtn}>
                {i18n.getHelpTitle}
              </TextGetHelp>
            </>
          )}
        </GetHelpBtn>
      </MainContainer>
    </ThemeProvider>
  )
}

interface GetHelpBtnProps {
  isSequoiaUser: boolean
  hasMobileToolbar: boolean
  isShowGetHelpModal: boolean
  isChangeGetHelpBtn: boolean
  isCurrentTutorialStep: boolean
}

const MainContainer = styled.section<{ isCurrentTutorialStep: boolean }>`
  position: fixed;
  bottom: ${convertPxToRem(38)};
  z-index: ${({ isCurrentTutorialStep }) => isCurrentTutorialStep && 2};
  width: 100%;
  padding: ${convertPxToRem(0, 12)};
  display: none;
  ${mediaQueryFor.mobile} {
    display: flex;
  }
`

const ModalContainer = styled.div<{ isCurrentTutorialStep: boolean }>`
  display: flex;
  flex-direction: ${({ isCurrentTutorialStep }) =>
    !isCurrentTutorialStep && 'column'};
  justify-content: ${({ isCurrentTutorialStep }) =>
    isCurrentTutorialStep ? 'center' : 'flex-end'};
  border-radius: ${convertPxToRem(24)};
  width: 100%;
  transition: height 0.4s ease-out;
  background-color: ${({ isCurrentTutorialStep }) =>
    !isCurrentTutorialStep && modalBg};
`

const GetHelpBtn = styled.button<GetHelpBtnProps>`
  position: fixed;
  bottom: ${({ isSequoiaUser, hasMobileToolbar, isShowGetHelpModal }) =>
    getHelpButtonPosition({
      isSequoiaUser,
      hasMobileToolbar,
      isShowGetHelpModal,
    })};
  right: ${convertPxToRem(16)};
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${convertPxToRem(40)};
  padding: ${({ isChangeGetHelpBtn }) =>
    isChangeGetHelpBtn ? convertPxToRem(10, 9) : convertPxToRem(10, 22)};
  border-radius: ${convertPxToRem(40)};
  box-shadow: ${({ isShowGetHelpModal }) =>
    !isShowGetHelpModal &&
    `${convertPxToRem(0, 5, 10, 0)} rgba(0, 0, 0, 0.35)`};
  background-color: ${({ isShowGetHelpModal }) =>
    isShowGetHelpModal ? modalBg : primaryWhite};
  border: 0;
  cursor: ${({ isCurrentTutorialStep }) =>
    isCurrentTutorialStep ? 'inherit' : 'pointer'};
`

const TextGetHelp = styled.div<{ isChangeGetHelpBtn: boolean }>`
  display: ${({ isChangeGetHelpBtn }) => isChangeGetHelpBtn && 'none'};
  color: ${primaryBlue};
  ${textLink2_14};
  margin-left: ${convertPxToRem(4)};
  visibility: ${({ theme }) =>
    theme.isShowGetHelpModal ? 'hidden' : 'visible'};
  opacity: ${({ theme }) => (theme.isShowGetHelpModal ? '0' : '1')};
  transition:
    opacity 0.4s,
    visibility 0.4s;
`

const ModalOverlay = styled.div`
  background-color: ${({ theme }) =>
    theme.isShowGetHelpModal ? 'rgba(0,0,0, 0.5)' : ''};
  visibility: ${({ theme }) =>
    theme.isShowGetHelpModal ? 'visible' : 'hidden'};
  opacity: ${({ theme }) => (theme.isShowGetHelpModal ? '1' : '0')};
  transition:
    background-color 0.4s ease-out,
    opacity 0.4s,
    visibility 0.4s;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const ModalContent = styled.div`
  padding: ${convertPxToRem(25, 16, 110)};
  display: ${({ theme }) => (theme.isShowGetHelpModal ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation-name: slideIn;
  animation-duration: 1s;

  @keyframes slideIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

const QuickLinksTitle = styled.div`
  padding-bottom: ${convertPxToRem(24)};
  ${heading4_21}
`
const CloseIconWrapper = styled.div`
  height: ${convertPxToRem(40)};
  width: ${convertPxToRem(40)};

  background-color: ${primaryBlue};

  padding: ${convertPxToRem(10)};
  border-radius: 50%;

  color: ${primaryWhite};
`

const mapStateToProps = ({ tutorial, user, common }: RootState) => ({
  isCurrentTutorialStep: tutorial.tutorialStep === TUTORIAL_SECOND_STEP,
  isSequoiaUser: user.isSequoiaUser,
  hasMobileToolbar: common.hasMobileToolbar,
})

const connector = connect(mapStateToProps)
export default connector(SideBarMobile)
