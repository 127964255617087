import React from 'react'

import PropTypes from 'prop-types'

import { convertPxToRem } from 'src/utils/responsiveHelpers'

function AttachFileIcon({
  width = convertPxToRem(20),
  height = convertPxToRem(20),
  fill = 'none',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={fill}
        d="M18.2025 2.87916C17.1146 1.79087 15.6388 1.17944 14.1 1.17944C12.5612 1.17944 11.0854 1.79087 9.99749 2.87916L1.34916 11.5267C-0.305728 13.1978 -0.299123 15.8922 1.36393 17.5552C3.02699 19.2183 5.7213 19.2249 7.39249 17.57L14.4167 10.5442C15.1548 9.861 15.4601 8.82873 15.2123 7.85394C14.9645 6.87915 14.2032 6.11806 13.2284 5.87043C12.2535 5.6228 11.2213 5.92832 10.5383 6.66666L6.21416 10.9867C6.00337 11.1971 5.92089 11.5041 5.99779 11.7919C6.07468 12.0797 6.29927 12.3046 6.58695 12.3819C6.87463 12.4592 7.1817 12.3771 7.39249 12.1667L11.7167 7.84083C12.1405 7.43644 12.8096 7.44438 13.2238 7.85872C13.6379 8.27306 13.6454 8.94222 13.2408 9.36583L6.21416 16.3917C5.19611 17.4097 3.54554 17.4097 2.52749 16.3917C1.50945 15.3736 1.50945 13.723 2.52749 12.705L11.1758 4.05749C12.7908 2.44252 15.4092 2.44252 17.0242 4.05749C18.6391 5.67247 18.6391 8.29085 17.0242 9.90583L10.5383 16.3917C10.3217 16.6009 10.2348 16.9107 10.3111 17.2021C10.3873 17.4934 10.6149 17.721 10.9062 17.7972C11.1976 17.8735 11.5074 17.7866 11.7167 17.57L18.2025 11.0833C19.291 9.99551 19.9026 8.51971 19.9026 6.98083C19.9026 5.44194 19.291 3.96614 18.2025 2.87833V2.87916Z"
      />
    </svg>
  )
}

AttachFileIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
}

export default AttachFileIcon
