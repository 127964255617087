import { gql } from '@apollo/client'

import CHAT_MESSAGE_FRAGMENT from 'src/apollo/fragments/CHAT_MESSAGE_FRAGMENT'
import NAVIGATOR_FRAGMENT from 'src/apollo/fragments/NAVIGATOR_FRAGMENT'

const CHAT_FRAGMENT = gql`
  fragment chatFragment on Chat {
    id
    is_inactive
    is_resolved
    last_message {
      ...chatMessageFragment
    }
    messages(first: $first, after: $cursor, order: reverse_created) {
      nodes {
        ...chatMessageFragment
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        totalCount
      }
    }
    navigator {
      ...navigatorFragment
    }
    subject
  }
  ${NAVIGATOR_FRAGMENT}
  ${CHAT_MESSAGE_FRAGMENT}
`

export default CHAT_FRAGMENT
