import { convertPxToRem } from 'src/utils/responsiveHelpers'

//  Named Colors v2
export const primaryWhite = '#ffffff'
export const primaryLightBlue = '#4085F1'
export const primaryDarkNavy = '#2e3141'
export const primaryOrange = '#F2B649'
export const primaryBlue = '#2064d2'

export const secondaryBackgroundBlue = '#183c84'
export const secondaryGrey = '#706e83'
export const secondaryMidGrey = '#bab8cb'
export const secondarySoftGrey = '#f0f0f0'
export const secondaryLightGrey = '#e7e7e7'
export const secondaryBackgroundGrey = '#f7f7f7'

export const featurePaleRed = '#F8EAE5'
export const featurePalePurple = '#E4E1F4'
export const featurePaleBlue = '#E2EEFF'
export const featurePaleGreen = '#E1F1E9'
export const featurePaleYellow = '#FFF0D9'

export const statusActive = '#266B49'
export const statusErrorRed = '#e22020'

// Other
export const darkNavy = '#16192C'
export const nativeSystemBlack = '#000000'
export const nativeSystemBlue = '#007aff'
export const nativeSystemRed = '#ff453a'
export const nativeSystemSeparator = '#3c3c435c' // rgba(60, 60, 67, 0.36);

export const modalBg = '#f5f5f5'
export const kebabText = '#595959'
export const selectedOption = '#f3f3f3'
export const primaryCoverageTakePhoto = '#e6b29f'
export const featurePaleBlueRGBA = 'rgba(226, 238, 255, 0.6)'

// SVG
export const classicGray = '#808080'
export const blueArrow = '#4382C3' // Primary/Blue
export const yellow = '#FAEA24' // Secondary/Yellow
export const black = '#000'
export const hardGray = '#222'
export const sweetGray = '#003354'
export const sweetBlue = '#deedff'
export const modalBlackOpacity = 'rgb(0, 0, 0, 0.35)' // black color for modals with opacity

const boxShadowScreenSize = convertPxToRem(1, 4, 26, 0)
export const boxShadowScreen = `${boxShadowScreenSize} rgba(187, 187, 187, 0.85)` // box shadow for screens
export const boxShadowScreenLight = `${boxShadowScreenSize} rgba(187, 187, 187, 0.15)` // box shadow for screens
