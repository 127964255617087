import { useCallback } from 'react'

import { useMutation } from '@apollo/client'
import { assocPath, pathOr, pipe } from 'ramda'

import CHAT_READ_MUTATION from 'src/apollo/mutations/CHAT_READ_MUTATION'
import NAVIGATOR_ACTIVITIES_QUERY from 'src/apollo/queries/NAVIGATOR_ACTIVITIES_QUERY'

const useChatReadMutation = () => {
  const [chatReadM] = useMutation(CHAT_READ_MUTATION, {
    update: (cache, { data }) => {
      // Unread chat ids and their count are kept in User object
      const { currentUser } =
        cache.readQuery({ query: NAVIGATOR_ACTIVITIES_QUERY }) ?? {}

      if (!currentUser) {
        // Apollo failed to provide User from cache, bail
        return
      }

      const { id: readChatId } = data.readChat

      // Retrieve a list of unread chats
      // These have been updated by subscription to display label "new"
      const unreadChatIds = pathOr(
        [],
        ['activities', 'chat', 'ids'],
        currentUser,
      ).filter((id) => id !== readChatId)

      // Because we have read the chat, we must remove it from list of unread chats
      // And also decrease the counter of unread chats
      const applyChanges = pipe(
        (user) => assocPath(['activities', 'chat', 'ids'], unreadChatIds, user),
        (user) =>
          assocPath(
            ['activities', 'chat', 'count'],
            unreadChatIds.length,
            user,
          ),
      )

      cache.writeQuery({
        query: NAVIGATOR_ACTIVITIES_QUERY,
        data: {
          currentUser: applyChanges(currentUser),
        },
      })
    },
  })

  const handleChatRead = useCallback(
    async (id) => {
      await chatReadM({ variables: { id } })
    },
    [chatReadM],
  )

  return { handleChatRead }
}

export default useChatReadMutation
