import { useState, useRef, memo } from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Measure from 'react-measure'
import styled, { keyframes, css } from 'styled-components'

import AttachFileBtn from 'src/components/AttachComponents/AttachFileBtn'
import useCaptureRatio from 'src/components/AttachComponents/hooks/useCaptureRatio'
import useUserMedia from 'src/components/AttachComponents/hooks/useUserMedia'
import { IMG_UPLOAD_TYPES } from 'src/constants/uploadConstants'
import { NavButton } from 'src/shared/ui/icons/NavButton'
import { Sprite } from 'src/shared/ui/Sprite'
import { ModalBase } from 'src/theme/baseStyles'
import {
  primaryDarkNavy,
  primaryBlue,
  primaryWhite,
  secondaryGrey,
} from 'src/theme/colors'
import { heading2_29, text1_16, textLink1_16 } from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { AttachFileFromCameraIcon } from '../SvgIcons/AttachFileFromCameraIcon'

const CAPTURE_OPTIONS = {
  audio: false,
  video: { facingMode: 'environment' },
}

function CameraModal({
  goBack,
  cameraTheme,
  setImgSrc,
  attachFile,
  confirmPhoto,
  imgSrc = {},
}) {
  const { t } = useTranslation()
  const i18n = {
    cameraTitle: t('translation.modal_photo.title'),
    cameraSubTitle: t('translation.modal_photo.sub_title'),
    confirmTitle: t('translation.modal_photo.confirm_title'),
    confirmSubTitle: t('translation.modal_photo.confirm_sub_title'),
    confirmButton: t('translation.modal_photo.confirm_btn'),
    confirmTryAgainButton: t('translation.modal_photo.confirm_try_btn'),
    newPhoto: t('translation.modal_photo.new_btn'),
  }

  const canvasRef = useRef()
  const videoRef = useRef()

  const [isVideoPlaying, setIsVideoPlaying] = useState(false)
  const [isFlashing, setIsFlashing] = useState(false)
  const [container, setContainer] = useState({ width: 0, height: 0 })

  const mediaStream = useUserMedia(CAPTURE_OPTIONS)

  const [aspectRatio, calculateRatio] = useCaptureRatio(0.667)

  if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
    videoRef.current.srcObject = mediaStream
  }

  const handleResize = (contentRect) => {
    setContainer({
      width: contentRect.bounds.width,
      height: Math.round(contentRect.bounds.width / aspectRatio),
    })
  }

  const handleCanPlay = () => {
    calculateRatio(videoRef.current.videoHeight, videoRef.current.videoWidth)
    setIsVideoPlaying(true)
    videoRef.current.play()
  }

  const handleCapture = () => {
    const context = canvasRef.current.getContext('2d')
    context.drawImage(videoRef.current, 0, 0, container.width, container.height)

    canvasRef.current.toBlob((blob) => setImgSrc(blob), 'image/jpeg', 1)
    setIsFlashing(true)
  }

  const handleConfirm = () => {
    confirmPhoto()
    goBack()
  }

  const handleTryAgain = () => {
    setImgSrc(null)
  }

  if (!mediaStream) {
    return null
  }

  return (
    <Measure bounds onResize={handleResize}>
      {({ measureRef }) => (
        <Wrapper style={{ position: 'fixed' }}>
          <Root>
            <ButtonBackWrapper>
              <NavButton onClick={goBack}>
                <Sprite name="nav/close" />
              </NavButton>
            </ButtonBackWrapper>

            <Header>
              <Title>{imgSrc ? i18n.confirmTitle : i18n.cameraTitle}</Title>
              <SubTitle>
                {imgSrc ? i18n.confirmSubTitle : i18n.cameraSubTitle}
              </SubTitle>
            </Header>

            <CameraWrapper
              ref={measureRef}
              style={{
                height: convertPxToRem(container.height),
              }}
            >
              <Video
                ref={videoRef}
                hidden={!isVideoPlaying}
                onCanPlay={handleCanPlay}
                autoPlay
                playsInline
                muted
              />

              {!imgSrc && (
                <>
                  <Canvas
                    ref={canvasRef}
                    width={container.width}
                    height={container.height}
                  />

                  <Flash
                    flash={isFlashing}
                    onAnimationEnd={() => setIsFlashing(false)}
                  />

                  <CornersWrapper>
                    <TopLeftCameraCorner cameraTheme={cameraTheme} />
                    <TopRightCameraCorner cameraTheme={cameraTheme} />
                    <BottomLeftCameraCorner cameraTheme={cameraTheme} />
                    <BottomRightCameraCorner cameraTheme={cameraTheme} />
                  </CornersWrapper>
                </>
              )}

              {imgSrc && (
                <Img
                  src={imgSrc && URL.createObjectURL(imgSrc)}
                  alt={i18n.newPhoto}
                />
              )}
            </CameraWrapper>

            <CaptureContainer>
              {imgSrc ? (
                <>
                  <Confirm
                    onClick={handleConfirm}
                    style={{ backgroundColor: cameraTheme }}
                  >
                    {i18n.confirmButton}
                  </Confirm>
                  <TryAgain onClick={handleTryAgain}>
                    {i18n.confirmTryAgainButton}
                  </TryAgain>
                </>
              ) : (
                <CaptureButton style={{ borderColor: cameraTheme }}>
                  <Capture onClick={handleCapture} />
                  <AttachFileBtnWrapper>
                    <AttachFileBtn
                      fill={cameraTheme}
                      IconComponent={AttachFileFromCameraIcon}
                      attachFile={(event) => {
                        attachFile(event)
                        goBack()
                      }}
                      id="input-from-camera"
                      accept={IMG_UPLOAD_TYPES}
                    />
                  </AttachFileBtnWrapper>
                </CaptureButton>
              )}
            </CaptureContainer>
          </Root>
        </Wrapper>
      )}
    </Measure>
  )
}

export default memo(CameraModal)

CameraModal.propTypes = {
  attachFile: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  cameraTheme: PropTypes.string.isRequired,
  setImgSrc: PropTypes.func.isRequired,
  confirmPhoto: PropTypes.func.isRequired,
  imgSrc: PropTypes.shape({}),
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  background-color: rgba(0, 0, 0, 0.35);
  padding: ${convertPxToRem(40, 0)};
  overflow: auto;
  ${ModalBase};
  ${mediaQueryFor.tabletOrLess} {
    padding: 0;
  }
`
const Root = styled.div`
  display: flex;
  margin: auto;
  flex-direction: column;
  align-items: center;
  background-color: ${primaryWhite};
  border-radius: ${convertPxToRem(44)};
  padding: ${convertPxToRem(0, 40)};
  max-width: ${convertPxToRem(800)};
  width: 100%;
  overflow: auto;
  ${mediaQueryFor.tabletOrLess} {
    border-radius: 0;
    max-width: 100%;
    margin: 0;
  }
`
const ButtonBackWrapper = styled.div`
  width: 100%;
  padding: ${convertPxToRem(26, 0)};
  align-self: flex-start;
`
const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: ${convertPxToRem(28)};
  width: 100%;
`
const Title = styled.div`
  color: ${primaryDarkNavy};
  margin-bottom: ${convertPxToRem(15)};
  ${heading2_29};
  text-align: center;
`
const SubTitle = styled.div`
  text-align: center;
  color: ${secondaryGrey};
  ${text1_16};
`
const CameraWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: center;
`
const Video = styled.video`
  width: 100%;
  height: 100%;
  &::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
  }
`
const Canvas = styled.canvas`
  position: absolute;
  width: 100%;
  height: 100%;
`
const Img = styled.img`
  position: absolute;
  width: 100%;
  height: auto;
`

const Flash = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${primaryWhite};
  opacity: 0;

  ${({ flash }) =>
    flash &&
    css`
      animation: ${flashAnimation} 750ms ease-out;
    `}
`
const flashAnimation = keyframes`
  from {
    opacity: 0.75;
  }

  to {
    opacity: 0;
  }
`
const CaptureContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: ${convertPxToRem(600)};
  padding: ${convertPxToRem(40, 0)};
  align-items: center;
  justify-content: center;
`
const CaptureButton = styled.div`
  position: relative;
  display: flex;
  width: ${convertPxToRem(68)};
  height: ${convertPxToRem(68)};
  border: ${convertPxToRem(5)} solid;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
const Capture = styled.div`
  width: ${convertPxToRem(48)};
  height: ${convertPxToRem(48)};
  border-radius: 50%;
  background-color: ${secondaryGrey};
`
const AttachFileBtnWrapper = styled.div`
  position: absolute;
  left: ${convertPxToRem(100)};
`
const Confirm = styled.div`
  display: flex;
  width: 100%;
  margin-right: ${convertPxToRem(10)};
  height: ${convertPxToRem(48)};
  border-radius: ${convertPxToRem(36)};
  align-items: center;
  justify-content: center;
  color: ${primaryDarkNavy};
  ${textLink1_16};
  cursor: pointer;
`
const TryAgain = styled.div`
  display: flex;
  width: 100%;
  height: ${convertPxToRem(48)};
  background-color: ${primaryBlue};
  border-radius: ${convertPxToRem(36)};
  align-items: center;
  justify-content: center;
  color: ${primaryWhite};
  ${textLink1_16};
  cursor: pointer;
`
const CornersWrapper = styled.div`
  position: absolute;
  max-width: 100%;
  width: 100%;
  height: 100%;
`
const TopLeftCameraCorner = styled.div`
  position: absolute;
  top: ${convertPxToRem(-8)};
  left: ${convertPxToRem(-7)};
  width: ${convertPxToRem(36)};
  height: ${convertPxToRem(36)};
  border-top: ${({ cameraTheme }) =>
    `${convertPxToRem(3)} solid ${cameraTheme}`};
  border-left: ${({ cameraTheme }) =>
    `${convertPxToRem(3)} solid ${cameraTheme}`};
`
const TopRightCameraCorner = styled.div`
  position: absolute;
  top: ${convertPxToRem(-8)};
  right: ${convertPxToRem(-7)};
  width: ${convertPxToRem(36)};
  height: ${convertPxToRem(36)};
  border-top: ${({ cameraTheme }) =>
    `${convertPxToRem(3)} solid ${cameraTheme}`};
  border-right: ${({ cameraTheme }) =>
    `${convertPxToRem(3)} solid ${cameraTheme}`};
`
const BottomLeftCameraCorner = styled.div`
  position: absolute;
  bottom: ${convertPxToRem(-8)};
  left: ${convertPxToRem(-7)};
  width: ${convertPxToRem(36)};
  height: ${convertPxToRem(36)};
  border-bottom: ${({ cameraTheme }) =>
    `${convertPxToRem(3)} solid ${cameraTheme}`};
  border-left: ${({ cameraTheme }) =>
    `${convertPxToRem(3)} solid ${cameraTheme}`};
`
const BottomRightCameraCorner = styled.div`
  position: absolute;
  bottom: ${convertPxToRem(-8)};
  right: ${convertPxToRem(-7)};
  width: ${convertPxToRem(36)};
  height: ${convertPxToRem(36)};
  border-bottom: ${({ cameraTheme }) =>
    `${convertPxToRem(3)} solid ${cameraTheme}`};
  border-right: ${({ cameraTheme }) =>
    `${convertPxToRem(3)} solid ${cameraTheme}`};
`
