import { gql } from '@apollo/client'

import CHAT_MESSAGE_FRAGMENT from 'src/apollo/fragments/CHAT_MESSAGE_FRAGMENT'

const CHAT_MESSAGES_EVENTS_SUBSCRIPTION = gql`
  subscription chatMessagesEventsSubscription($chat_id: ID!) {
    chatMessagesEvents(chat_id: $chat_id) {
      event
      message {
        ...chatMessageFragment
      }
    }
  }
  ${CHAT_MESSAGE_FRAGMENT}
`

export default CHAT_MESSAGES_EVENTS_SUBSCRIPTION
