import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { IconProps } from './types'

export function HealthIcon({
  width = convertPxToRem(20),
  height = convertPxToRem(20),
  fill = 'none',
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M10 1.88792e-05H10C4.47715 1.91206e-05 0 4.47717 0 10C2.41412e-07 15.5229 4.47715 20 10 20C15.5229 20 20 15.5229 20 10V9.99992C19.994 4.47954 15.5203 0.00591667 9.99992 0L10 1.88792e-05ZM10 17.7084C5.74281 17.7084 2.29167 14.2572 2.29167 10C2.29167 5.74283 5.74281 2.29169 10 2.29169C14.2572 2.29169 17.7083 5.74283 17.7083 10V10.0001C17.7037 14.2554 14.2552 17.7038 9.9999 17.7083L10 17.7084Z"
          fill={fill}
        />
        <path
          d="M9.99984 3.54163C6.433 3.54163 3.5415 6.43312 3.5415 9.99996C3.5415 13.5668 6.433 16.4583 9.99984 16.4583C13.5667 16.4583 16.4582 13.5668 16.4582 9.99996V10.0001C16.4541 6.43493 13.5651 3.54581 9.99993 3.54163H9.99984ZM13.7498 10.8333C13.7498 11.0634 13.5633 11.25 13.3332 11.25H11.6665C11.4364 11.25 11.2498 11.4365 11.2498 11.6666V13.3333C11.2498 13.5634 11.0633 13.75 10.8332 13.75H9.1665C8.93639 13.75 8.74984 13.5634 8.74984 13.3333V11.6666C8.74984 11.4365 8.56329 11.25 8.33317 11.25H6.6665C6.43639 11.25 6.24984 11.0634 6.24984 10.8333V9.16663C6.24984 8.93651 6.43639 8.74996 6.6665 8.74996H8.33317C8.56329 8.74996 8.74984 8.56341 8.74984 8.33329V6.66663C8.74984 6.43651 8.93639 6.24996 9.1665 6.24996H10.8332C11.0633 6.24996 11.2498 6.43651 11.2498 6.66663V8.33329C11.2498 8.56341 11.4364 8.74996 11.6665 8.74996H13.3332C13.5633 8.74996 13.7498 8.93651 13.7498 9.16663V10.8333Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
