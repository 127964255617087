import { MockedResponse } from '@apollo/client/testing'

import {
  RejectTypeEnum,
  PharmacyDayOfWeekType,
  ContentStepTypeEnum,
  PharmacyTypeEnum,
} from 'src/shared/api/types'

import { DRUG_SEARCH_PRICES_QUERY } from './document'
import { DrugSearchPricesQueryVariables } from './types'

export const MockMarkCubanPharmacy = {
  isBestPrice: false,
  patientPay: '10',
  insurancePay: '5',
  priceId: 'price_id_1',
  pharmacy: {
    name: 'recommendedPharmacy',
    addressLine1: 'address_line1',
    latitude: 0.0,
    longitude: 0.0,
    pharmacyType: PharmacyTypeEnum.MarkCuban,
    telephoneNumber: '+12342342323',
    city: 'city',
    state: 'NY',
    zip: '12345',
    nabp: 'nabp',
    deliveryTime: 'delivery_time',
    daysSupply: 30,
    workSchedule: [
      {
        dayOfWeek: PharmacyDayOfWeekType.Monday,
        openHour: '8:00 AM',
        closeHour: '8:00 PM',
      },
    ],
    pharmacySteps: [
      {
        title: 'title',
        content: [
          {
            type: ContentStepTypeEnum.Text,
            content: 'start',
            url: '',
          },
          {
            type: ContentStepTypeEnum.RxCard,
            content: ' rx card ',
            url: '',
          },
          {
            type: ContentStepTypeEnum.Text,
            content: 'end',
            url: '',
          },
        ],
      },
    ],
  },
}

export const MockRetailPharmacy = {
  isBestPrice: false,
  isRejected: false,
  patientPay: '20',
  insurancePay: '5',
  priceId: 'price_id_2',
  pharmacy: {
    name: 'preferredPharmacy',
    addressLine1: 'address_line1',
    latitude: 1.0,
    longitude: 1.0,
    pharmacyType: PharmacyTypeEnum.Retail,
    telephoneNumber: '+12342342323',
    city: 'city',
    state: 'NY',
    zip: '12345',
    nabp: 'nabp',
    deliveryTime: 'delivery_time',
    daysSupply: 30,
    workSchedule: [
      {
        dayOfWeek: PharmacyDayOfWeekType.Monday,
        openHour: '8:00 AM',
        closeHour: '8:00 PM',
      },
    ],
    pharmacySteps: [
      {
        content: [
          {
            type: ContentStepTypeEnum.Link,
            content: 'text',
            url: 'https://example.com',
          },
        ],
        title: 'title',
      },
    ],
  },
}

export const MockWelldynePharmacy = {
  isBestPrice: false,
  patientPay: '40',
  insurancePay: '5',
  priceId: 'price_id_6',
  pharmacy: {
    name: 'specialtyPharmacy',
    addressLine1: 'address_line1',
    latitude: 0.0,
    longitude: 0.0,
    pharmacyType: PharmacyTypeEnum.Welldyne,
    telephoneNumber: '+12342342323',
    city: 'city',
    state: 'NY',
    zip: '12345',
    nabp: 'nabp1',
    deliveryTime: 'delivery_time',
    daysSupply: 30,
    workSchedule: [
      {
        dayOfWeek: PharmacyDayOfWeekType.Monday,
        openHour: '8:00 AM',
        closeHour: '8:00 PM',
      },
    ],
    pharmacySteps: [
      {
        content: [
          {
            type: ContentStepTypeEnum.Link,
            content: 'text',
            url: 'https://example.com',
          },
        ],
        title: 'title',
      },
    ],
  },
}

export const MockCvsPharmacy = {
  isBestPrice: false,
  patientPay: '40',
  insurancePay: '5',
  priceId: 'price_id_6',
  pharmacy: {
    name: 'CVS Specialty Pharmacy',
    addressLine1: 'address_line1',
    latitude: 0.0,
    longitude: 0.0,
    pharmacyType: PharmacyTypeEnum.Cvs,
    telephoneNumber: '+12342342323',
    city: 'city',
    state: 'NY',
    zip: '12345',
    nabp: 'nabp2',
    deliveryTime: 'delivery_time',
    daysSupply: 30,
    workSchedule: [
      {
        dayOfWeek: PharmacyDayOfWeekType.Monday,
        openHour: '8:00 AM',
        closeHour: '8:00 PM',
      },
    ],
    pharmacySteps: [
      {
        content: [
          {
            type: ContentStepTypeEnum.Link,
            content: 'text',
            url: 'https://example.com',
          },
        ],
        title: 'title',
      },
    ],
  },
}

export const MockUnspecifiedPharmacy = {
  isBestPrice: false,
  patientPay: '40',
  insurancePay: '5',
  priceId: 'price_id_6',
  pharmacy: {
    name: 'Unspecified Pharmacy',
    addressLine1: 'address_line1',
    latitude: 0.0,
    longitude: 0.0,
    pharmacyType: PharmacyTypeEnum.UnspecifiedType,
    telephoneNumber: '+12342342323',
    city: 'city',
    state: 'NY',
    zip: '12345',
    nabp: 'nabp2',
    deliveryTime: 'delivery_time',
    daysSupply: 30,
    workSchedule: [
      {
        dayOfWeek: PharmacyDayOfWeekType.Monday,
        openHour: '8:00 AM',
        closeHour: '8:00 PM',
      },
    ],
    pharmacySteps: [
      {
        content: [
          {
            type: ContentStepTypeEnum.Link,
            content: 'text',
            url: 'https://example.com',
          },
        ],
        title: 'title',
      },
    ],
  },
}

export const drugSearchPricesRegularMock = {
  __typename: 'DrugPrices',
  recommendedPharmacies: [MockMarkCubanPharmacy],
  preferredPharmacy: MockRetailPharmacy,
  rejection: null,
  otherPharmacies: [
    {
      isBestPrice: false,
      isRejected: false,
      patientPay: '30',
      insurancePay: '5',
      priceId: 'price_id_3',
      pharmacy: {
        name: 'otherPharmacy_1',
        addressLine1: 'address_line1',
        latitude: 0.0,
        longitude: 0.0,
        pharmacyType: PharmacyTypeEnum.Retail,
        telephoneNumber: '+12342342323',
        city: 'city',
        state: 'NY',
        zip: '12345',
        nabp: 'nabp_1',
        deliveryTime: 'delivery_time',
        daysSupply: 30,
        workSchedule: [
          {
            dayOfWeek: PharmacyDayOfWeekType.Monday,
            openHour: '8:00 AM',
            closeHour: '8:00 PM',
          },
        ],
        pharmacySteps: [
          {
            content: [
              {
                type: ContentStepTypeEnum.Link,
                content: 'text',
                url: 'https://example.com',
              },
            ],
            title: 'title',
          },
        ],
      },
    },
    {
      isBestPrice: false,
      isRejected: false,
      patientPay: '50',
      insurancePay: '5',
      priceId: 'price_id_4',
      pharmacy: {
        name: 'otherPharmacy_2',
        addressLine1: 'address_line1',
        latitude: 0.0,
        longitude: 0.0,
        pharmacyType: PharmacyTypeEnum.Retail,
        telephoneNumber: '+12342342323',
        city: 'city',
        state: 'NY',
        zip: '12345',
        nabp: 'nabp_2',
        deliveryTime: 'delivery_time',
        daysSupply: 30,
        workSchedule: [
          {
            dayOfWeek: PharmacyDayOfWeekType.Tuesday,
            openHour: '8:00 AM',
            closeHour: '8:00 PM',
          },
        ],
        pharmacySteps: [
          {
            content: [
              {
                type: ContentStepTypeEnum.Link,
                content: 'text',
                url: 'https://example.com',
              },
            ],
            title: 'title',
          },
        ],
      },
    },
    {
      isBestPrice: false,
      isRejected: false,
      patientPay: '40',
      insurancePay: '5',
      priceId: 'price_id_5',
      pharmacy: {
        name: 'otherPharmacy_3',
        addressLine1: 'address_line1',
        latitude: 0.0,
        longitude: 0.0,
        pharmacyType: PharmacyTypeEnum.Retail,
        telephoneNumber: '+12342342323',
        city: 'city',
        state: 'NY',
        zip: '12345',
        nabp: 'nabp_3',
        deliveryTime: 'delivery_time',
        daysSupply: 30,
        workSchedule: [
          {
            dayOfWeek: PharmacyDayOfWeekType.Wednesday,
            openHour: '8:00 AM',
            closeHour: '8:00 PM',
          },
        ],
        pharmacySteps: [
          {
            content: [
              {
                type: ContentStepTypeEnum.Link,
                content: 'text',
                url: 'https://example.com',
              },
            ],
            title: 'title',
          },
        ],
      },
    },
    {
      isBestPrice: false,
      isRejected: true,
      patientPay: '0',
      insurancePay: '5',
      priceId: 'price_id_5',
      pharmacy: {
        name: 'otherPharmacy_4',
        addressLine1: 'address_line1',
        latitude: 0.0,
        longitude: 0.0,
        pharmacyType: PharmacyTypeEnum.Retail,
        telephoneNumber: '+12342342323',
        city: 'city',
        state: 'NY',
        zip: '12345',
        nabp: 'nabp_3',
        deliveryTime: 'delivery_time',
        daysSupply: 30,
        workSchedule: [
          {
            dayOfWeek: PharmacyDayOfWeekType.Wednesday,
            openHour: '8:00 AM',
            closeHour: '8:00 PM',
          },
        ],
        pharmacySteps: [
          {
            content: [
              {
                type: ContentStepTypeEnum.Link,
                content: 'text',
                url: 'https://example.com',
              },
            ],
            title: 'title',
          },
        ],
      },
    },
    {
      isBestPrice: false,
      isRejected: false,
      patientPay: '0',
      insurancePay: '5',
      priceId: 'price_id_5',
      pharmacy: {
        name: 'otherPharmacy_5',
        addressLine1: 'address_line1',
        latitude: 0.0,
        longitude: 0.0,
        pharmacyType: PharmacyTypeEnum.Retail,
        telephoneNumber: '+12342342323',
        city: 'city',
        state: 'NY',
        zip: '12345',
        nabp: 'nabp_3',
        deliveryTime: 'delivery_time',
        daysSupply: 30,
        workSchedule: [
          {
            dayOfWeek: PharmacyDayOfWeekType.Wednesday,
            openHour: '8:00 AM',
            closeHour: '8:00 PM',
          },
        ],
        pharmacySteps: [
          {
            content: [
              {
                type: ContentStepTypeEnum.Link,
                content: 'text',
                url: 'https://example.com',
              },
            ],
            title: 'title',
          },
        ],
      },
    },
  ],
  specialtyPharmacies: [],
}
export const drugSearchPricesSpecialtyMock = {
  __typename: 'DrugPrices',
  recommendedPharmacies: [],
  otherPharmacies: [],
  preferredPharmacy: null,
  rejection: null,
  specialtyPharmacies: [MockWelldynePharmacy, MockCvsPharmacy],
}
export const drugSearchPricesRejectMock = {
  __typename: 'DrugPrices',
  recommendedPharmacies: [],
  otherPharmacies: [],
  preferredPharmacy: null,
  rejection: {
    rejectDescription: 'rejectDescription',
    rejectMessage: 'rejectMessage',
    rejectType: RejectTypeEnum.Generic,
  },
  specialtyPharmacies: [],
}

const hasResult: MockedResponse<
  Record<string, unknown>,
  DrugSearchPricesQueryVariables
> = {
  request: {
    query: DRUG_SEARCH_PRICES_QUERY,
    variables: { ndc: '', gpi: '', days_supply: 30, quantity: 1 },
  },
  result: {
    data: {
      drugSearchPricesQuery: drugSearchPricesRegularMock,
    },
  },
}
const nothing: MockedResponse<
  Record<string, unknown>,
  DrugSearchPricesQueryVariables
> = {
  request: {
    query: DRUG_SEARCH_PRICES_QUERY,
    variables: { ndc: '', gpi: '', days_supply: 30, quantity: 1 },
  },
  result: { data: { drugSearchPricesQuery: {} } },
}
export const MockDrugSearchPricesQuery = {
  hasResult,
  nothing,
}
