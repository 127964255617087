import { primaryDarkNavy } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { IconProps } from '../types'

export function QuestionMarkIcon({
  width = convertPxToRem(20),
  height = convertPxToRem(20),
  fill = primaryDarkNavy,
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.093 11.741c0-.405.24-.772.61-.934v.001A4.074 4.074 0 1 0 6 7.074a1.019 1.019 0 1 0 2.037 0A2.037 2.037 0 1 1 10.89 8.94a3.056 3.056 0 0 0-1.833 2.801 1.019 1.019 0 0 0 2.037 0zm-2.547 4.731a1.528 1.528 0 1 0 3.056 0 1.528 1.528 0 0 0-3.056 0z"
        fill="#2E3141"
      />
      <path
        d="M11.704 10.807h.5v-.763l-.7.305.2.458zm-.611.934h.5-.5zm.61-.933h-.5v.764l.7-.305-.2-.459zm2.293-4.837-.482.135.482-.135zM6 7.074h.5H6zm2.037 0h.5-.5zm1.758-2.018-.069-.496.069.496zm2.24 1.465-.481.136.48-.136zm-1.146 2.42.2.458-.2-.459zm-1.833 2.8h.5-.5zm2.448-1.392a1.519 1.519 0 0 0-.911 1.393h1c0-.207.122-.394.31-.476l-.4-.917zm.7.46v-.002h-1v.001h1zm1.31-4.703a3.574 3.574 0 0 1-2.01 4.244l.4.917a4.574 4.574 0 0 0 2.573-5.432l-.963.271zM9.585 3.534a3.574 3.574 0 0 1 3.93 2.572l.962-.27A4.574 4.574 0 0 0 9.45 2.542l.136.99zM6.5 7.074a3.574 3.574 0 0 1 3.085-3.54l-.136-.99A4.574 4.574 0 0 0 5.5 7.073h1zm.519.519a.519.519 0 0 1-.519-.519h-1c0 .84.68 1.519 1.519 1.519v-1zm.518-.519a.519.519 0 0 1-.518.519v1c.838 0 1.518-.68 1.518-1.519h-1zm2.19-2.514a2.537 2.537 0 0 0-2.19 2.515h1c0-.769.566-1.419 1.326-1.524l-.137-.99zm2.79 1.826a2.537 2.537 0 0 0-2.79-1.826l.136.99a1.537 1.537 0 0 1 1.69 1.107l.963-.271zm-1.428 3.013a2.537 2.537 0 0 0 1.427-3.013l-.962.27a1.537 1.537 0 0 1-.865 1.826l.4.917zM9.556 11.74c0-1.016.602-1.936 1.533-2.342l-.4-.917a3.556 3.556 0 0 0-2.133 3.26h1zm.518.519a.519.519 0 0 1-.518-.519h-1c0 .839.68 1.519 1.518 1.519v-1zm.519-.519a.519.519 0 0 1-.519.519v1c.839 0 1.519-.68 1.519-1.519h-1zm-.519 5.759c-.567 0-1.028-.46-1.028-1.028h-1c0 1.12.908 2.028 2.028 2.028v-1zm1.028-1.028c0 .568-.46 1.028-1.028 1.028v1c1.12 0 2.028-.908 2.028-2.028h-1zm-1.028-1.028c.568 0 1.028.46 1.028 1.028h1c0-1.12-.908-2.028-2.028-2.028v1zm-1.028 1.028c0-.567.46-1.028 1.028-1.028v-1c-1.12 0-2.028.908-2.028 2.028h1z"
        fill={fill}
      />
    </svg>
  )
}
