import { memo } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import IconSvg40 from 'src/components/SvgIcons/IconSvg40'
import {
  primaryWhite,
  secondaryGrey,
  secondaryLightGrey,
  primaryDarkNavy,
} from 'src/theme/colors'
import { text1_16 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function ChatQuestion({ questionData = {}, setMessage }) {
  const { backgroundColor = '', url = '', question = '' } = questionData

  return (
    <Root data-test="chatQuestion" onClick={() => setMessage(question)}>
      <IconSvg40
        fill={primaryDarkNavy}
        IconSvg={url}
        border="none"
        bgColor={backgroundColor}
      />
      <Text>{`"${question}"`}</Text>
    </Root>
  )
}

export default memo(ChatQuestion)

ChatQuestion.propTypes = {
  questionData: PropTypes.objectOf(PropTypes.string),
  setMessage: PropTypes.func.isRequired,
}

const Root = styled.button`
  display: flex;
  flex: 1;
  align-items: center;
  background: ${primaryWhite};
  border: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  border-radius: ${convertPxToRem(24)};
  padding: ${convertPxToRem(4)};
  margin-bottom: ${convertPxToRem(8)};
  width: 100%;
  cursor: pointer;
`
const Text = styled.h2`
  display: flex;
  text-align: left;
  color: ${secondaryGrey};
  margin-left: ${convertPxToRem(8)};
  ${text1_16}
`
