import React, { useState, memo, useCallback } from 'react'

import { connect } from 'react-redux'

import useChatsQuery from 'src/apollo/hooks/useChatsQuery'
import useNavigatorActivitiesQuery from 'src/apollo/hooks/useNavigatorActivitiesQuery'
import ChatNew from 'src/sections/SectionNavigator/Chat/ChatNew'
import ChatThread from 'src/sections/SectionNavigator/Chat/ChatThread'
import Inbox from 'src/sections/SectionNavigator/Inbox'
import { STEP } from 'src/sections/SectionNavigator/navigatorHelpers'
import { openInbox, startNewChat, openChatThread } from 'src/store/slices/chats'

function Navigator({
  step = '',
  chatId = '',
  openChat = () => {},
  openNewChat = () => {},
  goToInbox = () => {},
}) {
  const { newMessagesIdsList, isNavigatorOnline } =
    useNavigatorActivitiesQuery()

  const [navigatorsSubject, setNavigatorsSubject] = useState('')
  const [navigatedFromInbox, setNavigatedFromInbox] = useState(false)

  const { inboxData, inboxDataLoading, isFirstDataRequest } = useChatsQuery({
    policy: 'cache-first',
  })

  const hasInboxTotalCount = !!inboxData.length

  const navigateToChatNew = useCallback(() => {
    setNavigatedFromInbox(true)
    openNewChat()
  }, [openNewChat])

  const renderStep = () => {
    switch (step) {
      case STEP.INBOX: // FIXME: use TS Enum
        return (
          <Inbox
            hasInboxTotalCount={hasInboxTotalCount}
            navigateToChatThread={openChat}
            navigateToChatNew={navigateToChatNew}
            newMessagesIdsList={newMessagesIdsList}
            isLoadingData={isLoadingData}
          />
        )

      case STEP.CHAT_NEW:
        return (
          <ChatNew
            navigatedFromInbox={navigatedFromInbox}
            setNavigatedFromInbox={setNavigatedFromInbox}
            navigateToChatThread={openChat}
            hasInboxTotalCount={hasInboxTotalCount}
            navigateToInbox={goToInbox}
            isNavigatorOnline={isNavigatorOnline}
          />
        )
      case STEP.CHAT_THREAD:
        return (
          <ChatThread
            chatId={chatId}
            setNavigatorsSubject={setNavigatorsSubject}
            navigateToInbox={goToInbox}
            navigatorsSubject={navigatorsSubject}
            hasInboxTotalCount={hasInboxTotalCount}
            isLoadingData={isLoadingData}
            isNavigatorOnline={isNavigatorOnline}
          />
        )
      default:
        return null
    }
  }

  const isLoadingData = inboxDataLoading && isFirstDataRequest
  return renderStep()
}

const mapStateToProps = (state) => ({
  step: state.chats.step,
  chatId: state.chats.chatId,
})

const mapDispatchToProps = (dispatch) => ({
  openChat: (chatId) => dispatch(openChatThread(chatId)),
  openNewChat: () => dispatch(startNewChat()),
  goToInbox: () => dispatch(openInbox()),
})

export default connect(mapStateToProps, mapDispatchToProps)(memo(Navigator))
