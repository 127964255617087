import React, { memo } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import ChatAttachmentScanStatus from 'src/sections/SectionNavigator/Chat/ChatAttachmentScanStatus'
import ChatDeliveredTime from 'src/sections/SectionNavigator/Chat/ChatDeliveredTime'
import ChatDisplayDate from 'src/sections/SectionNavigator/Chat/ChatDisplayDate'
import ChatMessage from 'src/sections/SectionNavigator/Chat/ChatMessage'
import ChatSystemMessage from 'src/sections/SectionNavigator/Chat/ChatSystemMessage'
import { searchPhoneNumber } from 'src/sections/SectionNavigator/navigatorHelpers'

function ChatMessageContainer({
  chatReplyLoading = false,
  item = {},
  hasNextPage = false,
}) {
  const {
    attachment = {},
    messageBody = '',
    hasAttachment = false,
    hasMessageBody = false,
    isLastMessage = false,
    isFirstMessage = false,
    isSelfSent = false,
    itemId = '',
    createdAt = '',
    updatedAt = '',
    receivedAt = '',
    displayDay = {},
  } = item

  const splitMessage = React.useMemo(
    () => searchPhoneNumber(messageBody),
    [messageBody],
  )

  return (
    <MessageWrapper key={itemId}>
      <TimeAndScanStatusContainer isSelfSent={isSelfSent}>
        {hasAttachment && attachment?.isFileLink && (
          <ChatAttachmentScanStatus
            scanStatus={attachment?.linkData?.scanStatus}
            isSelfSent={isSelfSent}
          />
        )}
        {isFirstMessage && (
          <ChatDeliveredTime
            displayDay={displayDay}
            creationTime={createdAt}
            updatedTime={updatedAt}
            receivedTime={receivedAt}
            isSelfSent={isSelfSent}
            deliveryLoading={chatReplyLoading && isLastMessage}
          />
        )}
      </TimeAndScanStatusContainer>
      {!attachment?.isSystemMessage && (
        <ChatMessage
          isSelfSent={isSelfSent}
          hasMessageBody={hasMessageBody}
          splitMessage={splitMessage}
          linkData={attachment.linkData}
          hasAttachment={hasAttachment}
          messageId={itemId}
        />
      )}
      {attachment?.isSystemMessage && (
        <ChatSystemMessage systemMessage={attachment.systemMessage} />
      )}
      {isLastMessage && !hasNextPage && (
        <ChatDisplayDate displayDay={displayDay} />
      )}
    </MessageWrapper>
  )
}

const MessageWrapper = styled.li`
  display: contents;
`

const TimeAndScanStatusContainer = styled.div`
  display: flex;
  flex-direction: ${({ isSelfSent }) => (isSelfSent ? 'row-reverse' : 'row')};
`

ChatMessageContainer.propTypes = {
  chatReplyLoading: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  item: PropTypes.shape({
    attachment: PropTypes.shape({}),
    messageBody: PropTypes.string,
    hasAttachment: PropTypes.bool,
    hasMessageBody: PropTypes.bool,
    isLastMessage: PropTypes.bool,
    isFirstMessage: PropTypes.bool,
    isSelfSent: PropTypes.bool,
    itemId: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    receivedAt: PropTypes.string,
    displayDay: PropTypes.shape({}),
  }),
}

/*
  Do not rerender if:
    - message (item) is the same
    - isLastMessage is the same
    - we don't care about chatReplyLoading OR chatReplyLoading is the same
    - we don't care about hasNextPage OR hasNextPage is the same
    - we don't care about scanStatus OR scanStatus is the same
*/

export default memo(ChatMessageContainer, (prev, curr) => {
  const isChatReplyLoadingSame = prev.item?.isFirstMessage
    ? prev.chatReplyLoading === curr.chatReplyLoading
    : true

  const isItemIdSame = prev.item?.itemId === curr.item?.itemId
  const isBodySame = prev.item?.messageBody === curr.item?.messageBody

  const isLastMessageSame =
    prev.item?.isLastMessage === curr.item?.isLastMessage
  const isFirstMessageSame =
    prev.item?.isFirstMessageSame === curr.item?.isFirstMessageSame

  const isHasNextPageSame =
    prev.item?.isLastMessage || curr.item?.isLastMessage
      ? prev.hasNextPage === curr.hasNextPage
      : true

  const isScanStatusSame =
    prev.item?.hasAttachment && prev.item?.attachment?.isFileLink
      ? prev.item?.attachment?.linkData?.scanStatus ===
        curr.item?.attachment?.linkData?.scanStatus
      : true

  return (
    isChatReplyLoadingSame &&
    isHasNextPageSame &&
    isItemIdSame &&
    isLastMessageSame &&
    isFirstMessageSame &&
    isScanStatusSame &&
    isBodySame
  )
})
