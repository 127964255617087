import { gql, TypedDocumentNode } from '@apollo/client'

import { CHAT_DETAILS_FRAGMENT } from '../chatDetails'
import { CHAT_MESSAGE_FRAGMENT } from '../chatMessage'

import {
  StartChatResult,
  StartChatUpdateResult,
  StartChatUpdateVariables,
  StartChatVariables,
} from './types'

export const START_CHAT_MUTATION: TypedDocumentNode<
  StartChatResult,
  StartChatVariables
> = gql`
  mutation startChat {
    chat: startChat {
      ...chatDetails

      messages {
        nodes {
          ...chatMessage
        }

        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }

  ${CHAT_DETAILS_FRAGMENT}
  ${CHAT_MESSAGE_FRAGMENT}
`

export const START_CHAT_UPDATE_QUERY: TypedDocumentNode<
  StartChatUpdateResult,
  StartChatUpdateVariables
> = gql`
  query startChatUpdate($id: ID!) {
    chat(id: $id) {
      # typename added explicitly, so that writeQuery
      # properly normalizes the Chat in updater
      __typename
      id
    }
  }
`
