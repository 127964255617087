import React, { memo, useRef, useMemo } from 'react'

import PropTypes, { shape } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import styled from 'styled-components'

import Spinner from 'src/components/Spinner'
import ChatMessageContainer from 'src/sections/SectionNavigator/Chat/ChatMessageContainer'
import { startNewChat } from 'src/store/slices/chats'
import { primaryWhite, secondaryGrey, primaryBlue } from 'src/theme/colors'
import { heading8_12, textLink1_16 } from 'src/theme/fonts'
import usePagination from 'src/utils/hooks/usePagination'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function ChatMessageList({
  chatReplyLoading,
  fetchMore,
  chatData = {},
  chatDataLoading = false,
  openNewChat = () => {},
}) {
  const { t } = useTranslation()
  const i18n = {
    inactiveText: t('translation.chat.thread.chat_bubble.inactive_text'),
    inactiveCTA: t('translation.chat.thread.chat_bubble.new_conversation'),
  }

  const { chatMessagesList, isResolved, hasNextPage, endCursor } = chatData

  const containerRef = useRef(null)

  usePagination({
    dataPath: ['chat', 'messages'],
    fetchMore,
    pageInfo: { hasNextPage, endCursor },
    scrollRef: containerRef.current,
    fetchMoreLoading: chatDataLoading,
    isReversePagination: true,
  })

  const chatMessages = useMemo(
    () =>
      chatMessagesList.map((item) => (
        <ChatMessageContainer
          key={item.itemId}
          item={item}
          chatReplyLoading={chatReplyLoading}
          hasNextPage={hasNextPage}
        />
      )),
    [chatMessagesList, chatReplyLoading, hasNextPage],
  )

  if (chatDataLoading && !chatMessagesList.length) return <Spinner />

  return (
    <Root data-test="chatList" ref={containerRef}>
      {!!isResolved && (
        <ButtonWrapper>
          <InactiveText>{i18n.inactiveText}</InactiveText>

          <NewConversationBtn
            data-test="startNewConversationBtn"
            onClick={openNewChat}
          >
            {i18n.inactiveCTA}
          </NewConversationBtn>
        </ButtonWrapper>
      )}
      {chatMessages}
    </Root>
  )
}

const Root = styled.ul`
  display: flex;
  flex: 1;
  overflow-y: auto;
  flex-direction: column-reverse;
  padding: ${convertPxToRem(0, 16)};
`

const InactiveText = styled.span`
  color: ${secondaryGrey};
  ${heading8_12};
`
const ButtonWrapper = styled.div`
  padding-bottom: ${convertPxToRem(20)};
  width: ${convertPxToRem(238)};
`
const NewConversationBtn = styled.button`
  background-color: ${primaryBlue};
  color: ${primaryWhite};
  border-radius: ${convertPxToRem(36)};
  min-height: ${convertPxToRem(40)};
  width: 100%;
  border: 0;
  ${textLink1_16};
  margin-top: ${convertPxToRem(10)};
`

ChatMessageList.propTypes = {
  chatData: PropTypes.shape({
    chatMessagesList: PropTypes.arrayOf(shape({})),
    hasNextPage: PropTypes.bool,
    isResolved: PropTypes.bool,
    endCursor: PropTypes.string,
  }),
  chatReplyLoading: PropTypes.bool.isRequired,
  chatDataLoading: PropTypes.bool,
  fetchMore: PropTypes.func.isRequired,
  openNewChat: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => ({
  openNewChat: () => dispatch(startNewChat()),
})

export default connect(null, mapDispatchToProps)(memo(ChatMessageList))
