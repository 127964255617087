import React from 'react'

import PropTypes from 'prop-types'

import { primaryWhite } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

export default function PlaceholderImageIcon({
  width = convertPxToRem(18),
  height = convertPxToRem(24),
  fill = primaryWhite,
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 6V0H2.25A2.25 2.25 0 0 0 0 2.25v19.5A2.25 2.25 0 0 0 2.25 24h13.5A2.25 2.25 0 0 0 18 21.75V7.5h-5.958C11.17 7.5 10.5 6.83 10.5 6zm-6 4.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm10.41 10.102a.744.744 0 0 1-.66.398H3.75a.75.75 0 0 1-.624-1.166l2.5-3.75c.097-.207.332-.334.622-.334a.7.7 0 0 1 .624.334l.628.941 2.126-3.189a.749.749 0 0 1 1.25-.002l4 6a.745.745 0 0 1 .035.768zM12 0v6h6l-6-6z"
        fill={fill}
      />
    </svg>
  )
}

PlaceholderImageIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
}
