import { memo } from 'react'

import styled from 'styled-components'

import Spinner from 'src/components/Spinner'
import { UploadFileScanStatus } from 'src/shared/api/types'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { Sprite } from 'src/shared/ui/Sprite'
import {
  primaryOrange,
  secondaryGrey,
  statusActive,
  statusErrorRed,
} from 'src/theme/colors'
import { text3_12 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface ScanStatusProps {
  status: UploadFileScanStatus

  isOwned: boolean
}

function ScanStatusComponent({ status, isOwned }: ScanStatusProps) {
  const t = useScopedTranslation('translation.shared.scan_status')

  switch (status) {
    case UploadFileScanStatus.Started:
      return (
        <Status>
          <Spinner size="smallest" color={primaryOrange} />
          {t('uploading')}
        </Status>
      )

    case UploadFileScanStatus.NotChecked:
      return null

    case UploadFileScanStatus.Clean:
    case UploadFileScanStatus.Sanitized:
      return <Status data-ok>{t('delivered')}</Status>

    case UploadFileScanStatus.Infected:
      return (
        <Status data-issue>
          <Issue />
          {isOwned ? t('infected') : t('not_available')}
        </Status>
      )

    case UploadFileScanStatus.InvalidLink:
      return (
        <Status data-issue>
          <Issue />
          {isOwned ? t('failed') : t('not_available')}
        </Status>
      )

    case UploadFileScanStatus.SanitizationFailed:
      return (
        <Status data-issue>
          <Issue />
          {t('sanitization_failed')}
        </Status>
      )
  }
}

const Status = styled.span`
  ${text3_12};

  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  gap: ${convertPxToRem(8)};

  color: ${secondaryGrey};

  &[data-ok] {
    color: ${statusActive};
  }

  &[data-issue] {
    color: ${statusErrorRed};
  }
`

const Issue = styled(Sprite).attrs({ name: 'misc/xmark' })`
  width: ${convertPxToRem(12)};
  height: ${convertPxToRem(12)};
`

export const ScanStatus = memo(ScanStatusComponent)
