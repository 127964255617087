import { createGlobalStyle } from 'styled-components'

// needs for export one variable from file
// eslint-disable-next-line import/prefer-default-export
export const GlobalMapStyle = createGlobalStyle`
  .gm-style > div:first-child > div:first-child {
    z-index: 100 !important; // Override inline styles
  }

  .infoBox {
    z-index: 100;
  }
`
