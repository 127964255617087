import React, { useCallback, useMemo, useState } from 'react'

import {
  RouteComponentProps,
  useLocation,
  useNavigate,
  WindowLocation,
} from '@reach/router'
import { combine } from 'effector'
import { useGate, useUnit } from 'effector-react'
import styled from 'styled-components'

import HeaderNavLightTheme from 'src/components/HeaderNavs/HeaderNavLightTheme'
import {
  $preferredPharmacy,
  loadPricesByConfigFx,
  DrugPrice,
  $otherPharmacies,
} from 'src/entities/drugDetails'
import { HoverButton } from 'src/features/HoverButton'
import { LargeMap, LargeMapHookParams } from 'src/features/LargeMap'
import { SpinnerWithTitle } from 'src/features/SpinnerWithTitle'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'
import { RetailPharmacyCard } from 'src/widgets/RetailPharmacyCard'

import { DrugSearchDetailsMapGate } from '../model'

import { RetailPharmacyMarker } from './RetailPharmacyMarker'

interface DrugPriceExtended extends DrugPrice {
  isPreferred: boolean
}

const $pharmacies = combine(
  $otherPharmacies,
  $preferredPharmacy,
  (otherPharmacies, preferredPharmacy) =>
    [
      ...otherPharmacies.map((pharmacy) => ({
        isPreferred: false,
        ...pharmacy,
      })),
      preferredPharmacy && { isPreferred: true, ...preferredPharmacy },
    ].filter(Boolean) as DrugPriceExtended[],
)

const getSelectedPharmacy = (
  pharmacies: DrugPriceExtended[],
  selectedName: string,
) => pharmacies.find((item) => item.pharmacy.name === selectedName) ?? null

interface LocationState {
  drugName: string
}

function DrugsMap() {
  const t = useScopedTranslation('translation.medications.drug_search')

  const [selectedPharmacyName, setSelectedPharmacyName] = useState('')

  const {
    state: { drugName },
  } = useLocation() as WindowLocation<LocationState>
  const navigate = useNavigate()

  useGate(DrugSearchDetailsMapGate, { drugName })

  const [pharmacies, isLoading] = useUnit([
    $pharmacies,
    loadPricesByConfigFx.pending,
  ])

  const markers = useMemo(
    () =>
      pharmacies.map(({ pharmacy, patientPay, isPreferred, isRejected }) => ({
        name: pharmacy.name,
        isRejectedPrice: isRejected,
        id: pharmacy.name,
        latitude: pharmacy.latitude,
        longitude: pharmacy.longitude,
        cost: Number(patientPay),
        isPreferred,
      })),
    [pharmacies],
  )

  const goBack = useCallback(() => {
    void navigate(-1)
  }, [navigate])

  const selectedPharmacy = useMemo(
    () => getSelectedPharmacy(pharmacies, selectedPharmacyName),
    [pharmacies, selectedPharmacyName],
  )

  const onSelectMarker = useCallback((name: string) => {
    setSelectedPharmacyName(name)
  }, [])

  const onMapLoaded = useCallback(({ map, bounds }: LargeMapHookParams) => {
    map.fitBounds(bounds)
  }, [])

  if (isLoading) {
    return <SpinnerWithTitle text={t('status.searching')} />
  }

  return (
    <Root>
      <Content>
        <StyledMap
          markers={markers}
          onLoaded={onMapLoaded}
          draggable="greedy"
          element={
            <RetailPharmacyMarker
              selectedMarkerId={selectedPharmacyName}
              onClick={onSelectMarker}
            />
          }
        />
        <HeaderButtons>
          <HeaderNavLightTheme withMiddleButton={false} />
        </HeaderButtons>
      </Content>

      <BottomContainer>
        <HoverButton>
          <HoverButton.List onClick={goBack} dataTestProp="listButton" />
        </HoverButton>

        {selectedPharmacyName && (
          <RetailPharmacyCard
            pharmacyInfo={selectedPharmacy}
            dataTestProp="retailPharmacyCard"
          />
        )}
      </BottomContainer>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex: 1;
  padding: ${convertPxToRem(24)};
  position: relative;

  ${mediaQueryFor.tabletOrLess} {
    padding: 0;
  }
`
const Content = styled.div`
  position: relative;
  width: 100%;

  border-radius: ${convertPxToRem(8)};
  display: flex;
  align-items: center;
  flex-direction: column;
`

const StyledMap = styled(LargeMap)`
  width: 100%;
  height: 100%;

  ${mediaQueryFor.desktop} {
    border-radius: ${convertPxToRem(20, 20, 0, 0)};
  }
`

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${convertPxToRem(16)};

  position: absolute;
  left: ${convertPxToRem(48)};
  right: ${convertPxToRem(48)};
  bottom: ${convertPxToRem(50)};

  ${mediaQueryFor.mobile} {
    left: ${convertPxToRem(24)};
    right: ${convertPxToRem(24)};
  }
`
const HeaderButtons = styled.div`
  position: absolute;

  top: ${convertPxToRem(40)};
  left: ${convertPxToRem(40)};
  right: ${convertPxToRem(40)};

  ${mediaQueryFor.mobile} {
    top: ${convertPxToRem(25)};
    left: ${convertPxToRem(25)};
    right: ${convertPxToRem(25)};
  }
`

export const DrugSearchDetailsMap = DrugsMap as React.FC<RouteComponentProps>
