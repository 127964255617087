import { useCallback } from 'react'

import styled from 'styled-components'

import { DrugPricesPharmacy } from 'src/entities/drugDetails'
import { LargeMap, LargeMapHookParams } from 'src/features/LargeMap'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { PharmacyDetailsMapMarker } from './PharmacyMapMarker'

interface PharmacyMapProps {
  pharmacy: DrugPricesPharmacy
}

export function PharmacyMap({ pharmacy }: PharmacyMapProps) {
  const onMapLoaded = useCallback(({ map, center }: LargeMapHookParams) => {
    map.panTo(center)
  }, [])

  const pharmacyMarker = {
    id: pharmacy.name,
    latitude: pharmacy.latitude,
    longitude: pharmacy.longitude,
  }

  return (
    <StyledMap
      draggable="none"
      onLoaded={onMapLoaded}
      element={<PharmacyDetailsMapMarker />}
      markers={[pharmacyMarker]}
    />
  )
}

const StyledMap = styled(LargeMap)`
  width: 100%;
  height: 100%;

  ${mediaQueryFor.desktop} {
    border-radius: ${convertPxToRem(20, 20, 0, 0)};
  }
`
