/* eslint-disable no-restricted-syntax */

import { LocationCoordinates } from 'src/utils/locationHelpers'

export function getMarkerBounds(
  markers: LocationCoordinates[],
): google.maps.LatLngBounds {
  const bounds = new window.google.maps.LatLngBounds()

  for (const { latitude, longitude } of markers) {
    bounds.extend({ lat: latitude, lng: longitude })
  }

  return bounds
}

export const DefaultMapOptions: google.maps.MapOptions = {
  disableDefaultUI: true,
  fullscreenControl: false,
  clickableIcons: false,
}

export const MAP_PADDING_PIXELS = 100
