import { useCallback } from 'react'

import { useMutation } from '@apollo/client'

import CHAT_DEACTIVATE_THREAD_MUTATION from 'src/apollo/mutations/CHAT_DEACTIVATE_THREAD_MUTATION'
import { getGraphQlError } from 'src/utils/errorHelpers'

const useChatDeactivateThreadMutation = () => {
  const [chatDeactivateThreadM] = useMutation(CHAT_DEACTIVATE_THREAD_MUTATION)

  const deactivateChatThread = useCallback(
    (id) => {
      try {
        chatDeactivateThreadM({ variables: { id } })
        return {}
      } catch (error) {
        return getGraphQlError(error)
      }
    },
    [chatDeactivateThreadM],
  )

  return { deactivateChatThread }
}

export default useChatDeactivateThreadMutation
