import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { IconProps } from './types'

export function AttachFileFromCameraIcon({
  width = convertPxToRem(40),
  height = convertPxToRem(40),
  fill = 'none',
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 40 40"
    >
      <circle cx="20" cy="20" r="20" fill={fill} />
      <path
        fill="#000"
        d="M12.5 21.25c-.46 0-.833-.373-.833-.833V13.75c0-.46.373-.833.833-.833h10.417c.46 0 .833.373.833.833v1.875c0 .115.093.208.208.208h1.25c.115 0 .209-.093.209-.208V13.75c0-1.38-1.12-2.5-2.5-2.5H12.5c-1.38 0-2.5 1.12-2.5 2.5v6.667c0 1.38 1.12 2.5 2.5 2.5h.208c.115 0 .209-.094.209-.209v-1.25c0-.115-.094-.208-.209-.208H12.5z"
      />
      <path
        fill="#000"
        fillRule="evenodd"
        d="M27.5 17.084c1.38 0 2.5 1.119 2.5 2.5v6.666c0 1.38-1.12 2.5-2.5 2.5H16.667c-1.381 0-2.5-1.12-2.5-2.5v-6.666c0-1.381 1.119-2.5 2.5-2.5H27.5zm0 10c.46 0 .833-.374.833-.834v-6.666c0-.46-.373-.834-.833-.834H16.667c-.46 0-.834.373-.834.834v6.666c0 .46.373.834.834.834H27.5z"
        clipRule="evenodd"
      />
      <path
        fill="#000"
        d="M25.622 22.91c-.116-.23-.343-.384-.6-.407-.257-.022-.507.09-.661.298l-.834 1.128c-.04.054-.104.086-.171.084-.068-.001-.13-.035-.169-.091l-2.245-3.339c-.072-.107-.194-.168-.323-.162-.13.006-.245.08-.306.193l-2.48 4.605c-.07.13-.066.285.01.411.075.126.21.203.357.203h8.212c.144 0 .278-.075.354-.197.076-.123.083-.277.018-.406l-1.162-2.32z"
      />
    </svg>
  )
}
