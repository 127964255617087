import { useContext } from 'react'

import styled from 'styled-components'

import { useInboxStatusQuery } from 'src/features/InboxStatus'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { primaryDarkNavy, secondaryGrey } from 'src/theme/colors'
import { heading6_16, heading8_12 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { useChatDetailsFragment } from '../../api/chatDetails'
import { ThreadIdContext } from '../../lib/ThreadIdContext'

export function ChatStatus() {
  const t = useScopedTranslation('translation.chat.thread')

  const id = useContext(ThreadIdContext)!
  const chat = useChatDetailsFragment(id)

  const { isOnline } = useInboxStatusQuery()

  return (
    <Wrapper>
      <Subject>{chat?.subject ?? t('navigator_title')}</Subject>

      {isOnline ? <Status>{t('navigator_sub_title')}</Status> : null}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  gap: ${convertPxToRem(1)};

  flex: 1 0 max-content;
`

const Subject = styled.span`
  ${heading6_16};

  color: ${primaryDarkNavy};
`

const Status = styled.span`
  ${heading8_12};

  color: ${secondaryGrey};
`
