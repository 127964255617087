import { useQuery } from '@apollo/client'
import { assocPath, pathOr, path, uniqBy } from 'ramda'

import { cache } from 'src/apollo/client'
import CHAT_QUERY from 'src/apollo/queries/CHAT_QUERY'
import CHAT_MESSAGES_EVENTS_SUBSCRIPTION from 'src/apollo/subscriptions/CHAT_MESSAGES_EVENTS_SUBSCRIPTION'
import {
  extractChatData,
  getChatPageInfo,
} from 'src/sections/SectionNavigator/navigatorHelpers'

const useChatQuery = (variables) => {
  const { data, loading, subscribeToMore, refetch, fetchMore } = useQuery(
    CHAT_QUERY,
    {
      variables,
      notifyOnNetworkStatusChange: true,
    },
  )

  const subscribeChatThreadToEvent = (handleChatRead) =>
    subscribeToMore({
      document: CHAT_MESSAGES_EVENTS_SUBSCRIPTION,
      variables: { chat_id: variables.id },
      updateQuery: (
        currentCache,
        {
          subscriptionData: {
            data: { chatMessagesEvents },
          },
        },
      ) => {
        handleChatRead(variables.id) // tell the server, that the message has been read

        let result = currentCache
        const currentMessages = pathOr(
          [],
          ['chat', 'messages', 'nodes'],
          currentCache,
        )
        const clonedMessage = { ...chatMessagesEvents?.message }

        if (chatMessagesEvents?.event === 'created') {
          result = assocPath(
            ['chat', 'messages', 'nodes'],
            uniqBy(path(['id']), [clonedMessage, ...currentMessages]),
            currentCache,
          )
        }

        if (chatMessagesEvents?.event === 'deleted') {
          result = assocPath(
            ['chat', 'messages', 'nodes'],
            currentMessages.filter(
              (node) => node.id !== chatMessagesEvents?.message?.id,
            ),
            currentCache,
          )
          cache.evict({
            id: `Chat:${clonedMessage.chat_id}`,
            fieldName: 'messages',
          })
          cache.gc()
        }

        return result
      },
    })

  const chatData = extractChatData(data)
  const pageInfo = getChatPageInfo(data)

  return {
    chatData,
    chatDataLoading: loading,
    subscribeChatThreadToEvent,
    refetchChatThread: refetch,
    fetchMore,
    pageInfo,
  }
}

export default useChatQuery
