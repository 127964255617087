import { gql } from '@apollo/client'

import CHAT_FRAGMENT from 'src/apollo/fragments/CHAT_FRAGMENT'

const CHAT_QUERY = gql`
  query chatQuery($id: ID!, $cursor: String, $first: Int) {
    chat(id: $id) {
      ...chatFragment
    }
  }
  ${CHAT_FRAGMENT}
`

export default CHAT_QUERY
