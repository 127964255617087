import { reflect } from '@effector/reflect'
import moment from 'moment'
import { Trans } from 'react-i18next'
import styled from 'styled-components'

import { EarlyAccess } from 'src/entities/earlyAccess/api/earlyAccessQuery'
import { $earlyAccess } from 'src/entities/earlyAccess/model'
import { DisclaimerModal } from 'src/features/DisclaimerModal'
import { RightwayProductsType } from 'src/shared/api/types'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { heading6_16 } from 'src/theme/fonts'

import { chatOpened, modalClosed } from '../model/core'

interface EarlyAccessModalContentProps {
  earlyAccess: EarlyAccess | null
  openChat: () => void
  closeModal: () => void
}

function EarlyAccessModalContentComponent({
  earlyAccess,
  openChat,
  closeModal,
}: EarlyAccessModalContentProps) {
  const { launchDate, rightwayProducts = RightwayProductsType.Core } =
    earlyAccess || {}

  const formattedDate = moment(launchDate).format('MM/DD/YYYY')

  const tEarlyAccess = useScopedTranslation(
    'translation.home.early_access_modal',
  )
  const tShared = useScopedTranslation('translation.shared.buttons')

  const i18n = {
    title: tEarlyAccess('title'),
    button_got_it: tShared('got_it'),
    description_specific: {
      [RightwayProductsType.Core]: tEarlyAccess('description_nav'),
      [RightwayProductsType.Pbm]: tEarlyAccess('description_pbm'),
      [RightwayProductsType.Unity]: tEarlyAccess('description_unity'),
    },
    button_chat: {
      [RightwayProductsType.Core]: tEarlyAccess('button_health_guide'),
      [RightwayProductsType.Pbm]: tEarlyAccess('button_pharmacy_guide'),
      [RightwayProductsType.Unity]: tEarlyAccess('button_health_guide'),
    },
  }

  const description = (
    <Trans
      i18nKey="translation.home.early_access_modal.description"
      values={{ launchDate: formattedDate }}
      components={{ date: <DateText /> }}
      tOptions={{ interpolation: { escapeValue: false } }}
    />
  )

  return (
    <DisclaimerModal onClose={closeModal} dataTestProp="earlyAccessModal">
      <DisclaimerModal.Title>{i18n.title}</DisclaimerModal.Title>
      <DisclaimerModal.Description>{description}</DisclaimerModal.Description>
      <DisclaimerModal.Description>
        {i18n.description_specific[rightwayProducts]}
      </DisclaimerModal.Description>
      <DisclaimerModal.PrimaryAction
        onClick={closeModal}
        data-test="earlyAccessModal.primaryAction"
      >
        {i18n.button_got_it}
      </DisclaimerModal.PrimaryAction>
      <DisclaimerModal.SecondaryAction
        onClick={openChat}
        data-test="earlyAccessModal.secondaryAction"
      >
        {i18n.button_chat[rightwayProducts]}
      </DisclaimerModal.SecondaryAction>
    </DisclaimerModal>
  )
}

const DateText = styled.span`
  ${heading6_16};
`

export const EarlyAccessModalContent = reflect({
  view: EarlyAccessModalContentComponent,
  bind: {
    earlyAccess: $earlyAccess,
    openChat: chatOpened,
    closeModal: modalClosed,
  },
})
