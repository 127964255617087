import React, { memo } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { secondaryGrey } from 'src/theme/colors'
import { heading8_12 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

const ChatSystemMessage = ({ systemMessage }) => <Date>{systemMessage}</Date>

export default memo(ChatSystemMessage)

ChatSystemMessage.propTypes = {
  systemMessage: PropTypes.string.isRequired,
}

const Date = styled.div`
  display: flex;
  flex-shrink: 0;
  align-self: ${({ isSelfSent }) => (isSelfSent ? 'flex-end' : 'flex-start')};
  margin-bottom: ${convertPxToRem(20)};
  color: ${secondaryGrey};
  ${heading8_12};
`
