import { Link } from '@reach/router'
import styled from 'styled-components'

import dashedBorder from 'src/assets/icons/dashed_border.svg'
import {
  ModalBase,
  ContentsRootBase,
  KebabBoxShadow,
  FocusDefault,
  FooterPaddingH40,
} from 'src/theme/baseStyles'
import {
  secondaryLightGrey,
  primaryWhite,
  primaryLightBlue,
  primaryDarkNavy,
  secondaryMidGrey,
  secondaryBackgroundGrey,
  primaryBlue,
  secondaryGrey,
} from 'src/theme/colors'
import {
  text1_16,
  heading6_16_Medium,
  heading2_29,
  text2_14,
  text3_12,
  heading1_20,
  heading7_14,
  capsHeading1_12_Medium,
  textLink2_14,
  text4_10,
  capsHeading1_12,
} from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

export const Root = styled.div<{ scrollTop?: number }>`
  ${ContentsRootBase};
  position: static;
  padding: ${convertPxToRem(40)};
  height: calc(80 * var(--vh));
  ${mediaQueryFor.tabletOrLess} {
    padding-top: ${({ scrollTop = 0 }) =>
      scrollTop > 39 ? convertPxToRem(105) : convertPxToRem(40)};
  }
  ${mediaQueryFor.mobile} {
    padding: ${convertPxToRem(16)};
    padding-top: ${({ scrollTop = 0 }) =>
      scrollTop > 124 ? convertPxToRem(82) : convertPxToRem(16)};
  }
`

export const CoverageWrapper = styled.div`
  overflow-y: auto;
`

interface StyledProps {
  paddingTop: string
  paddingBottom: string
  marginBottom: string
}

export const Wrapper = styled.div<StyledProps>`
  padding: ${({ paddingTop, paddingBottom }) =>
    ` ${paddingTop || convertPxToRem(26)}
    ${convertPxToRem(24)}
    ${paddingBottom || '0'}
    `};
  border: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  border-radius: ${convertPxToRem(8)};
  background-color: ${primaryWhite};
  margin-bottom: ${(props) => props.marginBottom || 'unset'};
  &:last-child {
    margin-bottom: 0;
  }
`

export const WrapperForPlan = styled.div`
  margin-top: ${convertPxToRem(16)};
`

export const ProviderLogo = styled.img`
  margin-top: ${convertPxToRem(25)};
`
export const ProviderType = styled.div`
  ${heading7_14};
  margin-bottom: ${convertPxToRem(19)};
  color: ${secondaryGrey};
`
export const CoverageLine = styled.hr`
  border: none;
  border-top: ${convertPxToRem(1)} solid ${secondaryLightGrey};
`
export const PlanWrapper = styled.div``
export const PlanItemWrapper = styled.div``
export const PlanItem = styled.div`
  display: flex;
  justify-content: space-between;
  height: ${convertPxToRem(38)};
  ${mediaQueryFor.mobile} {
    flex-direction: column;
    align-items: left;
    padding-bottom: ${convertPxToRem(8)};
    height: 100%;
  }
`
export const PLanItemNameValue = styled.div``
export const PlanItemTitle = styled.h2`
  display: flex;
  align-items: center;
  ${heading7_14};
  color: ${primaryDarkNavy};
`
export const PlanItemTotalValue = styled.div`
  ${heading6_16_Medium};
  color: ${primaryDarkNavy};
  font-weight: 500;
`
export const PlanItemIconWrapper = styled.div`
  cursor: pointer;
  margin-left: ${convertPxToRem(8)};
  display: flex;
`
export const PlanItemName = styled.div`
  ${heading7_14};
  color: ${secondaryGrey};
  ${mediaQueryFor.mobile} {
    padding-bottom: ${convertPxToRem(8)};
    padding-top: ${convertPxToRem(8)};
  }
`
export const PlanItemValue = styled.div`
  ${heading7_14};
  color: ${primaryDarkNavy};
`
export const PlanItemDescription = styled.div`
  width: 100%;
  display: flex;
  ${text2_14}
`

export const PlanItemProgressTotalValue = styled.div`
  display: flex;
  justify-content: space-between;
  ${text3_12};
  margin-top: ${convertPxToRem(10)};
  margin-bottom: ${convertPxToRem(7)};
`
export const PlanItemProgressTotalValueItem = styled.div`
  font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
  &:first-child {
    margin-right: ${convertPxToRem(3)};
  }
  &:last-child {
    text-align: end;
  }
  color: ${({ color }) => color || secondaryGrey};
`

export const Progress = styled.div`
  margin: 0 auto;
  padding: 0;
  width: 100%;
  height: ${convertPxToRem(8)};
  overflow: hidden;
  background: ${secondaryLightGrey};
  border-radius: ${convertPxToRem(6)};
`
export const Bar = styled.div`
  position: relative;
  float: left;
  height: 100%;
  border-radius: ${convertPxToRem(6)};
  background: ${primaryLightBlue};
`
export const PlanBlankTitle = styled.div`
  ${heading1_20};
  color: ${primaryDarkNavy};
  text-align: left;
`
export const PlanBlankMessage = styled.div`
  ${heading7_14};
  margin-top: ${convertPxToRem(8)};
  color: ${secondaryGrey};
`
export const PlanBlankButton = styled.div`
  ${heading6_16_Medium};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${convertPxToRem(24)} auto;
  height: ${convertPxToRem(48)};
  font-weight: 500;
  color: ${primaryWhite};
  border-radius: ${convertPxToRem(36)};
  background-color: ${primaryBlue};
  cursor: pointer;
  text-align: center;
`
export const CardTitleWrapper = styled.ul``
export const CardTitle = styled.li`
  display: flex;
  ${capsHeading1_12};
  text-transform: uppercase;
  margin: ${convertPxToRem(0, 0, 16, 16)};
  color: ${primaryDarkNavy};
`

export const CardItemTitle = styled.h2`
  ${text1_16};
  margin-top: ${convertPxToRem(16)};
  font-weight: bold;
  color: ${primaryDarkNavy};
`

export const UpdateDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${convertPxToRem(16, 0)};
  ${text3_12};
  color: ${secondaryGrey};
`
export const PlanButtonBottomWrapper = styled(Link)``
// MEDICAL CARD
export const MedicalCardWrapper = styled.div`
  border-radius: ${convertPxToRem(8)};
  border: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  box-shadow: ${convertPxToRem(0, 4, 16, 0)} rgba(206, 206, 206, 0.5);
  padding: ${convertPxToRem(16, 16, 20, 20)};
  position: relative;
  background: ${primaryWhite};
`
export const MedicalCardLogo = styled.img``
export const MedicalCardName = styled.h2`
  ${heading6_16_Medium};
  color: ${primaryDarkNavy};
`
// INSURANCE CARD
export const InsuranceCardWrapper = styled.div``
export const InsuranceCardHeaderWrapper = styled.div`
  height: ${convertPxToRem(50)};
  display: flex;
  justify-content: space-evenly;
  margin: ${convertPxToRem(0, -24)};
  padding: ${convertPxToRem(0, 24)};
  border-bottom: ${convertPxToRem(1)} solid ${secondaryLightGrey};
`
export const InsuranceCardPhotoLoadingWrapper = styled.div`
  height: ${convertPxToRem(186)};
  display: flex;
  justify-content: center;
  align-items: center;
`
export const InsuranceCardPhotoWrapper = styled.div`
  margin-top: ${convertPxToRem(24)};
`
export const InsuranceCardPhotoImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: ${convertPxToRem(600)};
  max-height: ${convertPxToRem(600)};
  margin: 0 auto ${convertPxToRem(25)} auto;
  overflow: ${({ overflow }) => overflow || 'unset'};
  ${mediaQueryFor.mobile} {
    flex-wrap: wrap;
  }
`
export const InsuranceCardPhotoImageItem = styled.div`
  max-width: ${convertPxToRem(295)};
  width: 100%;
  height: ${convertPxToRem(187)};
  position: relative;
  cursor: pointer;
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  & > svg {
    position: absolute;
    bottom: ${convertPxToRem(-4)};
    right: ${convertPxToRem(-4)};
    cursor: pointer;
    border-radius: 50%;
  }
  ${mediaQueryFor.mobile} {
    margin-bottom: ${convertPxToRem(13)};
  }
  ${mediaQueryFor.tablet} {
    &:first-child {
      margin-right: ${convertPxToRem(13)};
    }
  }
  ${mediaQueryFor.desktop} {
    &:first-child {
      margin-right: ${convertPxToRem(13)};
    }
  }
`

export const InsuranceCardPhotoNoImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${convertPxToRem(186)};
  background-image: url(${dashedBorder});
  border-radius: ${convertPxToRem(11)};
  flex-direction: column;
  position: relative;
`
export const InsuranceCardPhotoNoImageText = styled.div`
  ${heading6_16_Medium};
  color: ${primaryDarkNavy};
  margin-top: ${convertPxToRem(13)};
`

export const InsuranceCardHeaderItem = styled.div<{ active: boolean }>`
  width: 25%;
  max-width: ${convertPxToRem(179)};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: ${secondaryGrey};
  cursor: pointer;
  border-bottom: ${convertPxToRem(2)} solid transparent;
  margin-bottom: ${convertPxToRem(-1)};
  ${text1_16};
  ${({ active }) =>
    active &&
    `
    ${heading6_16_Medium};
    color: ${primaryDarkNavy};
    border-bottom: ${convertPxToRem(2)} solid ${primaryBlue}
  `}
`
// VIEW EDIT INSURANCE PHOTO
export const ViewEditWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  height: calc(100 * var(--vh));
  overflow-y: auto;
  background: ${secondaryBackgroundGrey};
  width: 100%;
  z-index: 1;
  ${mediaQueryFor.mobile} {
    padding: ${convertPxToRem(21, 24, 40)};
  }
  ${mediaQueryFor.desktop} {
    padding: ${convertPxToRem(40, 40, 36)};
  }
`
export const ViewEditWrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: inherit;
  ${mediaQueryFor.tablet} {
    padding: ${convertPxToRem(24, 40, 43)};
  }
  ${mediaQueryFor.desktop} {
    padding: ${convertPxToRem(40, 40, 36)};
    background: ${primaryWhite};
    box-shadow: ${convertPxToRem(0, 4, 26, 0)} rgba(187, 187, 187, 0.15);
    border: ${convertPxToRem(1)} solid ${secondaryLightGrey};
    border-radius: ${convertPxToRem(8)};
  }
`
export const ViewEditHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${convertPxToRem(25)};
`
export const ButtonKebabWrapper = styled.div`
  display: flex;
  align-items: center;
  width: ${convertPxToRem(40)};
  height: ${convertPxToRem(40)};
  justify-content: center;
  border-radius: 50%;
  background: ${primaryWhite};
  box-shadow: ${convertPxToRem(0, 4, 16, 0)} rgba(206, 206, 206, 0.5);
`
export const KebabModal = styled.div`
  position: absolute;
  top: ${convertPxToRem(65)};
  right: ${convertPxToRem(16)};
  width: ${convertPxToRem(196)};
  background-color: ${primaryWhite};
  border-radius: ${convertPxToRem(8)};
  ${KebabBoxShadow};
  ${mediaQueryFor.desktop} {
    top: ${convertPxToRem(127)};
    right: ${convertPxToRem(60)};
  }
`
export const KebabItemTitle = styled.div`
  padding: ${convertPxToRem(11, 16)};
  color: ${({ color }) => color};
  cursor: pointer;
  ${textLink2_14};
`

export const MedicalCardEdit = styled(Link)`
  position: absolute;
  top: ${convertPxToRem(16)};
  right: ${convertPxToRem(16)};
`
export const MedicalCardFullName = styled.h2`
  margin-top: ${convertPxToRem(16)};
`
export const MedicalCardMemberInformation = styled.div`
  display: flex;
  margin-top: ${convertPxToRem(16)};
`
export const MedicalCardMemberInformationItem = styled.div`
  display: flex;
  flex-direction: column;
  &:first-child {
    margin-right: ${convertPxToRem(56)};
  }
`
export const MedicalCardMemberInformationName = styled.div`
  ${text4_10};
  color: ${secondaryGrey};
`
export const MedicalCardMemberInformationValue = styled.div`
  ${text2_14};
  color: ${primaryDarkNavy};
  margin-top: ${convertPxToRem(5)};
`
// MY PLAN
export const MyPlanWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  flex-direction: column;
  height: calc(100 * var(--vh));
  overflow: hidden;
  width: 100%;
  background: ${secondaryBackgroundGrey};
`
export const MyPlanRoot = styled.div`
  display: flex;
  flex-direction: column;

  overflow: hidden;
  height: calc(100 * var(--vh));
  background-color: ${primaryWhite};

  ${mediaQueryFor.desktop} {
    margin: ${convertPxToRem(40)};

    box-shadow: ${convertPxToRem(0, 4, 26, 0)} rgba(187, 187, 187, 0.15);

    border: ${convertPxToRem(1)} solid ${secondaryLightGrey};
    border-radius: ${convertPxToRem(8)};
  }

  ${mediaQueryFor.mobile} {
    background-color: ${secondaryBackgroundGrey};
  }
`
export const MyPlanHeader = styled.div`
  background: ${primaryWhite};

  ${mediaQueryFor.desktop} {
    border-radius: ${convertPxToRem(8, 8, 0, 0)};
  }
`

export const MyPlanSubHeader = styled.div`
  ${mediaQueryFor.tabletOrLess} {
    min-height: ${({ isHide }) => (isHide ? 0 : convertPxToRem(60))};
    height: ${({ isHide }) => (isHide ? 0 : 'auto')};
    opacity: ${({ isHide }) => (isHide ? 0 : 1)};
    transition: all 0.5s;
  }
`

export const HeaderWrapper = styled.div<{ bgColor?: string }>`
  padding: ${convertPxToRem(40)};

  background: ${({ bgColor }) => bgColor || 'unset'};

  ${mediaQueryFor.mobile} {
    padding: ${convertPxToRem(24, 24, 30, 24)};
  }
`

export const MyPlanTitle = styled.h1`
  ${heading2_29};
  margin-top: ${convertPxToRem(24)};
  font-weight: 600;
  text-align: center;
  color: ${primaryDarkNavy};
  ${mediaQueryFor.desktop} {
    margin-top: ${convertPxToRem(19)};
  }
`
export const MyPlanType = styled.h2`
  text-align: center;
  ${text2_14};
  margin-top: ${convertPxToRem(32)};
  color: ${secondaryGrey};
`
export const MyPlanTabWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${secondaryBackgroundGrey};
  border-radius: ${convertPxToRem(10, 10, 0, 0)};
  padding-top: ${convertPxToRem(12)};
`

// MY INSURANCE
export const MyInsuranceWrapper = styled.div`
  padding: ${convertPxToRem(24, 27)};

  ${mediaQueryFor.mobile} {
    padding: ${convertPxToRem(16)};
  }
`

export const MarginWrapper = styled.div<{ mt?: string; mb?: string }>`
  margin-top: ${({ mt }) => mt || '0'};
  margin-bottom: ${({ mb }) => mb || '0'};
`
export const MyInsuranceMemberInformation = styled.div`
  margin: ${convertPxToRem(0, 8)} 25px;
  ${mediaQueryFor.tablet} {
    margin-left: 0;
    margin-right: 0;
  }
  ${mediaQueryFor.desktop} {
    margin-left: 0;
    margin-right: 0;
  }
`
export const MyInsuranceMemberInformationItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${convertPxToRem(52)};
`
export const MyInsuranceMemberInformationName = styled.div`
  ${heading7_14};
  color: ${secondaryMidGrey};
`
export const MyInsuranceMemberInformationValue = styled(Link)`
  ${heading6_16_Medium};
  color: ${primaryBlue};
`

// EDIT INSURANCE
export const EditInsuranceWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  background: ${primaryWhite};
  height: 100%;
  padding: 26px 24px 0;
  ${mediaQueryFor.desktop} {
    padding: 40px 40px 79px;
  }
  ${mediaQueryFor.tablet} {
    padding: 40px 40px 79px;
  }
`
export const EditInsuranceHeader = styled.h1`
  ${heading2_29};
  margin-top: 46px;
  font-weight: 600;
  color: ${primaryDarkNavy};
  ${mediaQueryFor.desktop} {
    margin-top: 26px;
  }
`
export const Form = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
`
export const EditInsuranceItem = styled.div`
  margin-top: 32px;
  color: ${primaryDarkNavy};
`
export const EditInsuranceItemLabel = styled.div`
  ${text3_12};
  margin-bottom: ${convertPxToRem(4)};
`
export const EditInsuranceItemInput = styled.input`
  height: ${convertPxToRem(48)};
  padding: ${convertPxToRem(16, 14)};
  width: 100%;
  border: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  border-radius: ${convertPxToRem(8)};
  color: ${primaryDarkNavy};
  ${text1_16};
  ${FocusDefault};
  ::-webkit-input-placeholder {
    color: ${secondaryMidGrey};
  }
  ::-moz-placeholder {
    color: ${secondaryMidGrey};
  }
  :-ms-input-placeholder {
    color: ${secondaryMidGrey};
  }
`
export const EditInsuranceButtonWrapper = styled.div`
  position: absolute;
  bottom: ${convertPxToRem(45)};
  left: 0;
  right: 0;
  ${FooterPaddingH40};
  ${mediaQueryFor.mobile} {
    padding: ${convertPxToRem(0, 24)};
  }
`

// Tooltip modal
export const TooltipModalRoot = styled.div`
  ${ModalBase};
  position: fixed;
  background: rgba(46, 49, 65, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TooltipModalWrapper = styled.div`
  background: ${primaryWhite};
  padding: ${convertPxToRem(24, 24, 21, 22)};
  border-radius: ${convertPxToRem(20)};
  box-shadow: ${convertPxToRem(0, 4, 16, 0)} rgba(206, 206, 206, 0.5);
  max-width: ${convertPxToRem(500)};
  width: 100%;
`
export const TooltipModalWrapperTitle = styled.div`
  ${heading1_20};
  display: flex;
  justify-content: center;
  margin: ${convertPxToRem(20, 0, 16)};
`
export const TooltipModalWrapperDescription = styled.div`
  ${text1_16};
  margin-bottom: ${convertPxToRem(20)};
`
export const TooltipModalBackButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`
// PLAN BENEFITS
export const PlanBenefitsWrapper = styled.div`
  margin: ${convertPxToRem(24, 27)};

  ${mediaQueryFor.mobile} {
    margin: ${convertPxToRem(16)};
  }
`

export const PlanBenefitsGroupItem = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${convertPxToRem(22)};
  margin-left: ${({ ml }) => ml || convertPxToRem(-6)};
  cursor: pointer;
  outline: none;
  & *:last-child {
    margin-left: auto;
  }
  &:hover,
  &:focus {
    outline: none;
  }
`
export const PlanBenefitsGroupItemIcon = styled.img`
  width: ${convertPxToRem(40)};
  height: ${convertPxToRem(40)};
`
export const PlanBenefitsGroupItemName = styled.h2`
  ${heading6_16_Medium};
  font-weight: 500;
  color: ${primaryDarkNavy};
  margin-left: ${({ ml }) => ml || convertPxToRem(12)};
`
// Benefits details
export const PlanBenefitsDetails = styled.div`
  background: ${primaryWhite};
  margin-top: ${convertPxToRem(-7)};
  border-radius: ${convertPxToRem(10, 10, 8, 8)};
  height: calc(100 * var(--vh));
  overflow: auto;
  padding: 31px ${convertPxToRem(16, 19)};

  ${mediaQueryFor.tablet} {
    padding: 31px 40px ${convertPxToRem(19)};
  }
  ${mediaQueryFor.desktop} {
    padding: 31px 40px ${convertPxToRem(19)};
  }
`
export const PlanBenefitsDetailsTitle = styled.h1`
  ${heading2_29};
  margin-top: ${convertPxToRem(26)};
  margin-bottom: ${convertPxToRem(10)};
  font-weight: 600;
  color: ${primaryDarkNavy};
`
export const PlanBenefitsDetailsDescription = styled.div`
  padding: ${convertPxToRem(0, 0, 32, 8)};
  ${text1_16};
  color: ${secondaryGrey};
`
export const PlanBenefitsDetailsItemDescription = styled.div`
  padding-top: ${convertPxToRem(15)};
  ${text2_14}
`
export const BenefitsCommentWrapper = styled.div`
  padding-top: ${convertPxToRem(34)};
`

export const Fieldset = styled.fieldset`
  display: flex;
  flex: 1;
  flex-direction: column;
`

export const Legend = styled.legend``

export const CoverageButtonContainer = styled.div`
  cursor: pointer;
  display: flex;
  width: inherit;
  max-width: inherit;
  background-color: ${secondaryBackgroundGrey};
  z-index: 1;
  padding-top: ${convertPxToRem(12)};
  padding-bottom: ${convertPxToRem(12)};
  margin-bottom: ${convertPxToRem(12)};

  ${mediaQueryFor.desktop} {
    display: none;
  }

  ${mediaQueryFor.tablet} {
    top: ${({ scrollTop }) => (scrollTop > 39 ? convertPxToRem(96) : 0)};
    position: ${({ scrollTop }) => (scrollTop > 39 ? 'absolute' : 'static')};
    right: ${convertPxToRem(0)};
    left: ${convertPxToRem(0)};

    background-color: ${({ scrollTop }) =>
      scrollTop > 39 ? secondaryBackgroundGrey : 'transparent'};

    box-shadow: inset
      ${({ scrollTop }) =>
        scrollTop > 39 ? convertPxToRem(0, 20, 16, -16) : 0}
      rgba(206, 206, 206, 0.5);

    padding-left: ${({ scrollTop }) =>
      scrollTop > 39 ? convertPxToRem(40) : 0};
    padding-right: ${({ scrollTop }) =>
      scrollTop > 39 ? convertPxToRem(40) : 0};
  }

  ${mediaQueryFor.mobile} {
    top: ${({ scrollTop }) => (scrollTop > 124 ? convertPxToRem(68) : 0)};
    position: ${({ scrollTop }) => (scrollTop > 124 ? 'absolute' : 'static')};

    right: ${convertPxToRem(0)};
    left: ${convertPxToRem(0)};

    padding-left: ${({ scrollTop }) =>
      scrollTop > 124 ? convertPxToRem(16) : 0};
    padding-right: ${({ scrollTop }) =>
      scrollTop > 124 ? convertPxToRem(16) : 0};
  }
`

export const CoverageButton = styled.div`
  flex-shrink: 0;
  margin-left: ${convertPxToRem(8)};
  background-color: ${({ activeButton, itemClicked }) =>
    activeButton === itemClicked ? primaryLightBlue : secondaryLightGrey};
  padding: ${convertPxToRem(6)};
  border-radius: ${convertPxToRem(16)};
  color: ${({ activeButton, itemClicked }) =>
    activeButton === itemClicked ? primaryWhite : primaryDarkNavy};
  ${capsHeading1_12_Medium}
`

export const PlanContainer = styled.div``

export const Divider = styled.div`
  margin-top: ${convertPxToRem(40)};
  margin-bottom: ${convertPxToRem(40)};
  background-color: ${primaryWhite};
  height: ${convertPxToRem(3)};
`
