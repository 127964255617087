import { memo, useMemo } from 'react'

import styled from 'styled-components'

import Spinner from 'src/components/Spinner'
import { useShortLink } from 'src/entities/userUpload'
import { UploadFileType } from 'src/shared/api/types'
import { Sprite } from 'src/shared/ui/Sprite'
import { primaryDarkNavy } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface AttachmentContentProps {
  id: string
  link: string
  contentType: string
}

function AttachmentContentComponent({
  id,
  link,
  contentType,
}: AttachmentContentProps) {
  const type = UploadFileType.ChatAttachment
  // FIXME: we should not load documents via short link until click
  const { fileSrc: url, isLoading } = useShortLink({ id, link, type })

  const content = useMemo(() => {
    if (isImage(contentType)) return <Image src={url!} />
    if (isDocument(contentType)) return <Icon name="misc/file_pdf" />
    return <Icon name="misc/file_image" />
  }, [contentType, url])

  if (isLoading) return <Spinner size="small" />

  return <LinkToFile href={url ?? undefined}>{content}</LinkToFile>
}

const isImage = (type: string) => type.startsWith('image/')
const isDocument = (type: string) => type.includes('pdf')

const Image = styled.img.attrs({ alt: '', draggable: false })`
  max-width: 100%;
  min-width: ${convertPxToRem(80)};

  max-height: ${convertPxToRem(180)};
  min-height: ${convertPxToRem(80)};

  object-fit: contain;
`

const Icon = styled(Sprite)`
  width: ${convertPxToRem(40)};
  height: ${convertPxToRem(40)};
`

const LinkToFile = styled.a.attrs({ target: '_blank', rel: 'noopener' })`
  display: flex;

  color: ${primaryDarkNavy};
`

export const AttachmentContent = memo(AttachmentContentComponent)
