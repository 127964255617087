import { memo } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { darkNavy } from 'src/theme/colors'

function AttachFileBtn({
  attachFile,
  disabled = false,
  IconComponent,
  fill = 'none',
  bgColor = 'inherit',
  size = 'auto',
  id = 'file-input',
  accept,
}) {
  return (
    <AttachFile bgColor={bgColor} size={size}>
      <StyledLabel htmlFor={id} />
      <IconComponent fill={fill} />
      <Input
        id={id}
        type="file"
        accept={accept}
        onChange={attachFile}
        disabled={disabled}
      />
    </AttachFile>
  )
}

export default memo(AttachFileBtn)

AttachFileBtn.propTypes = {
  attachFile: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  IconComponent: PropTypes.func.isRequired,
  fill: PropTypes.string,
  bgColor: PropTypes.string,
  size: PropTypes.string,
  id: PropTypes.string,
  accept: PropTypes.string.isRequired,
}

const AttachFile = styled.div`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${darkNavy};
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 50%;
  cursor: pointer;
`

const StyledLabel = styled.label`
  cursor: pointer;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
`
const Input = styled.input`
  display: none;
`
