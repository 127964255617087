import { attach, createEvent, sample } from 'effector'
import { equals } from 'patronum'

import { $$geolocation } from 'src/entities/geolocation'
import { createModal } from 'src/entities/modalsQueue'
import { postpone } from 'src/shared/lib/effector/postpone'
import { $$sessionStorage } from 'src/shared/storage/session'
import { store } from 'src/store'

import { ShareYourLocation } from '../ui/ShareYourLocation'

const checkShouldOpen = createEvent()

export const checkShouldOpenFx = attach({
  source: {
    withPrompt: $$geolocation.permission.$prompt,
    sessionStorage: $$sessionStorage.$state,
  },
  effect({ withPrompt, sessionStorage }) {
    const isActivityPath = window.location.pathname.startsWith('/home/activity')

    const { user, tutorial } = store.getState()

    const { isSequoiaUser } = user

    const { isCompleted: isTutorialCompleted } = tutorial

    return (
      isActivityPath &&
      !isSequoiaUser &&
      isTutorialCompleted &&
      sessionStorage.hasLocationModal &&
      withPrompt
    )
  },
})

sample({
  clock: postpone({
    clock: checkShouldOpen,
    until: equals($$geolocation.$checkStatus, 'done'),
  }),
  target: checkShouldOpenFx,
})

export const shareYourLocationModal = createModal({
  view: ShareYourLocation,
  beforeOpen: checkShouldOpen,
  openOn: checkShouldOpenFx.doneData,
})
