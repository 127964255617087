import { pathOr } from 'ramda'

export const GOOGLE_MAP_ZOOM_MAX_VALUE = 16

export const extractFacilityBase = (x) => ({
  id: x.id,
  name: x?.name,
  introduction: x?.introduction,
  rating: x?.rating,
  address: x?.address,
  distance: x?.distance,
  latitude: x?.latitude,
  longitude: x?.longitude,
  initials: x?.name ? x.name.charAt(0) : '',
  benefitTier: pathOr({}, ['cost', 'benefit_tier'], x),
  work_periods: x?.work_periods || [],
  weekday_text: x?.weekday_text || [],
  isFacility: true,
  // TODO: `imageUrl` Should work after backend implementation
})

export const extractCarePlanCost = (data) => ({
  benefitTier: {
    color: pathOr('', ['benefit_tier', 'color'], data),
    displayName: pathOr('', ['benefit_tier', 'display_name'], data),
    tier: pathOr('', ['benefit_tier', 'tier'], data),
  },
  copay: pathOr('', ['copay'], data),
  deductible: pathOr('', ['deductible'], data),
  insuranceCopay: pathOr('', ['insurance_copay'], data),
  insurancePays: pathOr('', ['insurance_pays'], data),
  total: pathOr('', ['total'], data),
  userPays: pathOr('', ['user_pays'], data),
})

export const extractCarePlanAssignee = ({ data, assigneeId }) => {
  const carePlanAssignees = pathOr([], ['carePlan', 'assignees'], data)
  const assignee = pathOr(
    {},
    ['0'],
    carePlanAssignees.filter((item) => item.id === assigneeId),
  )
  const cost = pathOr({}, ['cost'], assignee)

  return {
    carePlanCost: extractCarePlanCost(cost),
    introduction: pathOr(null, ['introduction'], assignee),
    id: pathOr([], ['id'], assignee),
  }
}

export const formatDistance = (distance) => {
  const distanceToFormat = Number(distance)

  if (distanceToFormat && distanceToFormat < 2) {
    return distanceToFormat.toFixed(1)
  }

  return Math.floor(distanceToFormat).toString()
}

export const selectedTiers = (_insuranceTiers) =>
  _insuranceTiers.filter((tier) => tier.default_state).map((tier) => tier.name)

export const checkIsGeoCoordsChanged = (geoCoords, qsFilters) => {
  const qsLat = pathOr('', ['location', 'coords', 'latitude'], qsFilters)
  const qsLong = pathOr('', ['location', 'coords', 'longitude'], qsFilters)
  const qsPlace = pathOr('', ['location', 'place'], qsFilters)
  const qeoLat = pathOr('', ['latitude'], geoCoords)
  const qeoLong = pathOr('', ['longitude'], geoCoords)

  return (
    !qsPlace && (toNumber(qsLat) !== qeoLat || toNumber(qsLong) !== qeoLong) // place priority
  )
}

export const getInitialFilters = (queryString, geoCoords) => {
  // type DoctorsFilterInputType {
  //   name: String
  //   rating: Float
  //   address: AddressInput
  //   location: LatitudeLongitudeInput
  //   distance: Int = 10000
  //   gender: DOCTOR_GENDER_TYPE
  //   language: DOCTOR_LANGUAGE_TYPE
  //   specialty: DOCTOR_SPECIALTY_GROUP
  //   request_type: DOCTOR_REQUEST_TYPE = "specialist_visit"
  //   insurance_tier: [INSURANCE_TIER_TYPE!]
  // }

  // set filters from url
  const qsFilters = queryString.parsed
  const filters = {}

  if (!qsFilters.location) {
    queryString.change(
      (q) => ({
        // set to url, ?distance=5&rating=3& ...
        ...q,
        ...{
          location: {
            coords: geoCoords,
          },
        },
      }),
      /* callback: */ () => {},
      /* replace: */ true,
    )
    // prepopulate default or geoPosition coords
    filters.location = { coords: geoCoords }
  }

  Object.keys(qsFilters).forEach((key) => {
    switch (key) {
      case 'name': {
        filters.name = qsFilters[key]
        break
      }
      case 'rating': {
        // transform string to number
        filters.rating = qsFilters[key] ? Number(qsFilters[key]) : null
        break
      }
      case 'location': {
        // transform string to number
        filters.location = {
          coords: {
            latitude: Number(qsFilters[key].coords?.latitude),
            longitude: Number(qsFilters[key].coords?.longitude),
          },
          place: qsFilters[key].place,
        }
        break
      }
      case 'distance': {
        // transform string to number
        filters.distance = qsFilters[key] ? Number(qsFilters[key]) : null
        break
      }
      case 'gender': {
        filters.gender = qsFilters[key]
        break
      }
      case 'language': {
        filters.language = qsFilters[key]
        break
      }
      case 'specialty': {
        filters.specialty = qsFilters[key]
        break
      }
      case 'request_type': {
        filters.request_type = qsFilters[key]
        break
      }
      case 'insurance_tier': {
        filters.insurance_tier = qsFilters[key] || []
        break
      }
      default:
        break
    }
  })

  return filters
}

const toNumber = (value) => Number(value)
