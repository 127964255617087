import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { IconProps } from './types'

export function PhysicalExpirienceIcon({
  width = convertPxToRem(20),
  height = convertPxToRem(20),
  fill = 'none',
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={fill}
        d="M6.94 6.416c.224-.02.391-.216.377-.44-.1-1.612-.176-2.976-.213-3.726-.008-.158-.105-.298-.25-.361C4.75.966 2.123 3.249.309 3.782c-.138.04-.248.147-.292.284-.041.137-.006.286.092.391 1.1 1.22 3.878 2.234 6.83 1.96zM19.982 4.066c-.044-.137-.153-.244-.291-.284-1.814-.532-4.441-2.816-6.546-1.894-.145.063-.242.203-.25.36-.038.75-.114 2.116-.213 3.73-.014.225.153.42.377.44 2.953.273 5.73-.742 6.833-1.96.098-.106.132-.255.09-.392z"
      />
      <path
        fill={fill}
        d="M13.75 8.333c-.46 0-.834.373-.834.834 0 .46.373.833.834.833.69 0 1.25.56 1.25 1.25 0 1.037-1.884 1.548-3.801 1.954-.064.013-.13-.004-.18-.046-.05-.044-.076-.107-.071-.173l.13-1.73c.32-4.285.588-9.22.588-9.588C11.666.747 10.92 0 10 0c-.92 0-1.667.746-1.667 1.667 0 .368.268 5.303.588 9.588l.13 1.73c.005.065-.021.128-.07.171-.05.043-.116.061-.18.048C6.883 12.797 5 12.287 5 11.25c0-.69.56-1.25 1.25-1.25.46 0 .833-.373.833-.833 0-.46-.373-.834-.833-.834-1.611 0-2.917 1.306-2.917 2.917 0 1.61 1.263 2.457 2.893 3.007.077.025.132.094.141.174.01.081-.03.16-.099.202-.772.385-1.262 1.171-1.268 2.034 0 1.38 1.119 2.5 2.5 2.5.46 0 .833-.373.833-.834 0-.46-.373-.833-.833-.833-.46 0-.834-.373-.834-.833 0-.574 1.069-.97 2.326-1.283.06-.015.122-.003.172.033.05.036.08.091.086.152.117 1.45.233 2.693.333 4.048.028.206.204.36.413.36.208 0 .385-.154.413-.36.104-1.355.219-2.599.333-4.048.005-.06.036-.117.086-.153.05-.035.113-.047.173-.032 1.264.313 2.332.71 2.332 1.283 0 .46-.373.833-.833.833-.46 0-.834.373-.834.833 0 .46.373.834.834.834 1.38 0 2.5-1.12 2.5-2.5-.007-.863-.497-1.649-1.269-2.035-.07-.041-.108-.12-.099-.2.009-.081.064-.15.141-.175 1.63-.55 2.893-1.397 2.893-3.007S15.36 8.333 13.75 8.333z"
      />
    </svg>
  )
}
