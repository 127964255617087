import {
  ApolloCache,
  MutationUpdaterFunction,
  useMutation,
} from '@apollo/client'

import { WithTypename } from 'src/shared/api/mocks'
import { UploadFileScanStatus } from 'src/shared/api/types'

import { ChatFileAttachment, ChatMessage } from '../chatMessage'
import { CHAT_MESSAGES_UPDATE_FRAGMENT } from '../chatQuery'

import { SEND_CHAT_MESSAGE_MUTATION } from './graphql'
import { SendChatMessageResult, SendChatMessageVariables } from './types'

type Updater = MutationUpdaterFunction<
  SendChatMessageResult,
  SendChatMessageVariables,
  unknown,
  ApolloCache<unknown>
>

const update: Updater = (cache, { data }, { variables }) => {
  cache.updateFragment(
    {
      id: cache.identify({ __typename: 'Chat', id: variables!.chatId }),
      fragment: CHAT_MESSAGES_UPDATE_FRAGMENT,
      returnPartialData: true,
    },
    (chat) => {
      // FIXME: remove checks when GraphQL makes type guarantees
      if (!data?.message) return /* skip */ undefined

      let nodes = chat?.messages?.nodes ?? []

      nodes = nodes.filter((item) => item!.id !== data.message!.id)
      nodes.unshift(data.message)

      return { messages: { nodes } }
    },
  )
}

/**
 * This counter prevents multiple in-flight messages
 * having conflicting optimistic ids in Apollo Cache.
 * Every sent message will have a unique entry
 * because of this counter.
 */
let optimisticId = 0

function optimisticAttachment(file: File): WithTypename<ChatFileAttachment> {
  const kind = 'ChatMessageFileType'

  const url = URL.createObjectURL(file)
  const contentType = file.type

  const scanStatus = UploadFileScanStatus.Started

  return { __typename: kind, kind, url, contentType, scanStatus }
}

function optimisticResponse(variables: SendChatMessageVariables) {
  const message: WithTypename<ChatMessage> = {
    __typename: 'ChatMessage',
    // eslint-disable-next-line no-plusplus
    id: `OPTIMISTIC-${optimisticId++}`,

    body: variables.text ?? '',
    isOwned: true,
    updatedAt: new Date().toISOString(),
    attachment: variables.file ? optimisticAttachment(variables.file) : null,
  }

  return { message } satisfies SendChatMessageResult
}

// Up the timeout from 30s default to 90
// Allows uploading large files
const SEND_MESSAGE_TIMEOUT = 90_000

export function useSendChatMessageMutation() {
  const [send] = useMutation(SEND_CHAT_MESSAGE_MUTATION, {
    update,
    optimisticResponse,
    ignoreResults: true,
    context: { timeout: SEND_MESSAGE_TIMEOUT },
  })

  return { send }
}
