import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import ButtonBottom from 'src/components/Buttons/ButtonBottom'
import ButtonClose40 from 'src/components/Buttons/ButtonClose40'
import BillingAdvocacyIcon from 'src/components/SvgIcons/BillingAdvocacyIcon'
import { ConsumerRatingsIcon } from 'src/components/SvgIcons/ConsumerRatingsIcon'
import IconSvg40 from 'src/components/SvgIcons/IconSvg40'
import { PhysicalExpirienceIcon } from 'src/components/SvgIcons/PhysicalExpirienceIcon'
import PrimaryCareIcon from 'src/components/SvgIcons/PrimaryCareIcon'
import StarIcon from 'src/components/SvgIcons/StarIcon'
import { ModalBase } from 'src/theme/baseStyles'
import {
  primaryDarkNavy,
  primaryWhite,
  secondaryGrey,
  primaryOrange,
  secondaryBackgroundGrey,
} from 'src/theme/colors'
import { heading2_29, text1_16, text3_12 } from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function RatingTooltip({ setIsVisible, dataTestProp = 'ratingTooltip' }) {
  const { t } = useTranslation()
  const i18n = {
    title: t('translation.home.care_tools.care_finder.rating_tooltip.title'),
    firstDescription: t(
      'translation.home.care_tools.care_finder.rating_tooltip.first_description',
    ),
    secondDescription: t(
      'translation.home.care_tools.care_finder.rating_tooltip.second_description',
    ),
    physicalTitle: t(
      'translation.home.care_tools.care_finder.rating_tooltip.physica_title',
    ),
    consumerTitle: t(
      'translation.home.care_tools.care_finder.rating_tooltip.consumer_title',
    ),
    gotItButtonTitle: t(
      'translation.home.care_tools.care_finder.rating_tooltip.button',
    ),
  }

  const topIcons = [
    {
      icon: PrimaryCareIcon,
      title: t(
        'translation.home.care_tools.care_finder.rating_tooltip.care_title',
      ),
    },
    {
      icon: BillingAdvocacyIcon,
      title: t(
        'translation.home.care_tools.care_finder.rating_tooltip.cost_title',
      ),
    },
  ]
  const closeModal = () => {
    setIsVisible(false)
  }
  return (
    <Modal
      onClick={(e) => e.stopPropagation()}
      onWheel={(e) => e.stopPropagation()}
    >
      <Overlay onClick={closeModal} />

      <Root data-test={dataTestProp}>
        <ButtonCloseWrapper>
          <ButtonClose40 border="none" withShadow onClick={closeModal} />
        </ButtonCloseWrapper>

        <Content>
          <TopIconsWrapper>
            {topIcons.map((item) => (
              <TopIcon>
                <IconSvg40
                  IconSvg={item.icon}
                  bgColor={primaryWhite}
                  border="none"
                  withShadow
                  fill={secondaryGrey}
                  cursor="initial"
                />
                <IconTitle>{item.title}</IconTitle>
              </TopIcon>
            ))}
          </TopIconsWrapper>

          <BottomIconsWrapper>
            <BottomIcon>
              <IconSvg40
                IconSvg={PhysicalExpirienceIcon}
                bgColor={primaryWhite}
                border="none"
                withShadow
                fill={secondaryGrey}
                cursor="initial"
              />
              <IconTitle>{i18n.physicalTitle}</IconTitle>
            </BottomIcon>

            <StarIconWrapper>
              <StarIcon
                fill={primaryOrange}
                width={convertPxToRem(93)}
                height={convertPxToRem(84)}
              />
            </StarIconWrapper>

            <BottomIcon>
              <IconSvg40
                IconSvg={ConsumerRatingsIcon}
                bgColor={primaryWhite}
                border="none"
                withShadow
                fill={secondaryGrey}
                cursor="initial"
              />
              <IconTitle>{i18n.consumerTitle}</IconTitle>
            </BottomIcon>
          </BottomIconsWrapper>
          <Title>{i18n.title}</Title>

          <DescriptionWrapper>
            <FirstDescription>{i18n.firstDescription}</FirstDescription>

            <SecondDescription>{i18n.secondDescription}</SecondDescription>
          </DescriptionWrapper>
        </Content>

        <ButtonBottom
          handleClick={closeModal}
          title={i18n.gotItButtonTitle}
          theme="primary"
        />
      </Root>
    </Modal>
  )
}

export default RatingTooltip

const Modal = styled.div`
  ${ModalBase};
  overflow: auto;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
`
const ButtonCloseWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const Root = styled.div`
  background-color: ${primaryWhite};
  max-width: ${convertPxToRem(500)};
  display: flex;
  margin: auto;
  flex-direction: column;
  padding: ${convertPxToRem(24, 36, 36)};
  border-radius: ${convertPxToRem(20)};
  ${mediaQueryFor.mobile} {
    max-width: 100%;
    margin: 0;
    padding: ${convertPxToRem(24, 24, 36)};
  }
  z-index: 1;
`
const Content = styled.div`
  margin-top: ${convertPxToRem(20)};
  display: flex;
  flex: 1;
  flex-direction: column;
  ${mediaQueryFor.mobile} {
    padding-top: ${convertPxToRem(26)};
  }
`
const TopIconsWrapper = styled.ul`
  display: flex;
  justify-content: center;
`
const BottomIconsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${convertPxToRem(13)};
`
const StarIconWrapper = styled.div`
  border-radius: 50%;
  background-color: ${secondaryBackgroundGrey};
  padding: ${convertPxToRem(16, 11, 22, 17)};
  margin: ${convertPxToRem(0, 20, 0, 26)};
`
const TopIcon = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: ${convertPxToRem(115)};
  :last-child {
    margin-right: 0;
  }
`
const BottomIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const IconTitle = styled.span`
  color: ${secondaryGrey};
  ${text3_12};
  margin-top: ${convertPxToRem(7)};
  width: ${convertPxToRem(75)};
  text-align: center;
`
const Title = styled.span`
  color: ${primaryDarkNavy};
  ${heading2_29};
  text-align: center;
  margin-top: ${convertPxToRem(50)};
  ${mediaQueryFor.mobile} {
    margin-top: ${convertPxToRem(28)};
  }
`
const DescriptionWrapper = styled.div`
  margin: ${convertPxToRem(16, 0, 24)};
  padding: ${convertPxToRem(0, 8)};
  text-align: center;
  display: flex;
  flex-direction: column;
  ${mediaQueryFor.mobile} {
    text-align: start;
  }
`
const FirstDescription = styled.span`
  color: ${secondaryGrey};
  ${text1_16};
  margin-top: ${convertPxToRem(6)};
  margin-bottom: ${convertPxToRem(25)};
`
const SecondDescription = styled.span`
  color: ${secondaryGrey};
  ${text1_16};
  margin-top: ${convertPxToRem(6)};
`
