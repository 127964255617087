import { attach, combine, createDomain, restore, sample, split } from 'effector'
import { createGate } from 'effector-react'

import { ROUTES } from 'src/constants/routesConstants'
import {
  $currentDrugConfig,
  drugDetailsRequested,
  $drugSearchFlowEntrypoint,
  DrugSearchFlowEntrypoint,
  $otherPharmacies,
} from 'src/entities/drugDetails'
import { $$navigate } from 'src/entities/navigate'
import { createChatFx } from 'src/features/drugSearchTicketCreation'
import { $$drugSearchConfigurationStorage } from 'src/shared/storage/drugSearch'

import { DrugSearchSortingOrder, sortDrugsBy } from './sortDrugsBy'

interface DrugSearchDetailsGateProps {
  drugName: string
  dispatchOpenChatThread: (payload: string) => void
  dispatchSetIsShowThirdSectionModal: (payload: boolean) => void
}

export const DrugSearchDetails = createDomain('DrugSearchDetails')

export const DrugSearchDetailsGate = createGate<DrugSearchDetailsGateProps>({
  name: 'DrugSearchDetailsGate',
  domain: DrugSearchDetails,
})

export const navigatedBack = DrugSearchDetails.event()
export const sortingOrderChanged =
  DrugSearchDetails.event<DrugSearchSortingOrder>()
export const drugDetailsReset = DrugSearchDetails.event()
export const chatWidgetDismissed = DrugSearchDetails.event()

export const $otherPharmaciesSortingOrder =
  DrugSearchDetails.store<DrugSearchSortingOrder>(DrugSearchSortingOrder.Price)
export const $isShowChatWidget = restore(
  chatWidgetDismissed.map(() => false),
  true,
)

export const $sortedOtherPharmacies = combine(
  $otherPharmaciesSortingOrder,
  $otherPharmacies,
  (order, drugs) => sortDrugsBy(order, drugs),
)

const startOverFx = attach({
  source: $$navigate.$fn,
  effect(navigate) {
    void navigate(`/${ROUTES.DRUG_SEARCH_PATH}`)
  },
})

const openDrugSearchChatFx = attach({
  source: DrugSearchDetailsGate.state,
  effect(
    { dispatchOpenChatThread, dispatchSetIsShowThirdSectionModal },
    chatId: string,
  ) {
    dispatchOpenChatThread(chatId)
    dispatchSetIsShowThirdSectionModal(true)
  },
})

sample({
  clock: DrugSearchDetailsGate.open,
  filter: ({ drugName }) => !drugName,
  target: startOverFx,
})

sample({
  clock: DrugSearchDetailsGate.open,
  filter: ({ drugName }) => Boolean(drugName),
  fn: ({ drugName }) => drugName,
  target: drugDetailsRequested,
})

sample({
  clock: sample({
    clock: DrugSearchDetailsGate.open,
    source: $$drugSearchConfigurationStorage.$state,
    fn: (savedDrugs, { drugName }) =>
      savedDrugs.find((savedDrug) => savedDrug.drugName === drugName)!,
  }),
  filter: Boolean,
  target: $currentDrugConfig,
})

sample({
  clock: sortingOrderChanged,
  target: $otherPharmaciesSortingOrder,
})

sample({
  clock: createChatFx.doneData,
  filter: DrugSearchDetailsGate.status,
  target: openDrugSearchChatFx,
})

split({
  source: navigatedBack,
  match: $drugSearchFlowEntrypoint,
  cases: {
    [DrugSearchFlowEntrypoint.Activity]: $$navigate.to(
      `/${ROUTES.HOME_ACTIVITY_PATH}`,
    ),
    [DrugSearchFlowEntrypoint.Medications]: $$navigate.to(
      `/${ROUTES.HOME_MEDICATIONS_CABINET_PATH}`,
    ),
    __: $$navigate.to(`/${ROUTES.HOME_MEDICATIONS_CABINET_PATH}`),
  },
})
