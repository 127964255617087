import { IconProps } from 'src/components/SvgIcons/types'
import { primaryBlue } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function LogoIcon({
  width = convertPxToRem(140),
  height = convertPxToRem(140),
  fill = primaryBlue,
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 140 140"
    >
      <path
        fill={fill}
        d="M114.606 56.738h-33.4c-18.461 0-31.083 9.99-31.083 28.302v50.016h28.785V85.04h35.72V56.738h-.022z"
      />
      <path
        fill={fill}
        d="M78.909 49.956V7H50.123v49.738H0v28.58h43.21C43.577 64.824 57.7 50.92 78.91 49.956zM96.51 39.988L140 71.392l-43.49 31.383V39.988z"
      />
    </svg>
  )
}

export default LogoIcon
