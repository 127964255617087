import React from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  primaryBlue,
  primaryWhite,
  primaryDarkNavy,
  secondaryLightGrey,
} from 'src/theme/colors'
import { textLink3_10 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function ActiveTag({ isResolved, isMessageNew }) {
  const { t } = useTranslation()
  const i18n = {
    statusIsNew: t('translation.chat.thread.chat_bubble.status_new'),
    statusIsResolved: t('translation.chat.thread.chat_bubble.status_resolved'),
  }

  return (
    <>
      {isMessageNew && (
        <Root background={primaryBlue}>
          <TagText textColor={primaryWhite}>{i18n.statusIsNew}</TagText>
        </Root>
      )}
      {isResolved && (
        <Root background={secondaryLightGrey}>
          <TagText textColor={primaryDarkNavy}>{i18n.statusIsResolved}</TagText>
        </Root>
      )}
    </>
  )
}

export default ActiveTag

const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ background }) => background};
  border-radius: ${convertPxToRem(5)};
  margin-left: ${convertPxToRem(8)};
  padding: ${convertPxToRem(3)};
`
const TagText = styled.span`
  color: ${({ textColor }) => textColor};
  text-align: center;
  ${textLink3_10};
`
