import React from 'react'

import styled from 'styled-components'

import FeatureGuard from 'src/components/FeatureGuard'
import { FeatureFlag } from 'src/constants/featureFlags'
import { BillSupportIcon } from 'src/shared/ui/icons/misc/BillSupportIcon'
import { RatingsWithTooltip } from 'src/shared/ui/RatingsWithTooltip'
import { secondaryGrey } from 'src/theme/colors'
import { heading4_21, heading6_16, text3_12 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { AppointmentProfileAddress } from './AppointmentProfileAddress'

interface AppointmentProfileProps {
  name: string
  specialty?: string | null

  image?: React.ReactElement | null

  rating: number
  cost: number

  address: string
  distance: number

  onRatingToolipClicked: () => void

  dataTestProp?: string
}

export function AppointmentProfile({
  name,
  specialty = null,

  image = null,

  rating,
  cost,

  address,
  distance,

  onRatingToolipClicked,

  dataTestProp = 'appointmentProfile',
}: AppointmentProfileProps) {
  return (
    <Wrapper data-test={dataTestProp}>
      {image}

      <AssigneeDetails>
        <AssigneePersonalDetails>
          <AssigneeName>{name}</AssigneeName>
          {specialty && <AssigneeSpeciality>{specialty}</AssigneeSpeciality>}

          <FeatureGuard permit={FeatureFlag.RATINGS}>
            <RatingsWithTooltip
              rating={rating}
              onTooltipClick={onRatingToolipClicked}
            />
          </FeatureGuard>
        </AssigneePersonalDetails>

        <AppointmentProfileAddress address={address} distance={distance} />

        <CostDetails>
          <BillSupportIcon />
          <span>{cost}</span>
        </CostDetails>
      </AssigneeDetails>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: start;
  justify-content: start;

  gap: ${convertPxToRem(16)};
`

const AssigneeDetails = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: start;

  gap: ${convertPxToRem(8)};
`

const AssigneePersonalDetails = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: start;

  gap: ${convertPxToRem(2)};
`

const AssigneeName = styled.span`
  ${heading6_16};
`

const AssigneeSpeciality = styled.span`
  ${text3_12};

  color: ${secondaryGrey};
`

const CostDetails = styled.div`
  ${heading4_21};
  color: ${secondaryGrey};

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: start;

  gap: ${convertPxToRem(13)};

  height: ${convertPxToRem(28)};

  > svg {
    width: ${convertPxToRem(20)};
    height: ${convertPxToRem(20)};

    fill: ${secondaryGrey};
  }
`
