import React, { memo } from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import styled from 'styled-components'

import ButtonCall40 from 'src/components/Buttons/ButtonCall40'
import ButtonClose40 from 'src/components/Buttons/ButtonClose40'
import ButtonNavigateToChatNew40 from 'src/components/Buttons/ButtonNavigateToChatNew40'
import NavigatorIconLightTheme from 'src/components/NavigatorIcons/NavigatorIconLightTheme'
import { TUTORIAL_FIRST_STEP } from 'src/sections/SectionDashboard/dashboardHelpers'
import {
  Root,
  HeaderBox,
  ButtonCloseWrapper,
  TitleBox,
  Title,
} from 'src/sections/SectionNavigator/styled'
import { setIsShowThirdSectionModal } from 'src/store/slices/common'
import { isDesktopView } from 'src/utils/responsiveHelpers'

function InboxHeader({
  hasInboxTotalCount,
  hideThirdSectionModal = () => {},
  navigateToChatNew,
  isLoadingData,
  isFirstTutorialStep = false,
}) {
  const { t } = useTranslation()
  const i18n = {
    helpTitle: t('translation.chat.inbox.title'),
  }

  const isCurrentTutorialStep = isFirstTutorialStep && hasInboxTotalCount

  return (
    <>
      <Root hasInboxTotalCount={hasInboxTotalCount}>
        <HeaderBox isCurrentTutorialStep={isCurrentTutorialStep}>
          <ButtonCloseWrapper>
            <ButtonClose40
              disabled={isCurrentTutorialStep}
              onClick={
                isCurrentTutorialStep ? null : () => hideThirdSectionModal()
              }
              withShadow
              isNotVisibleCursor={isDesktopView}
            />
          </ButtonCloseWrapper>

          <Navigator>
            <NavigatorBox>
              <NavigatorIconLightTheme isPreventOnClick />
            </NavigatorBox>

            {!isLoadingData && (
              <NavigatorHelp>
                <NavigateToChatNewWrapper
                  onClick={isCurrentTutorialStep ? null : navigateToChatNew}
                >
                  <ButtonNavigateToChatNew40 disabled={isCurrentTutorialStep} />
                </NavigateToChatNewWrapper>

                <ButtonCall40 isDisabled={isCurrentTutorialStep} withShadow />
              </NavigatorHelp>
            )}
          </Navigator>
        </HeaderBox>

        <TitleBox>
          <Title>{i18n.helpTitle}</Title>
        </TitleBox>
      </Root>
    </>
  )
}

const NavigateToChatNewWrapper = styled.div`
  margin-right: 8px;
`
const Navigator = styled.div`
  display: flex;
  justify-content: space-between;
  width: 52%;
`
export const NavigatorHelp = styled.div`
  display: flex;
`
const NavigatorBox = styled.div`
  transform: translateX(-50%);
`

InboxHeader.propTypes = {
  hasInboxTotalCount: PropTypes.bool.isRequired,
  hideThirdSectionModal: PropTypes.func,
  navigateToChatNew: PropTypes.func.isRequired,
  isLoadingData: PropTypes.bool.isRequired,
  isFirstTutorialStep: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  isFirstTutorialStep:
    state.tutorial.tutorialStep === TUTORIAL_FIRST_STEP &&
    !state.tutorial.isCompleted,
})

const mapDispatchToProps = (dispatch) => ({
  hideThirdSectionModal: () => dispatch(setIsShowThirdSectionModal(false)),
})

export default connect(mapStateToProps, mapDispatchToProps)(memo(InboxHeader))
