import { FeedItem } from 'src/entities/feedItems'

import { FeedItemType, IterableInAppMessage } from './types'

const formatInAppsToActivityCards = (inAppMessages: IterableInAppMessage[]) =>
  inAppMessages.map((message) => ({
    card: message,
    id: message.messageId,
    type: FeedItemType.InApp,
    createdAt: message.createdAt,
  }))

const extractResourceStatus = (resource: FeedItem['resource']) => {
  switch (resource.__typename) {
    case 'CarePlan':
      return resource.carePlanStatus
    case 'BillClaim':
      return resource.billClaimStatus
    case 'Appointment':
      return resource.appointmentStatus
  }
}

const formatFeedItemsToActivityCards = (feedItems: FeedItem[]) =>
  feedItems.map((item) => {
    const resource = item?.resource ?? {}
    return {
      card: {
        ...item,
        resourceId: resource.id ?? '',
        resourceImageUrl: 'imageUrl' in resource ? resource.imageUrl : '',
        isResourceArchived:
          'isArchived' in resource ? resource.isArchived : false,
        resourceStatus: extractResourceStatus(resource),
      },
      id: item.id,
      type: FeedItemType.Todo,
      createdAt: item.updatedAt
        ? new Date(item.updatedAt).getTime()
        : new Date(item.createdAt).getTime(),
    }
  })

interface FilterInAppsByDateParams {
  inAppMessages: IterableInAppMessage[]
  ISODate: string
}

export function filterInAppsByDate({
  inAppMessages,
  ISODate,
}: FilterInAppsByDateParams) {
  const epochMs = new Date(ISODate).getTime()
  return inAppMessages.filter((message) => {
    const createdAt = message.createdAt || new Date().getTime()
    return createdAt > epochMs
  })
}

function getISODate(date: Date | string | null) {
  if (!date) {
    return new Date().toISOString()
  }
  if (typeof date === 'string') {
    return date
  }
  return date.toISOString()
}

interface GetCurrentPartInAppMessagesParams {
  inAppMessages: IterableInAppMessage[]
  hasTodoNextPage: boolean
  lastTodoCardDate: Date | string
}

function getCurrentPartInAppMessages({
  inAppMessages,
  hasTodoNextPage,
  lastTodoCardDate,
}: GetCurrentPartInAppMessagesParams) {
  if (hasTodoNextPage && !!lastTodoCardDate) {
    return filterInAppsByDate({
      inAppMessages,
      ISODate: getISODate(lastTodoCardDate),
    })
  }
  return inAppMessages
}

interface MergeFeedItemsParams {
  feedItems: FeedItem[]
  inAppMessages: IterableInAppMessage[]
  hasTodoNextPage: boolean
}

export function mergeFeedItems({
  feedItems,
  inAppMessages,
  hasTodoNextPage,
}: MergeFeedItemsParams) {
  if (feedItems.length === 0) {
    return formatInAppsToActivityCards(inAppMessages).sort(
      (x, y) => y.createdAt - x.createdAt,
    )
  }

  const lastTodoCardDate = feedItems[feedItems.length - 1].updatedAt ?? ''

  const currentPartInAppMessages = getCurrentPartInAppMessages({
    inAppMessages,
    hasTodoNextPage,
    lastTodoCardDate,
  })

  return [
    ...formatInAppsToActivityCards(currentPartInAppMessages),
    ...formatFeedItemsToActivityCards(feedItems),
  ].sort((x, y) => y.createdAt - x.createdAt)
}
