import React, { memo } from 'react'

import { useTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import styled from 'styled-components'

import ButtonClose40 from 'src/components/Buttons/ButtonClose40'
import NavigatorIconLightTheme from 'src/components/NavigatorIcons/NavigatorIconLightTheme'
import {
  HeaderBox,
  ButtonCloseWrapper,
  TitleBox,
  Title,
} from 'src/sections/SectionNavigator/styled'
import { withDispatch } from 'src/store'
import { setIsShowThirdSectionModal } from 'src/store/slices/common'
import { primaryWhite, secondaryBackgroundGrey } from 'src/theme/colors'
import { convertPxToRem, isDesktopView } from 'src/utils/responsiveHelpers'

interface BlankInboxHeaderProps extends ConnectedProps<typeof connector> {
  hasInboxTotalCount: boolean
}

function BlankInboxHeaderComponent({
  hasInboxTotalCount,
  dispatchHideThirdSectionModal,
}: BlankInboxHeaderProps) {
  const { t } = useTranslation()
  const i18n = {
    helpTitle: t('translation.chat.inbox.title'),
  }

  return (
    <Root data-has-count={hasInboxTotalCount}>
      <HeaderBox>
        <ButtonCloseWrapper>
          <ButtonClose40
            onClick={dispatchHideThirdSectionModal}
            withShadow
            isNotVisibleCursor={isDesktopView}
          />
        </ButtonCloseWrapper>

        <Navigator>
          <NavigatorBox>
            <NavigatorIconLightTheme isInbox isPreventOnClick />
          </NavigatorBox>
        </Navigator>
      </HeaderBox>

      <TitleBox>
        <Title>{i18n.helpTitle}</Title>
      </TitleBox>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${convertPxToRem(26)};
  background: ${primaryWhite};
  &[data-has-count='true'] {
    background: ${secondaryBackgroundGrey};
  }
`

const Navigator = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50%;
`
const NavigatorBox = styled.div`
  transform: translateX(-50%);
`

const mapDispatchToProps = withDispatch({
  hideThirdSectionModal: () => setIsShowThirdSectionModal(false),
})

const connector = connect(null, mapDispatchToProps)
export const BlankInboxHeader = connector(memo(BlankInboxHeaderComponent))
