import { gql } from '@apollo/client'

import CHAT_MESSAGE_FRAGMENT from 'src/apollo/fragments/CHAT_MESSAGE_FRAGMENT'

const CHAT_REPLY_MUTATION = gql`
  mutation replyToChatMutation(
    $id: ID!
    $attributes: ChatMessageAttributesInput!
  ) {
    replyToChat(id: $id, attributes: $attributes) {
      ...chatMessageFragment
    }
  }
  ${CHAT_MESSAGE_FRAGMENT}
`

export default CHAT_REPLY_MUTATION
