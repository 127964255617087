import { invoke } from '@withease/factories'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import { sample } from 'effector'
import { once } from 'patronum'

import config from 'src/config'
import { $$darkly, createFlag, watchFlag } from 'src/shared/lib/darkly'

const GatewayFlag = invoke(() =>
  createFlag<boolean>({ key: 'web.api-gateway', default: true }),
)

sample({ clock: once($$darkly.ready), target: GatewayFlag.aquire })

watchFlag(GatewayFlag)

const uploadLink = createUploadLink({
  uri: () => {
    // UploadLink is not reactive, it will pull current $value on each request
    // eslint-disable-next-line effector/no-getState
    const isGateway = GatewayFlag.$value.getState()

    return isGateway ? config.GATEWAY_URL : config.API_URL
  },
})

export default uploadLink
