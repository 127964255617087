import { gql } from '@apollo/client'

const CHAT_START_MUTATION = gql`
  mutation startChatMuattion($attributes: ChatAttributesInput) {
    startChat(attributes: $attributes) {
      id
    }
  }
`

export default CHAT_START_MUTATION
