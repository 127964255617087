/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
import { pathOr } from 'ramda'

import MiscIcon from 'src/components/SvgIcons/ProfileIcons/MiscIcon'
import NotificationIcon from 'src/components/SvgIcons/ProfileIcons/NotificationIcon'
import { ROUTES } from 'src/constants/routesConstants'
import { Sprite } from 'src/shared/ui/Sprite'
import i18next from 'src/translations/i18n'
import { formatPhone, PHONE_FORMATS } from 'src/utils/phoneHelpers'
import getReleaseName from 'src/utils/sentryHelpers/getReleaseName'
import persistentStorageInterface from 'src/utils/storage/persistent'

export const extractProfileData = (data) => {
  const currentUser = pathOr({}, ['currentUser'], data)
  const firstName = pathOr('', ['first_name'], currentUser)
  const lastName = pathOr('', ['last_name'], currentUser)
  const fullName = `${firstName} ${lastName}`
  const isSurveyCompleted = extractSurveyGroups(currentUser)

  return {
    id: pathOr('', ['id'], currentUser),
    address: pathOr({}, ['address'], currentUser),
    email: pathOr('', ['email'], currentUser),
    employeeId: pathOr('', ['employee_id'], currentUser),
    gender: pathOr('', ['gender'], currentUser),
    insuranceId: pathOr('', ['insurance_id'], currentUser),
    preferredPhoneNumber: pathOr('', ['preferred_phone_number'], currentUser),
    preferredEmail: pathOr('', ['preferred_email'], currentUser),
    organizationName: pathOr('', ['organization', 'name'], currentUser),
    medicalInsuranceName: pathOr(
      'No medical plan',
      ['medical_insurance', 'name'],
      currentUser,
    ),
    questions: pathOr([], ['survey_registration', 'questions'], currentUser),
    questionCount: pathOr(
      [],
      ['survey_registration', 'questions_count'],
      currentUser,
    ),
    firstName,
    lastName,
    fullName,
    isSurveyCompleted,
  }
}

export function sanitizeWeightValue(value) {
  const sanitized = value.replace(/[^0-9]/g, '')
  return sanitized
}

export const extractSurveyGroups = (data) => {
  const questions = pathOr(
    [],
    ['survey_registration', 'questions', 'nodes'],
    data,
  )
  const questionsCount = pathOr(
    [],
    ['survey_registration', 'questions_count'],
    data,
  )

  const isQuestionAnswered = questions.filter((node) => node.is_answered)
  return isQuestionAnswered.length === questionsCount
}
export const ABOUT_RIGHTWAY_LINK = 'https://www.rightwayhealthcare.com/our-team'

const appVersion = getReleaseName()

export const getProfileOptions = ({
  data,
  handleSignOut,
  displayDependentsOption,
  hasHealthProfileAccess,
  hasWellnessProgramAccess,
  navigate,
}) => {
  const profileOptionsArray = [
    {
      id: SECTIONS.ACCOUNT_DETAILS,
      title: data.fullName,
      subtitle: i18next.t(
        'translation.profile.account_settings.preference.title',
      ),
      onClick: () => navigate(`/${ROUTES.PROFILE_ACCOUNT_SETTINGS_PATH}`),
    },
    hasHealthProfileAccess && {
      id: SECTIONS.HEALTH_PROFILE,
      title: i18next.t(
        'translation.care_plans.survey.healthprofile_forward.label',
      ),
      onClick: () => navigate(`/${ROUTES.PROFILE_HEALTH_PATH}`),
    },
    hasWellnessProgramAccess && {
      id: SECTIONS.WELLNESS_PROGRAM,
      title: i18next.t('translation.profile.wellness.link'),
      onClick: () => navigate(`/${ROUTES.PROFILE_WELLNESS_PROGRAM_PATH}`),
    },
    displayDependentsOption && {
      id: SECTIONS.DEPENDENTS,
      title: i18next.t(
        'translation.profile.account_settings.preference.dependents',
      ),
      onClick: () => navigate(`/${ROUTES.PROFILE_DEPENDENTS_PATH}`),
    },
    {
      id: PROFILE_OPTIONS.ABOUT_TEXT,
      title: i18next.t('translation.profile.profile_sections.about'),
    },
    {
      id: SECTIONS.APP_VERSION,
      title: i18next.t('translation.profile.profile_sections.app_version', {
        version: appVersion,
      }),
      onClick: () => {
        navigator.clipboard.writeText(appVersion)
      },
      linkWithText: i18next.t('translation.profile.profile_sections.copy'),
    },
    {
      id: SECTIONS.ABOUT,
      title: i18next.t('translation.profile.profile_sections.about'),
      onClick: () => {
        window.location.href = ABOUT_RIGHTWAY_LINK
      },
    },
    {
      id: SECTIONS.FEEDBACK,
      title: i18next.t('translation.profile.profile_sections.feedback'),
      onClick: () => navigate(ROUTES.PROFILE_LEAVE_FEEDBACK_PATH),
    },
    {
      id: SECTIONS.SIGN_OUT,
      title: i18next.t('translation.profile.profile_sections.sign_out'),
      onClick: async () => {
        const {
          access: getPersistentStorageState,
          modify: { clearRedirect },
        } = persistentStorageInterface

        const { redirectUrl } = getPersistentStorageState()

        clearRedirect()
        await handleSignOut()

        if (redirectUrl) window.location.replace(redirectUrl)
      },
    },
  ].filter(Boolean)

  return profileOptionsArray
}

export const QUESTION_TYPES = {
  CHECKBOX: 'checkbox',
  HEIGHT: 'height',
  PHONE: 'phone',
  RADIO: 'radio',
  WEIGHT: 'weight',
}

export const updateSummaryAnswersWithUsersPreviousAnswers = (sortedQuestions) =>
  sortedQuestions
    ?.map((question) => {
      const answers = question.answers.nodes
        .map(
          (node) =>
            node.user_answer_value !== null && {
              ...node,
              currentQuestionId: question.id,
            },
        )
        .filter((node) => node)

      return { ...question, currentAnswer: answers }
    })
    .filter((question) => question.currentAnswer.length !== 0)

export const STEPS = {
  HEALTH_PROFILE_ENDING: 'HEALTH_PROFILE_ENDING',
  HEALTH_PROFILE_INTRO: 'HEALTH_PROFILE_INTRO',
  HEALTH_PROFILE_SUMMARY: 'HEALTH_PROFILE_SUMMARY',
  HEALTH_PROFILE_SURVEY: 'HEALTH_PROFILE_SURVEY',
}

export const updateArrayWithPreviouslyCheckedValues = (array1, array2) => {
  const combinedArray = [...array1, ...array2]
  const removeDuplicates = Object.values(
    array1.concat(combinedArray).reduce((combined, item) => {
      // eslint-disable-next-line no-param-reassign
      combined[item.title] = item
      return combined
    }, {}),
  )
  const valuesCheckedByUserPreviously = removeDuplicates.map((obj) => {
    if (array2.find((obj2) => obj.title === obj2.title)) {
      return { ...obj, checked: true }
    }

    return obj
  })

  return valuesCheckedByUserPreviously
}

export const getParsedValues = (usersAnswer) => {
  const parsedValuesArray = usersAnswer?.split("'")
  const parsedFeetValue = parsedValuesArray[0]
  const parsedInchesValue = parsedValuesArray[1]?.match(/\d+/)[0]

  return [parsedFeetValue, parsedInchesValue]
}

export const SECTIONS = {
  ACCOUNT_DETAILS: 'ACCOUNT_DETAILS',
  HEALTH_PROFILE: 'HEALTH_PROFILE',
  NOTIFICATIONS: 'NOTIFICATIONS',
  ABOUT: 'ABOUT',
  FEEDBACK: 'FEEDBACK',
  SIGN_OUT: 'SIGN_OUT',
  DEPENDENTS: 'DEPENDENTS',
  APP_VERSION: 'APP_VERSION',
  WELLNESS_PROGRAM: 'WELLNESS_PROGRAM',
}

export const PROFILE_ICONS = {
  [SECTIONS.ACCOUNT_DETAILS]: <MiscIcon />,
  [SECTIONS.HEALTH_PROFILE]: <Sprite name="misc/health_profile" />,
  [SECTIONS.NOTIFICATIONS]: <NotificationIcon />,
  [SECTIONS.ABOUT]: <Sprite name="misc/rightway" />,
  [SECTIONS.FEEDBACK]: <Sprite name="settings/feedback" />,
  [SECTIONS.SIGN_OUT]: <Sprite name="settings/sign_out" />,

  [SECTIONS.DEPENDENTS]: <Sprite name="settings/dependents" />,
  [SECTIONS.APP_VERSION]: <Sprite name="settings/launch" />,
  [SECTIONS.WELLNESS_PROGRAM]: <Sprite name="settings/wellness" />,
}

export const getAccountSettings = ({ i18n, profileSurveyData }) => {
  let sex
  const {
    id,
    firstName,
    lastName,
    gender,
    organizationName,
    employeeId,
    medicalInsuranceName,
    preferredPhoneNumber,
  } = profileSurveyData

  if (!id) return []

  if (gender === 'm') {
    sex = 'Male'
  } else if (gender === 'f') {
    sex = 'Female'
  } else {
    sex = 'Other'
  }

  return [
    {
      id: 'personalInformation',
      title: i18n.personalInformationTitle,
      rows: [
        {
          id: 'personalInformationFirstName',
          title: i18n.firstName,
          value: firstName,
        },
        {
          id: 'personalInformationLastName',
          title: i18n.lastName,
          value: lastName,
        },
        { id: 'personalInformationGender', title: i18n.gender, value: sex },
      ],
    },
    {
      id: 'contactInformation',
      rows: [
        {
          id: 'contactInformation',
          value: preferredPhoneNumber ? formatPhone(preferredPhoneNumber) : '',
        },
      ],
    },
    {
      id: 'employerInformation',
      title: i18n.employerInfoTitle,
      rows: [
        {
          id: 'employerInformationEmployer',
          title: i18n.employer,
          value: organizationName,
        },
        {
          id: 'employerInformationEmployerID',
          title: i18n.employerId,
          value: employeeId,
        },
        {
          id: 'employerInformationEmployerInsurancePlan',
          title: i18n.insurancePlan,
          value: medicalInsuranceName,
        },
      ],
    },
    {
      id: 'security',
      title: i18n.securityTitle,
      rows: [
        { id: 'securityChangeEmail', title: i18n.changeEmail },
        { id: 'securityChangePassword', title: i18n.changePassword },
      ],
    },
    {
      id: 'privacy',
      title: i18n.privacyTitle,
      rows: [
        { id: 'privacyPolicy', title: i18n.privacyPolicy },
        { id: 'privacyTerms', title: i18n.termsOfService },
        { id: 'privacyConsent', title: i18n.informedConsent },
      ],
    },
  ]
}

export const STEPS_ACCOUNT_SETTINGS = {
  EMAIL: 'EMAIL',
  PASSWORD: 'PASSWORD',
  ADDRESS: 'ADDRESS',
  PHONE: 'PHONE',
  SUCCESS: 'SUCCESS',
}
export const getProfileAccountDetailsCallPath = (id) => {
  if (id === 'privacyPolicy') {
    return 'privacypolicy'
  }
  if (id === 'privacyTerms') {
    return 'termsservices'
  }
  if (id === 'privacyConsent') {
    return 'informed-consent'
  }
}

export const filterDataForMutation = (
  currentQuestion,
  summaryAnswers,
  inputValues,
  userAnswer,
) => {
  let answerObj
  let mutationAnswer
  let questionId
  let userAnswerValue
  const { height, phoneNumber, weight } = inputValues
  const isCheckBoxQ = currentQuestion.kind === 'checkbox'
  const isHeightQ = currentQuestion.kind === 'height'
  const isPhoneNumberQ = currentQuestion.kind === 'phone'
  const isRadioQ = currentQuestion.kind === 'radio'
  const isWeightQ = currentQuestion.kind === 'weight'

  if (isCheckBoxQ) {
    answerObj = summaryAnswers.find(
      (answer) => answer.id === currentQuestion.id,
    )
    userAnswerValue = answerObj.currentAnswer
    questionId = answerObj.id

    const answers = userAnswerValue.map((answer) => ({
      id: answer.id,
      user_answer_value:
        answer.kind === 'text'
          ? answer.otherConditions || answer.user_answer_value
          : answer.title,
    }))

    mutationAnswer = {
      variables: {
        question_id: questionId,
        answers,
      },
    }
  } else if (isWeightQ || isHeightQ || isPhoneNumberQ) {
    answerObj = pathOr({}, ['answers', 'nodes', 0], currentQuestion)
    const { id } = answerObj
    userAnswerValue = isWeightQ
      ? weight.value
      : isHeightQ
        ? height.value
        : isPhoneNumberQ
          ? formatPhone(phoneNumber.value, PHONE_FORMATS.plainWithCode)
          : null

    mutationAnswer = {
      variables: {
        question_id: currentQuestion.id,
        answers: [
          {
            id,
            user_answer_value: userAnswerValue,
          },
        ],
      },
    }
  } else if (isRadioQ) {
    const { id, title } = userAnswer[0]

    if (userAnswer.length === 2) {
      const secondAnswerId = userAnswer[1].id
      const secondAnswerTitle = userAnswer[1].doctor

      mutationAnswer = {
        variables: {
          question_id: currentQuestion.id,
          answers: [
            {
              id,
              user_answer_value: title,
            },
            {
              id: secondAnswerId,
              user_answer_value: secondAnswerTitle,
            },
          ],
        },
      }
    } else {
      mutationAnswer = {
        variables: {
          question_id: currentQuestion.id,
          answers: [
            {
              id,
              user_answer_value: title,
            },
          ],
        },
      }
    }
  }

  return mutationAnswer
}

export const PROFILE_OPTIONS = {
  HEALTH_PROFILE: 'HEALTH_PROFILE',
  ABOUT_TEXT: 'ABOUT_TEXT',
}

export const getRadioAnswer = (inputValue, currentAnswer) => {
  const doctorAnswer = currentAnswer.find((answer) => answer?.kind === 'doctor')
  if (doctorAnswer && inputValue) {
    const doctorName = doctorAnswer?.doctor || doctorAnswer?.user_answer_value
    return `${inputValue}, ${doctorName}`
  }
  return inputValue
}

export const getCheckboxAnswer = (currentAnswer) =>
  currentAnswer
    .map((checkboxAnswer) =>
      checkboxAnswer.kind === 'text'
        ? checkboxAnswer.otherConditions || checkboxAnswer.user_answer_value
        : checkboxAnswer.title,
    )
    .join(', ')
