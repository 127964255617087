import { useContext } from 'react'

import { NetworkStatus } from '@apollo/client'
import styled from 'styled-components'

import Spinner from 'src/components/Spinner'
import { Paginated } from 'src/features/Paginated'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { ChatMessage } from '../api/chatMessage'
import { useChatQuery } from '../api/chatQuery'
import { useChatSubscription } from '../api/chatSubscription'
import { ThreadIdContext } from '../lib/ThreadIdContext'
import { useReadChat } from '../lib/useReadChat'

import { Message } from './Message'

export function ChatContent() {
  const id = useContext(ThreadIdContext)!

  const { data, status, fetchMore } = useChatQuery(id)

  useChatSubscription(id)

  useReadChat()

  if (!data?.chat?.messages?.nodes) return <Spinner />

  return (
    <Paginated
      parent={Messages}
      fetchMore={fetchMore}
      after={status === NetworkStatus.fetchMore && <PaginationLoader />}
    >
      {data.chat.messages.nodes
        .filter((message): message is ChatMessage => Boolean(message))
        .map((message) => (
          <Message key={message.id} message={message} />
        ))}
    </Paginated>
  )
}

const Messages = styled.div`
  display: flex;
  flex-flow: column-reverse nowrap;

  overscroll-behavior-y: contain;
  overflow-y: auto;

  gap: ${convertPxToRem(16)};

  padding: ${convertPxToRem(0, 16)};
`

const PaginationLoader = styled(Spinner).attrs({ size: 'small' })`
  // Add space above and below
  // to prevent rotating Spinner from affecting overflow
  flex: 0 0 ${convertPxToRem(32)};
`
