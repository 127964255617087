import React, { memo } from 'react'

import moment from 'moment'
import PropTypes, { string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { secondaryGrey, primaryDarkNavy } from 'src/theme/colors'
import { text3_12 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

const ChatDeliveredTime = ({
  creationTime,
  deliveryLoading,
  isSelfSent,
  displayDay,
}) => {
  const { t } = useTranslation()
  const i18n = {
    justNow: t('translation.chat.inbox.with_messages.inbox_card.just_now'),
    sending: t('translation.chat.inbox.with_messages.inbox_card.sending'),
  }

  const now = moment()
  const isMessageDeliveredNow = moment(now).diff(creationTime, 'minutes') < 1

  return (
    <Date isSelfSent={isSelfSent}>
      {deliveryLoading ? (
        i18n.sending
      ) : (
        <>
          <Day isMessageDeliveredNow={isMessageDeliveredNow}>
            {isMessageDeliveredNow ? i18n.justNow : displayDay.day}
          </Day>
          {!isMessageDeliveredNow && <Time>{displayDay.dayTime}</Time>}
        </>
      )}
    </Date>
  )
}

export default memo(ChatDeliveredTime)

ChatDeliveredTime.propTypes = {
  deliveryLoading: PropTypes.bool.isRequired,
  isSelfSent: PropTypes.bool.isRequired,
  creationTime: PropTypes.string.isRequired,
  displayDay: PropTypes.objectOf(string).isRequired,
}

const Date = styled.div`
  display: flex;
  flex-shrink: 0;
  align-self: ${({ isSelfSent }) => (isSelfSent ? 'flex-end' : 'flex-start')};
  margin-top: ${convertPxToRem(-12)};
  margin-bottom: ${convertPxToRem(20)};
  color: ${secondaryGrey};
  ${text3_12};
`

const Day = styled.div`
  color: ${({ isMessageDeliveredNow }) =>
    !isMessageDeliveredNow && primaryDarkNavy};
  margin-right: ${convertPxToRem(2)};
`
const Time = styled.div``
