import React from 'react'

import PropTypes from 'prop-types'

import Chat from 'src/sections/SectionNavigator/Chat'
import ChatNewHeader from 'src/sections/SectionNavigator/NavigatorHeader/ChatNewHeader'

export default function ChatNew({
  navigateToChatThread,
  hasInboxTotalCount,
  navigateToInbox,
  isNavigatorOnline,
  navigatedFromInbox,
  setNavigatedFromInbox,
}) {
  return (
    <>
      <ChatNewHeader
        hasInboxTotalCount={hasInboxTotalCount}
        navigateToInbox={navigateToInbox}
        isNavigatorOnline={isNavigatorOnline}
        navigatedFromInbox={navigatedFromInbox}
        setNavigatedFromInbox={setNavigatedFromInbox}
      />
      <Chat
        isChatNew
        navigateToChatThread={navigateToChatThread}
        navigateToInbox={navigateToInbox}
        hasInboxTotalCount={hasInboxTotalCount}
      />
    </>
  )
}

ChatNew.propTypes = {
  navigateToChatThread: PropTypes.func.isRequired,
  hasInboxTotalCount: PropTypes.bool.isRequired,
  isNavigatorOnline: PropTypes.bool.isRequired,
  navigateToInbox: PropTypes.func.isRequired,
  navigatedFromInbox: PropTypes.bool.isRequired,
  setNavigatedFromInbox: PropTypes.func.isRequired,
}
