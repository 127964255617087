import { memo, useState, useEffect } from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import styled from 'styled-components'

import BillingAdvocacyIcon from 'src/components/SvgIcons/BillingAdvocacyIcon'
import { HealthIcon } from 'src/components/SvgIcons/HealthIcon'
import { ClockIcon } from 'src/components/SvgIcons/Misc/ClockIcon'
import MailIcon from 'src/components/SvgIcons/Misc/MailIcon'
import PillIcon from 'src/components/SvgIcons/Misc/PillIcon'
import { QuestionMarkIcon } from 'src/components/SvgIcons/Misc/QuestionMarkIcon'
import OptionsForCareIcon from 'src/components/SvgIcons/OptionsForCareIcon'
import PrimaryCareIcon from 'src/components/SvgIcons/PrimaryCareIcon'
import { FeatureFlag } from 'src/constants/featureFlags'
import ChatQuestion from 'src/sections/SectionNavigator/Chat/ChatQuestion'
import {
  featurePaleRed,
  featurePaleGreen,
  featurePaleBlue,
  featurePaleYellow,
} from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function ChatPrimaryQuestions({
  hasNavFeaturesAccess = false,
  hasPBMFeaturesAccess = false,
  hasBillAdvocacyAccess = false,
  hasCarePlansAccess = false,
  hasAppointmentsAccess = false,
  hasSharedBenefitsAccess = false,
  setMessage,
}) {
  const { t } = useTranslation()
  const i18n = {
    healthIssue: t('translation.chat.thread.answers.health_issue'),
    bookMe: t('translation.chat.thread.answers.book_me'),
    overcharged: t('translation.chat.thread.answers.overcharged'),
    planBenefits: t('translation.chat.thread.answers.plan_benefits'),
    medCost: t('translation.chat.thread.answers.med_cost'),
    mailOrder: t('translation.chat.thread.answers.mail_order'),
    priorAuthStatus: t('translation.chat.thread.answers.prior_auth_status'),
    aboutApp: t('translation.chat.thread.answers.about_app'),
  }

  const [chatPrefills, setChatPrefills] = useState([])

  const hasNavAndPbmAccess = hasPBMFeaturesAccess && hasNavFeaturesAccess

  useEffect(() => {
    const chatPrompts = {
      healthIssue: {
        backgroundColor: featurePaleBlue,
        url: OptionsForCareIcon,
        question: i18n.healthIssue,
      },
      bookMe: {
        backgroundColor: featurePaleBlue,
        url: PrimaryCareIcon,
        question: i18n.bookMe,
      },
      overcharged: {
        backgroundColor: featurePaleGreen,
        url: BillingAdvocacyIcon,
        question: i18n.overcharged,
      },
      planBenefits: {
        backgroundColor: featurePaleRed,
        url: HealthIcon,
        question: i18n.planBenefits,
      },
      mailOrder: {
        backgroundColor: featurePaleYellow,
        url: MailIcon,
        question: i18n.mailOrder,
      },
      medCost: (backgroundColor) => ({
        backgroundColor,
        url: PillIcon,
        question: i18n.medCost,
      }),
      priorAuthStatus: {
        backgroundColor: featurePaleYellow,
        url: ClockIcon,
        question: i18n.priorAuthStatus,
      },
      aboutApp: {
        backgroundColor: featurePaleRed,
        url: QuestionMarkIcon,
        question: i18n.aboutApp,
      },
    }

    const answersArray = []

    if (hasNavAndPbmAccess) {
      if (hasCarePlansAccess) {
        answersArray.push(chatPrompts.healthIssue)
      }
      if (hasSharedBenefitsAccess) {
        answersArray.push(chatPrompts.planBenefits)
      }
      answersArray.push(chatPrompts.medCost(featurePaleYellow))

      if (hasBillAdvocacyAccess) {
        answersArray.splice(1, 0, chatPrompts.overcharged)
      }
    } else if (hasNavFeaturesAccess) {
      if (hasCarePlansAccess) {
        answersArray.push(chatPrompts.healthIssue)
      }

      if (hasAppointmentsAccess) {
        answersArray.push(chatPrompts.bookMe)
      }

      if (hasSharedBenefitsAccess) {
        answersArray.push(chatPrompts.planBenefits)
      }

      if (hasBillAdvocacyAccess) {
        answersArray.splice(2, 0, chatPrompts.overcharged)
      }
    } else if (hasPBMFeaturesAccess) {
      answersArray.push(
        chatPrompts.mailOrder,
        chatPrompts.medCost(featurePaleRed),
        chatPrompts.priorAuthStatus,
        chatPrompts.aboutApp,
      )
    }

    setChatPrefills(answersArray)
  }, [hasNavFeaturesAccess, hasPBMFeaturesAccess, hasNavAndPbmAccess])

  return (
    <Root>
      {chatPrefills.map((questionData) => (
        <ListItem key={questionData.question}>
          <ChatQuestion questionData={questionData} setMessage={setMessage} />
        </ListItem>
      ))}
    </Root>
  )
}

const Root = styled.ul`
  display: flex;
  flex-direction: column;
  padding: ${convertPxToRem(7)} 20px 0;
`
const ListItem = styled.li``

ChatPrimaryQuestions.propTypes = {
  setMessage: PropTypes.func.isRequired,
  hasNavFeaturesAccess: PropTypes.bool,
  hasPBMFeaturesAccess: PropTypes.bool,
  hasCarePlansAccess: PropTypes.bool,
  hasAppointmentsAccess: PropTypes.bool,
  hasBillAdvocacyAccess: PropTypes.bool,
  hasSharedBenefitsAccess: PropTypes.bool,
}

const mapStateToProps = ({ features }) => ({
  hasNavFeaturesAccess: features.featureFlags[FeatureFlag.CORE_NAV],
  hasPBMFeaturesAccess: features.featureFlags[FeatureFlag.PBM],
  hasCarePlansAccess: features.featureFlags[FeatureFlag.CARE_PLANS],
  hasAppointmentsAccess: features.featureFlags[FeatureFlag.APPOINTMENTS],
  hasBillAdvocacyAccess: features.featureFlags[FeatureFlag.BILL_ADVOCACY],
  hasSharedBenefitsAccess:
    features.featureFlags[FeatureFlag.SHARED_INSURANCE_BENEFITS],
})

export default connect(mapStateToProps)(memo(ChatPrimaryQuestions))
