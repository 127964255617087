import React, { useCallback, useState, useEffect, useRef } from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import styled from 'styled-components'

import ButtonSendMessage32 from 'src/components/Buttons/ButtonSendMessage32'
import ModalActionsChatThread from 'src/components/Modals/ModalActionsChatThread'
import AttachFileIcon from 'src/components/SvgIcons/AttachFileIcon'
import {
  TEXT_AREA_LINE_HEIGHT,
  handleTextAreaRows,
} from 'src/sections/SectionNavigator/navigatorHelpers'
import { setPrefilledMessage } from 'src/store/slices/chats'
import { FocusDefault } from 'src/theme/baseStyles'
import {
  featurePaleBlue,
  primaryDarkNavy,
  primaryWhite,
  secondaryBackgroundGrey,
  secondaryLightGrey,
  secondaryMidGrey,
  primaryBlue,
} from 'src/theme/colors'
import { text1_16 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function ChatActions({
  isChatNew = false,
  existingChatId = '',
  handleCreateChat = () => {},
  handleChatReply = () => {},
  navigateToChatThread = () => {},
  loading = false,
  prefilledMessage = '',
  clearPrefilledMessage = () => {},
}) {
  const { t } = useTranslation()
  const i18n = {
    inputPlaceholder: t('translation.chat.thread.input.input_placeholder'),
  }

  const [message, setMessage] = useState('')
  const [isShowModal, setIsShowModal] = useState(false)
  const file = useRef(null)
  const textAreaRef = useRef(null)

  useEffect(() => {
    if (prefilledMessage) {
      setMessage(prefilledMessage)
      clearPrefilledMessage()
    }
    handleTextAreaRows(textAreaRef.current)
  }, [prefilledMessage, clearPrefilledMessage])

  const sendMessage = useCallback(async () => {
    const chatId = isChatNew ? await handleCreateChat() : existingChatId

    const variables = {
      id: chatId,
      attributes: {
        body: message,
        file: file.current,
      },
    }

    // Reset states
    setMessage('')
    file.current = null

    await handleChatReply(variables, isChatNew)

    if (isChatNew) {
      navigateToChatThread(chatId)
    }
  }, [
    existingChatId,
    isChatNew,
    message,
    navigateToChatThread,
    handleCreateChat,
    handleChatReply,
  ])

  const onSendButtonClick = useCallback(
    async (event) => {
      event.preventDefault()

      if (message.trim().length === 0) {
        setMessage('')
      } else {
        await sendMessage()
      }
    },
    [message, sendMessage],
  )

  const handleChange = ({ target }) => {
    handleTextAreaRows(target)
    setMessage(target.value)
  }

  const handleFocus = ({ target }) => {
    handleTextAreaRows(target)
  }

  const onAttachmentButtonClick = () => {
    setIsShowModal((prev) => !prev)
  }

  const ref = useRef()

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  const handleClick = (e) => {
    if (ref?.current?.contains(e.target)) {
      return
    }
    setIsShowModal(false)
  }

  return (
    <Root data-test="сhatActions">
      <ModalWrapper ref={ref}>
        <AttachFileBtnWrapper
          data-test="attachFileButton"
          onClick={onAttachmentButtonClick}
        >
          <AttachFileIcon
            height={convertPxToRem(24)}
            width={convertPxToRem(24)}
            fill={primaryBlue}
          />
        </AttachFileBtnWrapper>
        {isShowModal && (
          <ModalActionsChatThread
            sendMessage={sendMessage}
            disabled={loading}
            id="attach-from-chat"
            cameraTheme={featurePaleBlue}
            setIsShowModal={setIsShowModal}
            fileContainer={file}
          />
        )}
      </ModalWrapper>
      <InputBox>
        <Label htmlFor="chat-message-box" />
        <Input
          id="chat-message-box"
          ref={textAreaRef}
          placeholder={i18n.inputPlaceholder}
          value={message}
          rows={1} // Set initial rows
          onChange={handleChange}
          onFocus={handleFocus}
          data-test="chatMessageInput"
        />
        <ButtonSendMessageWrapper>
          <ButtonSendMessage32
            onClick={onSendButtonClick}
            bgColor={primaryBlue}
            dataTest="chatActionsSendMessage"
            disabled={loading}
          />
        </ButtonSendMessageWrapper>
      </InputBox>
    </Root>
  )
}

const Label = styled.label``

const Root = styled.div`
  display: flex;
  align-items: center;
  padding: ${convertPxToRem(8, 16)};
  border-top: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  background: ${secondaryBackgroundGrey};
`
const ModalWrapper = styled.div``

const InputBox = styled.div`
  position: relative;
  width: 100%;
  padding-left: ${convertPxToRem(16)};
  user-select: none;
`
const ButtonSendMessageWrapper = styled.div`
  position: absolute;
  right: ${convertPxToRem(4)};
  bottom: ${convertPxToRem(2)};
`
const AttachFileBtnWrapper = styled.div`
  position: relative;
  cursor: pointer;
`
const Input = styled.textarea`
  height: auto;
  resize: none;
  overflow: auto;
  width: 100%;
  padding: ${convertPxToRem(11, 38, 7, 16)};
  background: ${primaryWhite};
  border: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  border-radius: ${convertPxToRem(20)};
  color: ${primaryDarkNavy};
  ::-webkit-input-placeholder {
    color: ${secondaryMidGrey};
  }
  ::-moz-placeholder {
    color: ${secondaryMidGrey};
  }
  :-ms-input-placeholder {
    color: ${secondaryMidGrey};
  }
  //hide scrollbar
  ::-webkit-scrollbar {
    // Chrome
    display: none;
  }
  -ms-overflow-style: none; // IE and Edge
  scrollbar-width: none; // Firefox

  ${FocusDefault}
  ${text1_16};
  line-height: ${convertPxToRem(TEXT_AREA_LINE_HEIGHT)};
`

ChatActions.propTypes = {
  isChatNew: PropTypes.bool,
  existingChatId: PropTypes.string,
  handleCreateChat: PropTypes.func,
  handleChatReply: PropTypes.func,
  navigateToChatThread: PropTypes.func,
  loading: PropTypes.bool,
  prefilledMessage: PropTypes.string,
  clearPrefilledMessage: PropTypes.func,
}

const mapStateToProps = (state) => ({
  prefilledMessage: state.chats.prefilledMessage,
})

const mapDispatchToProps = (dispatch) => ({
  clearPrefilledMessage: () => dispatch(setPrefilledMessage('')),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChatActions)
