import styled from 'styled-components'

import { primaryDarkNavy, secondaryGrey } from 'src/theme/colors'
import {
  fontInterRegular,
  heading2_29,
  text1_16,
  text3_12,
} from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${convertPxToRem(53)} 0 ${convertPxToRem(15)} 0;
`

export const Title = styled.h1`
  color: ${primaryDarkNavy};
  ${heading2_29};
`

export const Description = styled.span`
  margin-top: ${convertPxToRem(10)};
  color: ${secondaryGrey};
  ${text1_16};
`

export const Form = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
`
export const Fieldset = styled.fieldset`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;

  min-inline-size: 100%;
  width: 100%;
`

export const Legend = styled.legend``

export const ContentContainer = styled.div`
  height: auto;
  padding-bottom: ${convertPxToRem(10)};
  line-height: ${convertPxToRem(22)};

  p {
    font-family: ${fontInterRegular}, serif;
    font-size: ${convertPxToRem(16)};
    letter-spacing: 0;
    line-height: ${convertPxToRem(22)};
  }
  li,
  div {
    font-family: ${fontInterRegular}, serif;
    font-size: ${convertPxToRem(14)};
    letter-spacing: 0;
    line-height: ${convertPxToRem(22)};
  }

  strong {
    font-size: ${convertPxToRem(14)};
    font-family: ${fontInterRegular}, serif;
    font-weight: 700;
    color: ${secondaryGrey};
  }

  ul {
    padding-left: ${convertPxToRem(30)};
    list-style: disc;
  }
  ul ul {
    list-style: circle;
  }

  ol {
    padding-left: ${convertPxToRem(30)};
    list-style: decimal;
  }

  em {
    font-style: italic;
  }
`
