import React, { memo } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import HelpMessageIcon from 'src/components/SvgIcons/HelpMessageIcon'
import { IconSvgBase } from 'src/theme/baseStyles'
import { primaryLightBlue, primaryWhite } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function ButtonSendMessage32({
  onClick,
  fill = primaryWhite,
  bgColor = primaryLightBlue,
  height = convertPxToRem(16),
  width = convertPxToRem(16),
  dataTest = 'ButtonSendMessage32',
  disabled = false,
}) {
  return (
    <Root
      onClick={onClick}
      bgColor={bgColor}
      data-test={dataTest}
      disabled={disabled}
    >
      <HelpMessageIcon fill={fill} height={height} width={width} />
    </Root>
  )
}

export default memo(ButtonSendMessage32)

const Root = styled.div`
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  ${IconSvgBase};
  width: ${convertPxToRem(32)};
  height: ${convertPxToRem(32)};
  margin-bottom: ${convertPxToRem(2)};
`

ButtonSendMessage32.propTypes = {
  onClick: PropTypes.func.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  bgColor: PropTypes.string,
  dataTest: PropTypes.string,
  disabled: PropTypes.bool,
}
