import { useCallback, useState } from 'react'

import styled from 'styled-components'

import {
  ContentStepType,
  Steps,
  StepData,
  StepRxContent,
} from 'src/features/Steps'
import { StepLinkContent } from 'src/features/Steps/ui/content/StepLinkContent'
import { InfoMarkIcon } from 'src/shared/ui/icons/misc/InfoMarkIcon'
import {
  primaryBlue,
  secondaryBackgroundGrey,
  secondaryGrey,
} from 'src/theme/colors'
import { capsHeading1_12 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { RxCardModal } from './RxCardModal'

interface PharmacyStepsProps {
  steps: StepData[]
  title: string
}

export function PharmacySteps({ steps, title }: PharmacyStepsProps) {
  const [isVisible, setIsVisible] = useState(false)

  const handlerCard = useCallback(() => {
    setIsVisible((willVisible) => !willVisible)
  }, [setIsVisible])

  return (
    <Root>
      <Title>
        <StyledIcon />
        {title}
      </Title>
      <Steps
        steps={steps}
        theme="light"
        content={{
          [ContentStepType.RxCard]: (
            <StyledStepRxContent onClick={handlerCard} />
          ),
          [ContentStepType.Link]: <StyledStepLinkContent />,
        }}
      />
      {isVisible && <RxCardModal handlerCard={handlerCard} />}
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${convertPxToRem(16, 16, 0)};
  gap: ${convertPxToRem(16)};

  background-color: ${secondaryBackgroundGrey};
  border-radius: ${convertPxToRem(8)};
`

const Title = styled.div`
  display: flex;
  align-items: center;

  ${capsHeading1_12};
  color: ${secondaryGrey};
  text-transform: uppercase;
`

const StyledIcon = styled(InfoMarkIcon)`
  margin-right: ${convertPxToRem(8)};
`

const StyledStepRxContent = styled(StepRxContent)`
  color: ${primaryBlue};
`

const StyledStepLinkContent = styled(StepLinkContent)`
  color: ${primaryBlue};
`
