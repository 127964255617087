import React from 'react'

import {
  Redirect,
  RouteComponentProps,
  useLocation,
  useNavigate,
  WindowLocation,
} from '@reach/router'
import { useGate, useUnit } from 'effector-react'
import styled from 'styled-components'

import ButtonBottom from 'src/components/Buttons/ButtonBottom'
import { ROUTES } from 'src/constants/routesConstants'
import { $hasDrugInfoError } from 'src/entities/drugInfo'
import { drugInfoQuery } from 'src/entities/drugInfo/model'
import { $hasPreferredPharmacy } from 'src/entities/pharmacy'
import { SpinnerWithTitle } from 'src/features/SpinnerWithTitle'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { primaryDarkNavy } from 'src/theme/colors'
import { heading4_21 } from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'
import { ContentWithLightNavHeader } from 'src/widgets/ContentWithLightNavHeader'

import { DrugSearchAlternativesGate } from '../model'

import { AlternativesList } from './components/AlternativesList'
import { Warning } from './components/Warning'

interface LocationState {
  drugName: string
}

function AlternativePromptComponent() {
  const t = useScopedTranslation('translation.medications.drug_search')
  const navigate = useNavigate()

  const {
    state: { drugName },
  } = useLocation() as WindowLocation<LocationState>

  useGate(DrugSearchAlternativesGate, { drugName })

  const isInfoReady = useUnit(drugInfoQuery.$finished)

  const [hasDrugInfoError, hasPreferredPharmacy] = useUnit([
    $hasDrugInfoError,
    $hasPreferredPharmacy,
  ])

  const i18n = {
    buttonTitle: t('buttons.no_continue_with', {
      name: drugName,
      interpolation: { escapeValue: false },
    }),
    title: t('labels.alternative_switch_prompt'),
    warning: t('messages.alternative_medication_info'),
    notCovered: t('messages.not_covered_info'),
  }

  const handleContinue = () => {
    const path = hasPreferredPharmacy
      ? ROUTES.DRUG_SEARCH_CONFIGURE
      : ROUTES.DRUG_SEARCH_INTRO

    void navigate(path, {
      state: {
        drugName,
        isPreconfigured: false,
      },
    })
  }

  if (hasDrugInfoError) {
    return (
      <Redirect
        to={ROUTES.DRUG_SEARCH_ERROR_PATH}
        state={{ drugName }}
        noThrow
      />
    )
  }

  if (!isInfoReady) {
    return <SpinnerWithTitle text={t('status.searching')} />
  }

  return (
    <ContentWithLightNavHeader>
      <Title>{i18n.title}</Title>
      <Warning />
      <AlternativesList />
      <ContinueButton
        handleClick={handleContinue}
        theme="primary"
        title={i18n.buttonTitle}
      />
    </ContentWithLightNavHeader>
  )
}

const Title = styled.span`
  display: flex;
  width: 100%;
  color: ${primaryDarkNavy};
  ${heading4_21};
  margin-bottom: ${convertPxToRem(24)};
`

const ContinueButton = styled(ButtonBottom)`
  margin-top: ${convertPxToRem(54)};

  ${mediaQueryFor.mobile} {
    margin-top: auto;
  }
`

export const AlternativePrompt =
  AlternativePromptComponent as React.FC<RouteComponentProps>
