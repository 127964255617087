import { useCallback } from 'react'

import { variant } from '@effector/reflect'
import Helmet from 'react-helmet'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import ButtonBottom from 'src/components/Buttons/ButtonBottom'
import HeaderNavLightTheme from 'src/components/HeaderNavs/HeaderNavLightTheme'
import Calendar from 'src/components/SvgIcons/Misc/Calendar'
import ClockHistory from 'src/components/SvgIcons/Misc/ClockHistory'
import { DrugPrice, $currentDrugConfig } from 'src/entities/drugDetails'
import {
  chatCreated,
  createChatFx,
} from 'src/features/drugSearchTicketCreation'
import { MedicationCostWithTooltip } from 'src/features/MedicationCostWithTooltip'
import InfoTitle from 'src/sections/SectionMedications/MedicationDetails/Pricing/InfoTitle'
import { ChipTag } from 'src/shared/ui/labels/ChipTag'
import { featurePaleRed, primaryDarkNavy, primaryWhite } from 'src/theme/colors'
import { heading3_25 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { $currentPharmacy } from '../model'

import { PharmacySteps } from './PharmacySteps'

interface PharmacyInfoProps {
  daysSupply: string
  data: DrugPrice
  isLoading: boolean
  onChat: (param: { price: string; pharmacyName: string }) => void
}

function PharmacyInfoComponent({
  daysSupply,
  data,
  isLoading,
  onChat,
}: PharmacyInfoProps) {
  const { t } = useTranslation()
  const {
    patientPay,
    insurancePay,
    isRejected,
    pharmacy: { name, deliveryTime, pharmacySteps },
  } = data

  const openChat = useCallback(() => {
    onChat({ price: patientPay, pharmacyName: name })
  }, [onChat, patientPay, name])

  const i18n = {
    daysSupply: t(
      'translation.medications.pricing_tab.card_options.days_supply_extended',
    ),
    deliveryTime: t(
      'translation.medications.pricing_tab.card_options.delivery_time_extended',
    ),
    steps: t('translation.medications.drug_search.labels.what_you_need'),
    button: t(
      'translation.medications.drug_search.buttons.chat_with_a_pharmacy_guide',
    ),
    days: t('translation.shared.others.day', {
      count: Number(daysSupply),
    }),
    tooltipNote: t(
      'translation.medications.drug_search.messages.prices_modal_note',
    ),
    noPrice: t('translation.medications.drug_search.labels.no_price'),
  }

  return (
    <Root data-test="pharmacyInfo.root">
      <Helmet>
        <title>{name}</title>
      </Helmet>

      <Info>
        <HeaderNavLightTheme withMiddleButton={false} />

        <PharmacyName>{name}</PharmacyName>

        {isRejected && <NoPriceLabel>{i18n.noPrice}</NoPriceLabel>}

        {!isRejected && (
          <MedicationCostWithTooltip
            cost={patientPay}
            insurancePay={insurancePay}
            additionalInfo={i18n.tooltipNote}
          />
        )}

        <InfoTitle
          icon={<ClockHistory />}
          textKey={i18n.daysSupply}
          value={i18n.days}
          type="daysSupply"
        />
        <InfoTitle
          icon={<Calendar />}
          textKey={i18n.deliveryTime}
          value={deliveryTime}
          type="time"
        />

        <PharmacySteps title={i18n.steps} steps={pharmacySteps} />
      </Info>
      <ButtonContainer>
        <ButtonBottom
          dataTestProp="pharmacyContent.chatButton"
          theme="primary"
          title={i18n.button}
          handleClick={openChat}
          loading={isLoading}
          disabled={isLoading}
        />
      </ButtonContainer>
    </Root>
  )
}

const Root = styled.div`
  position: relative;

  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  height: 100%;
  gap: ${convertPxToRem(12)};

  background-color: ${primaryWhite};

  border-radius: ${convertPxToRem(20)};
  box-shadow: ${convertPxToRem(0, -6, 14, 0)} rgba(0, 0, 0, 0.08);
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;

  padding: ${convertPxToRem(24)};
  gap: ${convertPxToRem(16)};
`

const ButtonContainer = styled.div`
  padding: ${convertPxToRem(0, 24, 24)};
`

const PharmacyName = styled.span`
  color: ${primaryDarkNavy};
  ${heading3_25};
`

const NoPriceLabel = styled(ChipTag)`
  background-color: ${featurePaleRed};
  color: ${primaryDarkNavy};
  text-transform: uppercase;
`

export const PharmacyInfo = variant({
  if: $currentPharmacy.map((data) => Boolean(data)),
  then: PharmacyInfoComponent,
  bind: {
    data: $currentPharmacy.map((data) => data!),
    daysSupply: $currentDrugConfig.map(({ config }) => config.daysSupplyCustom),
    onChat: chatCreated,
    isLoading: createChatFx.pending,
  },
})
