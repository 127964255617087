import { gql } from '@apollo/client'

const CHAT_READ_MUTATION = gql`
  mutation readChatMutation($id: ID!) {
    readChat(id: $id) {
      id
    }
  }
`

export default CHAT_READ_MUTATION
