import { createEvent, createStore, sample } from 'effector'
import { debounce } from 'patronum'

export const searchChanged = createEvent<string>()

export const chatSelected = createEvent<string>()
export const chatCreated = createEvent<void>()

export const $search = createStore<string>('')
export const $query = createStore<string>('')

sample({
  source: searchChanged,
  target: $search,
})

debounce({
  source: $search,
  timeout: 300,
  target: $query,
})
