import { primaryDarkNavy } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { IconProps } from './types'

function PdfIcon({
  width = convertPxToRem(33),
  height = convertPxToRem(33),
  fill = primaryDarkNavy,
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 33 33"
    >
      <path
        fill={fill}
        fillRule="evenodd"
        d="M31.106 30.362c0 .693-.567 1.258-1.264 1.258h-18.89c-.698 0-1.265-.565-1.265-1.26v-5.67h10.71c1.042 0 1.89-.847 1.89-1.889v-9.453c0-1.041-.848-1.888-1.89-1.888H9.688V2.644c0-.697.567-1.264 1.264-1.264h11.335v8.19c0 .348.283.63.63.63h8.19v20.162zM3.384 23.43c-.347 0-.629-.282-.629-.629v-9.453c0-.346.282-.628.63-.628h17.011c.347 0 .629.282.629.628v9.453c0 .347-.282.629-.629.629H3.384zM23.544 1.82l6.851 7.12h-6.85V1.82zm8.645 7.165L23.843.313C23.724.19 23.561.12 23.39.12H10.95C9.56.12 8.426 1.253 8.426 2.644v8.816H3.385c-1.042 0-1.889.847-1.889 1.888v9.453c0 1.042.847 1.889 1.889 1.889h5.04v5.67c0 1.39 1.134 2.52 2.525 2.52h18.89c1.393 0 2.525-1.13 2.525-2.518V9.422c0-.163-.063-.32-.176-.437z"
        clipRule="evenodd"
      />
      <mask
        id="bhy6k6jb3a"
        width="32"
        height="33"
        x="1"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M1.375 0h31.11v33H1.375V0z"
          clipRule="evenodd"
        />
      </mask>
      <g mask="url(#bhy6k6jb3a)">
        <path
          fill={fill}
          fillRule="evenodd"
          d="M23.665 8.82h6.448l-6.448-6.7v6.7zm7.013.24h-7.255V1.524l7.255 7.538zM3.385 12.84c-.28 0-.509.228-.509.508v9.453c0 .28.228.508.509.508h17.011c.28 0 .509-.228.509-.508v-9.453c0-.28-.228-.508-.509-.508H3.386zm17.01 10.71H3.385c-.413 0-.75-.336-.75-.749v-9.453c0-.413.337-.748.75-.748h17.012c.413 0 .749.335.749.748v9.453c0 .413-.336.749-.75.749zm-10.59 1.26v5.55c0 .628.513 1.14 1.144 1.14h18.89c.631 0 1.145-.511 1.145-1.138V10.32h-8.07c-.414 0-.75-.337-.75-.751V1.5H10.949c-.63 0-1.144.514-1.144 1.144v8.696h10.59c1.108 0 2.01.9 2.01 2.008v9.453c0 1.108-.902 2.009-2.01 2.009H9.806zm20.036 6.93h-18.89c-.764 0-1.385-.619-1.385-1.38v-5.79h10.832c.974 0 1.768-.794 1.768-1.769v-9.453c0-.975-.794-1.768-1.768-1.768H9.566V2.645c0-.763.621-1.384 1.385-1.384h11.456v8.31c0 .28.228.51.51.51h8.31v20.282c0 .76-.622 1.378-1.386 1.378zM3.385 11.58c-.975 0-1.768.794-1.768 1.768v9.453c0 .975.793 1.768 1.768 1.768h5.161v5.791c0 1.323 1.079 2.4 2.405 2.4h18.89c1.325 0 2.404-1.076 2.404-2.398V9.422c0-.132-.05-.257-.142-.353L23.757.397c-.096-.1-.23-.156-.367-.156H10.95c-1.325 0-2.404 1.078-2.404 2.403v8.937h-5.16zM29.84 33H10.95c-1.459 0-2.645-1.184-2.645-2.64v-5.55h-4.92c-1.109 0-2.01-.901-2.01-2.009v-9.453c0-1.107.901-2.008 2.01-2.008h4.92V2.644C8.305 1.186 9.49 0 10.95 0h12.439c.203 0 .4.084.54.23l8.346 8.672c.136.14.21.325.21.52v20.94c0 1.454-1.187 2.638-2.645 2.638z"
          clipRule="evenodd"
        />
      </g>
      <path
        fill={fill}
        fillRule="evenodd"
        d="M8.093 17.599c-.083.12-.196.209-.342.265-.145.057-.433.085-.864.085H6.29v-1.535h.527c.394 0 .656.012.787.037.177.032.323.112.439.24.116.128.174.29.174.487 0 .16-.042.3-.124.42m.157-2.018c-.202-.055-.635-.082-1.3-.082H5.198v5.412H6.29v-2.041h.712c.495 0 .873-.026 1.134-.078.192-.042.38-.127.566-.257.186-.129.34-.307.46-.533.12-.226.18-.506.18-.838 0-.43-.104-.782-.313-1.054-.21-.272-.469-.448-.78-.53"
        clipRule="evenodd"
      />
      <path
        fill={fill}
        fillRule="evenodd"
        d="M6.41 17.829h.478c.515 0 .73-.042.82-.077.123-.048.217-.12.286-.221.069-.101.103-.217.103-.353 0-.168-.047-.3-.143-.407-.098-.108-.22-.174-.371-.202-.123-.023-.38-.035-.765-.035H6.41v1.295zm.477.24H6.17v-1.776h.648c.406 0 .67.013.809.04.202.036.372.13.506.277.136.15.205.341.205.568 0 .184-.05.348-.145.489-.096.14-.23.244-.397.31-.161.062-.458.093-.908.093zm-1.57 2.72h.852v-2.041h.833c.485 0 .859-.025 1.11-.075.173-.038.35-.118.521-.237.17-.118.311-.283.422-.491.11-.208.167-.471.167-.782 0-.401-.097-.731-.289-.98-.191-.25-.432-.413-.714-.487-.19-.051-.617-.077-1.269-.077H5.317v5.17zm1.093.241H5.076v-5.652H6.95c.682 0 1.117.028 1.33.085.335.088.619.28.844.573.224.292.338.671.338 1.127 0 .35-.065.652-.195.895-.129.242-.296.436-.497.575-.197.138-.403.23-.61.276-.272.054-.65.08-1.158.08H6.41v2.041zM13.531 19.199c-.073.242-.169.416-.286.522-.117.106-.263.181-.44.225-.136.035-.356.052-.661.052h-.816v-3.584h.49c.446 0 .745.017.898.052.204.044.372.128.506.254.132.126.236.3.31.524.073.224.11.545.11.964 0 .418-.037.748-.11.99m.545-3.098c-.226-.239-.498-.405-.816-.498-.236-.07-.58-.104-1.03-.104h-1.997v5.412h2.056c.404 0 .726-.038.968-.115.322-.103.578-.247.767-.432.251-.243.444-.562.58-.956.11-.322.166-.706.166-1.151 0-.507-.06-.933-.177-1.28-.118-.345-.29-.637-.517-.876"
        clipRule="evenodd"
      />
      <path
        fill={fill}
        fillRule="evenodd"
        d="M11.448 19.878h.696c.294 0 .506-.017.631-.048.157-.04.288-.106.39-.198.1-.09.185-.248.252-.468.07-.23.105-.552.105-.956 0-.403-.035-.715-.104-.926-.067-.203-.161-.363-.279-.474-.116-.11-.267-.186-.448-.225-.144-.032-.437-.049-.872-.049h-.37v3.344zm.695.24h-.936v-3.825h.611c.46 0 .762.018.924.055.223.048.413.144.562.285.147.139.262.332.341.574.078.235.117.572.117 1 0 .428-.039.774-.116 1.027-.08.266-.186.455-.32.577-.132.119-.298.204-.493.252-.146.037-.372.056-.69.056zm-1.788.671h1.936c.39 0 .703-.036.93-.109.303-.096.546-.232.72-.403.237-.229.422-.535.55-.909.107-.308.16-.682.16-1.112 0-.491-.057-.909-.17-1.24-.112-.328-.277-.608-.49-.833-.211-.222-.468-.379-.763-.466-.224-.065-.559-.098-.996-.098h-1.877v5.17zm1.935.241h-2.177v-5.652h2.118c.466 0 .814.035 1.063.108.337.1.63.278.87.531.237.25.42.56.543.92.122.358.184.801.184 1.319 0 .457-.059.857-.173 1.19-.141.41-.346.748-.61 1.004-.201.196-.475.351-.814.46-.252.08-.59.12-1.004.12zM15.705 20.91h1.093v-2.3h2.259v-.915h-2.26v-1.281h2.618v-.916h-3.71v5.412z"
        clipRule="evenodd"
      />
      <path
        fill={fill}
        fillRule="evenodd"
        d="M15.826 20.79h.852v-2.3h2.259v-.675h-2.26v-1.522h2.618v-.674h-3.47v5.17zm1.092.24h-1.334v-5.652h3.95v1.156h-2.616v1.04h2.259v1.156h-2.26v2.3z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default PdfIcon
