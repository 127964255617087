import React from 'react'

import styled from 'styled-components'

import { AppointmentStatus } from 'src/shared/api/types'
import { useScopedTranslation } from 'src/shared/hooks/useScopedTranslation'
import { ChipTag } from 'src/shared/ui/labels/ChipTag'
import { featurePaleBlue } from 'src/theme/colors'
import { text1_16 } from 'src/theme/fonts'

interface AppointmentHeaderDescriptionProps {
  status: AppointmentStatus
  statusName: string
}

export function AppointmentHeaderDescription({
  status,
  statusName,
}: AppointmentHeaderDescriptionProps) {
  const t = useScopedTranslation('translation.appointments.title')

  switch (status) {
    case AppointmentStatus.Suggested:
      return <DescriptionWrapper>{t('confirm_description')}</DescriptionWrapper>

    default:
      return <StatusLabel>{statusName}</StatusLabel>
  }
}

const DescriptionWrapper = styled.span`
  ${text1_16};

  grid-row: 2 / 3;
  grid-column: 1 / 3;
`

const StatusLabel = styled(ChipTag)`
  text-transform: uppercase;
  background-color: ${featurePaleBlue};

  grid-row: 2 / 3;
  grid-column: 1 / 3;
`
