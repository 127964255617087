import { BILL_CLAIM_STATUS } from 'src/sections/SectionBillingAdvocacy/billAdvocacyHelpers'
import {
  secondaryLightGrey,
  featurePaleBlue,
  featurePaleYellow,
  featurePaleGreen,
  featurePaleRed,
} from 'src/theme/colors'

const StatusMap = {
  // __typename -> FEED_ITEM_FRAGMENT selection
  CarePlan: 'carePlanStatus',
  BillClaim: 'billClaimStatus',
  Appointment: 'appointmentStatus',
}

export const extractActivityFeedItem = (item) => ({
  id: item?.id || '',
  category: item?.category || '',
  createdAt: item?.created_at || '',
  updatedAt: item?.updated_at || '',
  cardType: item?.card_type || '', // Need to remove when backend will supply resource status "rejected"
  title: item?.title || '',
  description: item?.description || '',
  label: item?.label || '',
  callToAction: item?.call_to_action || '',
  resourceId: item?.resource?.id || '',
  // FIXME: https://github.com/graphql/graphql-js/issues/53
  resourceStatus:
    (item?.resource && item.resource[StatusMap[item.resource.__typename]]) ||
    '',
  resourceImageUrl: item?.resource?.image_url || '',
  imageUrl: item?.image_url || '',
  isResourceArchived: item?.resource?.archived || false,
  url: item?.url || '',
})

// TODO will transfer in the global folder for constants
export const CATEGORY = {
  BILLING_ADVOCACY: 'billing_advocacy',
  CARE_PLAN: 'care_plan',
  CHAT: 'chat',
  APPOINTMENT: 'appointment',
  MEDICATION: 'medication',
  CARE_PROGRAM: 'care_program',
  MAIL_ORDER: 'mail_order_transfer',
  WELLNESS: 'wellness',
  HEALTH_PROFILE: 'health_profile',
  ITERABLE: 'iterable',
}

export const BG_COLORS = {
  [CATEGORY.HEALTH_PROFILE]: featurePaleRed,
  [CATEGORY.BILLING_ADVOCACY]: featurePaleGreen,
  [CATEGORY.CARE_PLAN]: featurePaleBlue,
  [CATEGORY.APPOINTMENT]: featurePaleBlue,
  [CATEGORY.MEDICATION]: featurePaleYellow,
  [CATEGORY.CARE_PROGRAM]: featurePaleYellow,
  [CATEGORY.MAIL_ORDER]: featurePaleYellow,
  [CATEGORY.WELLNESS]: featurePaleRed,
  [CATEGORY.ITERABLE]: featurePaleBlue,
}
const LABEL_COLORS = {
  [CATEGORY.HEALTH_PROFILE]: featurePaleRed,
  [CATEGORY.BILLING_ADVOCACY]: {
    [BILL_CLAIM_STATUS.RESOLVED]: secondaryLightGrey,
    [BILL_CLAIM_STATUS.IN_PROGRESS]: featurePaleGreen,
    [BILL_CLAIM_STATUS.PENDING]: featurePaleGreen,
  },
  [CATEGORY.CARE_PLAN]: {
    archived: secondaryLightGrey,
    unArchived: featurePaleBlue,
  },
  [CATEGORY.APPOINTMENT]: featurePaleBlue,
  [CATEGORY.MEDICATION]: featurePaleYellow,
  [CATEGORY.CARE_PROGRAM]: featurePaleYellow,
  [CATEGORY.MAIL_ORDER]: featurePaleYellow,
  [CATEGORY.WELLNESS]: featurePaleRed,
  [CATEGORY.ITERABLE]: featurePaleBlue,
}

export const getLabelColors = ({
  category,
  resourceStatus,
  isResourceArchived,
}) => {
  switch (category) {
    case CATEGORY.HEALTH_PROFILE:
      return LABEL_COLORS[category]
    case CATEGORY.BILLING_ADVOCACY:
      return LABEL_COLORS[category][resourceStatus]
    case CATEGORY.CARE_PLAN:
      return isResourceArchived
        ? LABEL_COLORS[category].archived
        : LABEL_COLORS[category].unArchived
    default:
      return LABEL_COLORS[category]
  }
}

export const openActivityCard =
  ({ url, setErrorMessage, navigate }) =>
  () => {
    try {
      const { pathname, search } = new URL(url)
      // navigate instead of 'www.rightway.com/home/section?var=example' to '/home/section?var=example'
      navigate(`${pathname}${search}`)
    } catch (err) {
      // FIXME: URL throwing error in JSDom does not pass this check
      // see https://github.com/jsdom/jsdom/issues/2727

      // Stryker disable all
      /* istanbul ignore if */
      if (err instanceof Error) {
        setErrorMessage(err.message)
      }
      // Stryker restore all
    }
  }

export const openIterableCard = ({ url, setErrorMessage, navigate }) => {
  try {
    const currentURL = new URL(window.location)
    const targetURL = new URL(url, currentURL.origin)

    const isExternal = targetURL.origin !== currentURL.origin

    if (isExternal) {
      window.open(targetURL, '_blank', 'noopener')
      return
    }

    const { pathname, search } = targetURL
    navigate(`${pathname}${search}`)
  } catch (err) {
    // FIXME: URL throwing error in JSDom does not pass this check
    // see https://github.com/jsdom/jsdom/issues/2727

    // Stryker disable all
    /* istanbul ignore if */
    if (err instanceof Error) {
      setErrorMessage(err.message)
    }
    // Stryker restore all
  }
}
