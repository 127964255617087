import styled from 'styled-components'

import { Sprite } from 'src/shared/ui/Sprite'
import { formatPhone, PHONE_FORMATS } from 'src/utils/phoneHelpers'

import { ActionTitle } from './ActionTitle'
import { IconWrapper } from './IconWrapper'

interface CallButtonProps {
  phoneNumber?: string
  title?: string
  icon?: 'misc/call' | 'misc/phone_rotary'
}

export function CallButton({
  phoneNumber = '',
  title = '',
  icon = 'misc/call',
}: CallButtonProps) {
  const makeCall = () => {
    const link = formatPhone(phoneNumber, PHONE_FORMATS.uri)
    if (link) window.open(link, '_self')
  }

  if (!phoneNumber) return null

  return (
    <ButtonWrapper>
      <IconWrapper onClick={makeCall} data-test="actions.callButton">
        <Sprite name={icon} />
      </IconWrapper>
      <ActionTitle>{title}</ActionTitle>
    </ButtonWrapper>
  )
}

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
