import React, { ReactElement, useCallback, useMemo, useState } from 'react'

import { GoogleMap } from '@react-google-maps/api'

import { GlobalMapStyle } from 'src/components/Map/mapStyles'
import { LocationCoordinates } from 'src/utils/locationHelpers'

import { DefaultMapOptions, getMarkerBounds } from '../lib'

export interface LargeMapBaseMarker extends LocationCoordinates {
  id: string
}

export interface LargeMapProvidedProps<Marker> {
  marker: Marker
  map: google.maps.Map
}

type LargeMapPropHelper<
  T extends LargeMapProvidedProps<unknown>,
  Provided extends
    keyof LargeMapProvidedProps<unknown> = keyof LargeMapProvidedProps<unknown>,
> = Omit<T, Provided> & Partial<Pick<T, Provided>>

export type LargeMapMarkerComponent<
  Props extends LargeMapProvidedProps<LargeMapBaseMarker>,
> = React.FC<LargeMapPropHelper<Props>>

export interface LargeMapHookParams {
  map: google.maps.Map
  bounds: google.maps.LatLngBounds
  center: google.maps.LatLng
}

interface LargeMapProps<Marker extends LargeMapBaseMarker> {
  markers: Marker[]

  draggable?: 'cooperative' | 'greedy' | 'none'
  className?: string

  onLoaded?: (params: LargeMapHookParams) => void

  element: ReactElement<LargeMapProvidedProps<Marker>>
}

export function LargeMap<M extends LargeMapBaseMarker>({
  markers,

  draggable = 'cooperative',
  className = undefined,
  onLoaded = () => {},
  element,
}: LargeMapProps<M>) {
  const [map, setMap] = useState<google.maps.Map>()

  const bounds = useMemo(() => getMarkerBounds(markers), [markers])
  const center = useMemo(() => bounds.getCenter(), [bounds])

  const options = useMemo(
    () => ({ ...DefaultMapOptions, gestureHandling: draggable }),
    [draggable],
  )

  const markerElements = useMemo(
    () =>
      markers.map((marker) =>
        React.cloneElement(element, {
          marker,
          key: marker.id,
          map,
        }),
      ),
    [element, markers, map],
  )

  const onMapLoaded = useCallback(
    (value: google.maps.Map) => {
      setMap(value)

      onLoaded({ map: value, bounds, center })
    },
    [bounds, center, onLoaded],
  )

  return (
    <>
      <GlobalMapStyle />
      <GoogleMap
        mapContainerClassName={className}
        onLoad={onMapLoaded}
        options={options}
        zoom={16}
      >
        {markerElements}
      </GoogleMap>
    </>
  )
}
