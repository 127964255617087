import moment from 'moment'
import { isEmpty, pathOr } from 'ramda'

import i18next from 'src/translations/i18n'
import { findPhoneNumbersInText } from 'src/utils/phoneHelpers'

export const ATTACHMENT_TYPE = {
  FILE_LINK: 'ChatMessageFileType',
  SYSTEM_MESSAGE: 'SystemNotificationType',
}
export const ATTACHMENT_IMAGE_TYPE = 'image/'
export const extractAttachment = (item) => {
  const typeName = pathOr('', ['__typename'], item)
  const isFileLink = typeName === ATTACHMENT_TYPE.FILE_LINK
  const isSystemMessage = typeName === ATTACHMENT_TYPE.SYSTEM_MESSAGE

  const attachedFileUrl = pathOr('', ['short_url'], item)
  const contentType = pathOr('', ['content_type'], item)
  const systemMessage = pathOr('', ['message'], item)
  const isPdf = contentType.toLowerCase().includes('pdf')
  const scanStatus = item?.scan_status ?? ''

  return {
    isFileLink,
    isSystemMessage,
    systemMessage,
    linkData: isFileLink
      ? {
          // file config
          isPdf,
          attachedFileUrl,
          scanStatus,
        }
      : {
          // deep link config
          title: pathOr('', ['title'], item),
          subTitle: pathOr('', ['sub_title'], item),
          resourceImageUrl: pathOr('', ['icon_url'], item),
          callToAction: pathOr('', ['url_name'], item),
          link: pathOr('', ['url'], item),
        },
  }
}

const getDisplayDayParams = (index, createdAt, lastItem) => {
  if (index > 0 && index < lastItem) return {}
  const today = moment()
  const dateToTest = moment(createdAt)
  const dateDiff = today.diff(dateToTest, 'days')
  const isToday = dateDiff === 0
  const day = isToday ? 'Today ' : getMessagesDay(createdAt)
  const dayTime = getMessageTime(createdAt)

  return { day, dayTime }
}

export const extractChatMessagesList = (data) =>
  data.map((item, index) => {
    const attachment = pathOr({}, ['attachment'], item)
    const messageBody = pathOr('', ['body'], item)
    const createdAt = pathOr('', ['created_at'], item)
    const lastItem = data.length - 1
    const lastMessageId = data[lastItem]?.id
    const firstMessageId = data[0]?.id
    const hasAttachment = !isEmpty(attachment)

    return {
      attachment: hasAttachment ? extractAttachment(attachment) : {},
      hasAttachment,
      hasMessageBody: !!messageBody,
      messageBody: pathOr('', ['body'], item),
      isLastMessage: lastMessageId === item.id,
      isFirstMessage: firstMessageId === item.id,
      isSelfSent: pathOr(false, ['is_self_sent'], item),
      itemId: pathOr('', ['id'], item),
      createdAt,
      updatedAt: pathOr('', ['updated_at'], item),
      receivedAt: pathOr('', ['receiver_read_at'], item),
      displayDay: getDisplayDayParams(index, createdAt, lastItem),
    }
  })

export const extractChatData = (data) => {
  const chatData = pathOr({}, ['chat'], data)
  const chatMessagesList = pathOr([], ['messages', 'nodes'], chatData)

  return {
    chatMessagesList: extractChatMessagesList(chatMessagesList),
    subject: pathOr('', ['chat', 'subject'], data),
    isResolved: pathOr(true, ['is_resolved'], chatData),
    isInactive: pathOr(false, ['is_inactive'], chatData),
    hasNextPage: pathOr(
      false,
      ['messages', 'pageInfo', 'hasNextPage'],
      chatData,
    ),
    endCursor: pathOr('', ['messages', 'pageInfo', 'endCursor'], chatData),
  }
}

export const getChatPageInfo = (data) => ({
  totalPages: pathOr('', ['chat', 'messages', 'pageInfo', 'totalPages'], data),
  currentPage: pathOr(
    '',
    ['chat', 'messages', 'pageInfo', 'currentPage'],
    data,
  ),
  hasNextPage: pathOr(
    '',
    ['chat', 'messages', 'pageInfo', 'hasNextPage'],
    data,
  ),
  hasPreviousPage: pathOr(
    '',
    ['chat', 'messages', 'pageInfo', 'hasPreviousPage'],
    data,
  ),
})

const getLastMessageTime = (_time) => {
  const today = moment()
  const dateDiff = today.diff(_time, 'seconds')
  if (dateDiff < 60) {
    return 'Just now'
  }
  return moment(_time).calendar(null, {
    sameDay: 'h:mma',
    lastDay: '[Yesterday]',
    lastWeek: 'dddd',
    sameElse: 'M/D/YY',
  })
}
const extractInboxAttachment = (data) => ({
  contentType: pathOr('', ['content_type'], data),
  url: pathOr('', ['url'], data),
  size: pathOr(0, ['size'], data),
})
export const extractInboxItem = (data) => {
  const inboxData = pathOr([], ['chats', 'nodes'], data)

  return inboxData
    .filter((item) => item.last_message !== null)
    .map((item) => {
      const lastMessage = pathOr({}, ['last_message'], item)
      const messageUpdated = pathOr('', ['updated_at'], lastMessage)
      const typeName = pathOr(
        '',
        ['last_message', 'attachment', '__typename'],
        item,
      )
      const isSystemMessage = typeName === ATTACHMENT_TYPE.SYSTEM_MESSAGE
      const messageBody = isSystemMessage
        ? pathOr('', ['attachment', 'message'], lastMessage)
        : pathOr('', ['body'], lastMessage)

      const attachment = pathOr({}, ['attachment'], lastMessage)

      return {
        chatId: pathOr('', ['id'], item),
        subject: pathOr('', ['subject'], item),
        createdAt: pathOr('', ['created_at'], item),
        isResolved: pathOr(false, ['is_resolved'], item),
        lastMessage: {
          messageBody,
          attachment: extractInboxAttachment(attachment),
          lastTimeUpdated: getLastMessageTime(messageUpdated),
          timestamp: moment(messageUpdated).format('x'),
        },
        chatCreatedAt: pathOr('', ['created_at'], item),
        chatUpdatedAt: pathOr('', ['updated_at'], item),
      }
    })
    .sort((a, b) => b.lastMessage.timestamp - a.lastMessage.timestamp)
}

export const extractInboxData = (data) => {
  const inboxData = extractInboxItem(data)
  const pageInfo = pathOr({}, ['chats', 'pageInfo'], data)
  return {
    inboxData,
    pageInfo,
  }
}

export const extractNavigatorActivities = (data) => {
  const navigatorActivities = pathOr(
    {},
    ['currentUser', 'activities', 'chat'],
    data,
  )
  return {
    newMessagesCount: pathOr('', ['count'], navigatorActivities),
    newMessagesIdsList: pathOr([], ['ids'], navigatorActivities),
    isNavigatorOnline: pathOr(
      false,
      ['currentUser', 'navigator_work_schedule', 'online'],
      data,
    ),
  }
}

export const checkIsHasMessages = (newMessage, data) =>
  newMessage && pathOr(false, ['chat', 'messages', 'nodes'], data)

export const STEP = {
  INBOX: 'INBOX',
  CHAT_NEW: 'CHAT_NEW',
  CHAT_THREAD: 'CHAT_THREAD',
}

export const getWithDayDateParams = (_date) => {
  const transformDate = moment(_date).calendar()
  const separateDayFromTime = transformDate.split(' at')

  return {
    day: separateDayFromTime[0],
    time: separateDayFromTime[1],
  }
}

export const getMessageTime = (_date) => moment(_date).format('h:mm A')

export const getMessagesDay = (_date) => moment(_date).format('ddd, MMM DD')

export const TEXT_AREA_LINE_HEIGHT = 22

// Memoize this! Expensive computations
export const searchPhoneNumber = (message = '') => {
  const phones = findPhoneNumbersInText(message)

  const [lastMarker, result] = phones.reduce(
    ([prev, arr], phone) => [
      phone.endsAt,
      [
        ...arr,
        phone.startsAt > prev && {
          kind: 'text',
          display: message.substring(prev, phone.startsAt),
          link: null,
        },
        {
          kind: 'phone',
          display: message.substring(phone.startsAt, phone.endsAt),
          link: phone.number.getURI(),
        },
      ],
    ],
    [0, []],
  )

  return [
    ...result,
    lastMarker < message.length && {
      kind: 'text',
      display: message.substring(lastMarker),
      link: null,
    },
  ].filter(Boolean)
}

const MIN_ROWS = 1
const MAX_ROWS = 5

export const handleTextAreaRows = (target) => {
  /* eslint-disable no-param-reassign */
  if (!target.scrollHeight) return

  target.rows = MIN_ROWS // reset number of rows in textarea
  const targetRows = Math.floor(target.scrollHeight / TEXT_AREA_LINE_HEIGHT)

  target.rows = Math.min(targetRows, MAX_ROWS)
  target.scrollTop = target.scrollHeight
}
