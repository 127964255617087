import { useQuery } from '@apollo/client'

import { INSURANCE_INFO_QUERY } from './document'

export function useInsuranceQuery() {
  const { data, loading: isLoading, error } = useQuery(INSURANCE_INFO_QUERY)

  return {
    insuranceInfo: data?.currentUser.insuranceInfo ?? null,
    isLoading,
    error,
  }
}
