import { useCallback, useContext, UIEvent } from 'react'

import { useGate, useUnit } from 'effector-react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import useDismissCustomFeedItemMutation from 'src/apollo/hooks/useDismissCustomFeedItemMutation'
import useDismissFeedItemMutation from 'src/apollo/hooks/useDismissFeedItemMutation'
import FeatureGuard from 'src/components/FeatureGuard'
import Spinner from 'src/components/Spinner'
import { FeatureFlag } from 'src/constants/featureFlags'
import { useFeedItemsQuery } from 'src/entities/feedItems'
import { isMobileDevice } from 'src/sections/SectionDashboard/dashboardHelpers'
import ActivityList from 'src/sections/SectionHome/Activity/ActivityList'
import { ActivityDismissGate } from 'src/sections/SectionHome/Activity/model'
import { HeaderAnimationContext } from 'src/sections/SectionHome/HeaderAnimationContext'
import HomeChatBox from 'src/sections/SectionNavigator/HomeChatBox'
import {
  MobileBodyPadding16,
  BodyPadding40,
  ContentsRootBase,
} from 'src/theme/baseStyles'
import { primaryDarkNavy } from 'src/theme/colors'
import {
  Heading,
  heading6_24,
  fontSourceSerifProRegular,
} from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'
import { checkIsEndScrollReached } from 'src/utils/scrollHelpers'

import { $inAppMessages } from './model/iterable/core'
import { ActivityDrugSearchAccessButton } from './widgets/ActivityDrugSearchAccessButton'

interface ActivityFeedProps {
  isSequoiaUser?: boolean
}

function ActivityFeed({ isSequoiaUser = false }: ActivityFeedProps) {
  const { t } = useTranslation()
  const i18n = {
    today: t('translation.home.activity.titles.today'),
    updates: t('translation.home.activity.titles.updates'),
    callToAction: t(
      'translation.profile.wizard.finish_health_profile.continue',
    ),
    description: t('translation.profile.wizard.finish_health_profile.reminder'),
    label: t('translation.profile.wizard.finish_health_profile.incomplete'),
    title: t('translation.profile.wizard.finish_health_profile.complete'),
  }
  const currentDate = moment().format('MMM DD')

  const {
    feedItems,
    hasNextPage,
    customFeedItems,
    totalCount,
    isLoading,
    fetchMoreFeedItems,
    isFetchMoreLoading,
  } = useFeedItemsQuery()

  const { setScrollTop } = useContext(HeaderAnimationContext)

  const { dismissFeedItem } = useDismissFeedItemMutation()
  const { handleDismissCustomFeedItem } = useDismissCustomFeedItemMutation()

  const inAppMessages = useUnit($inAppMessages)

  useGate(ActivityDismissGate, {
    dismissFeedItem,
    handleDismissCustomFeedItem,
  })

  const hasData =
    feedItems.length > 0 ||
    customFeedItems.length > 0 ||
    inAppMessages.length > 0

  const handleScroll = useCallback(
    (event: UIEvent<HTMLElement>) => {
      if (isMobileDevice && !hasData)
        setScrollTop(event.currentTarget.scrollTop)

      const isEndScrollReached = checkIsEndScrollReached(event, 100)
      if (!isEndScrollReached) return

      const shouldFetchMore =
        !isFetchMoreLoading && isEndScrollReached && hasNextPage

      if (shouldFetchMore) {
        void fetchMoreFeedItems()
      }
    },
    [
      hasData,
      isFetchMoreLoading,
      setScrollTop,
      fetchMoreFeedItems,
      hasNextPage,
    ],
  )

  if (isLoading && !hasData) return <Spinner />

  return (
    <Root
      onScroll={handleScroll}
      data-is-sequoia={isSequoiaUser}
      data-test="activityFeed"
    >
      <HomeChatBox />
      <DateContainer>
        <Day>{i18n.today}</Day>
        <Date>{` ${currentDate}`}</Date>
      </DateContainer>

      <FeatureGuard permit={FeatureFlag.PBM_DRUG_SEARCH}>
        <ActivityDrugSearchAccessButton />
      </FeatureGuard>

      <ActivityList
        feedItems={feedItems}
        customFeedItems={customFeedItems}
        inAppMessages={inAppMessages}
        hasTodoNextPage={hasNextPage}
        totalCount={totalCount}
      />

      {isFetchMoreLoading && <Spinner />}
    </Root>
  )
}

const Root = styled.div`
  ${ContentsRootBase};
  ${BodyPadding40};
  ${mediaQueryFor.tabletOrLess} {
    padding-bottom: ${convertPxToRem(140)};
  }
  ${mediaQueryFor.mobile} {
    ${MobileBodyPadding16};
    padding-bottom: ${convertPxToRem(140)};
    &[data-is-sequoia='false'] {
      padding-top: 0;
    }
  }
`

const DateContainer = styled.h1`
  display: flex;
  align-items: center;
  margin-bottom: ${convertPxToRem(16)};
  padding-left: ${convertPxToRem(6)};
`

const Date = styled.div`
  margin-left: ${convertPxToRem(6)};
  color: ${primaryDarkNavy};
  ${heading6_24};
  font-family: ${fontSourceSerifProRegular};
`
const Day = styled.div`
  color: ${primaryDarkNavy};
  ${Heading};
`

export default ActivityFeed
