import { primaryDarkNavy } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { IconProps } from '../types'

export function ClockIcon({
  width = convertPxToRem(20),
  height = convertPxToRem(20),
  fill = primaryDarkNavy,
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10C0 4.477 4.477 0 10 0c5.52.006 9.994 4.48 10 10 0 5.523-4.477 10-10 10S0 15.523 0 10zm13.156 4.402a.847.847 0 0 0 1.177-.051.833.833 0 0 0-.055-1.178l-3.861-3.54V5.417a.833.833 0 0 0-1.667 0V10c0 .234.1.457.273.614l4.133 3.788z"
        fill={fill}
      />
    </svg>
  )
}
