import { useState, useEffect } from 'react'

const useUserMedia = (requestedMedia) => {
  const [mediaStream, setMediaStream] = useState(null)

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    async function enableVideoStream() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia(requestedMedia)
        setMediaStream(stream)
      } catch (err) {
        alert('Camera is not available on your device')
      }
    }

    if (!mediaStream) {
      enableVideoStream()
    } else {
      return function cleanup() {
        mediaStream.getTracks().forEach((track) => {
          track.stop()
        })
      }
    }
  }, [mediaStream, requestedMedia])

  return mediaStream
}

export default useUserMedia
