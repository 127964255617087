import {
  combine,
  createEffect,
  createEvent,
  createStore,
  sample,
} from 'effector'
import { createGate } from 'effector-react'

import client from 'src/apollo/client'
import { Coordinates } from 'src/entities/geolocation'
import { PREFERRED_PHARMACY_MUTATION } from 'src/entities/pharmacy'

import { drugSearchPharmaciesQuery } from '../api/pharmaciesSearchQuery'

export const INITIAL_LIST_LENGTH = 5

type SelectedPharmacyAttributes = {
  nabp: string
  zipCode: string
}

export const PreferredPharmacySearchGate = createGate({
  name: 'PreferredPharmacySearchGate',
})

export const pharmaciesListExpanded = createEvent()
export const preferredPharmacyChosen = createEvent<SelectedPharmacyAttributes>()
export const preferredPharmacySearchReset = createEvent<void>()

export const selectPharmacyFx = createEffect<SelectedPharmacyAttributes, void>()

export const $isPendingSelection = selectPharmacyFx.pending
export const $preferredPharmacyCoordinates = createStore<Coordinates | null>(
  null,
).reset(preferredPharmacySearchReset)

export const $isShortList = createStore<boolean>(true).on(
  pharmaciesListExpanded,
  () => false,
)

const $pharmacies = drugSearchPharmaciesQuery.$data.map(
  (data) => data?.pharmacies ?? [],
)

export const $foundPharmacies = combine(
  $pharmacies,
  $isShortList,
  (pharmacies, isShortList) =>
    isShortList ? pharmacies.slice(0, INITIAL_LIST_LENGTH) : pharmacies,
)

export const $hasPharmacies = $pharmacies.map(
  (pharmacies) => pharmacies.length > 0,
)
export const $hasExtendOption = combine(
  $pharmacies,
  $isShortList,
  (pharmacies, isShortList) =>
    isShortList && pharmacies.length > INITIAL_LIST_LENGTH,
)

selectPharmacyFx.use(async ({ nabp, zipCode }) => {
  await client.mutate({
    mutation: PREFERRED_PHARMACY_MUTATION,
    variables: { nabp, zip_code: zipCode },
  })

  await client.refetchQueries({
    updateCache: (cache) => {
      cache.evict({ fieldName: 'pdsPrices' })
      cache.evict({ fieldName: 'pdsPreferredPharmacy' })
    },
  })
})

sample({
  source: preferredPharmacyChosen,
  target: selectPharmacyFx,
})
