import React, { memo, useCallback, useState } from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import useChatDeactivateThreadMutation from 'src/apollo/hooks/useChatDeactivateThreadMutation'
import ButtonCall40 from 'src/components/Buttons/ButtonCall40'
import ModalActionConfirmation from 'src/components/Modals/ModalActionConfirmation'
import NavigatorIconLightTheme from 'src/components/NavigatorIcons/NavigatorIconLightTheme'
import {
  Root,
  HeaderBox,
  NavigatorTitleBox,
  NavigatorTitle,
  NavigatorSubTitle,
  ButtonBackWrapper,
} from 'src/sections/SectionNavigator/styled'
import ButtonBack40 from 'src/shared/ui/buttons/ButtonBack40'
import { ButtonKebab24 } from 'src/shared/ui/buttons/ButtonKebab24'
import { KebabBoxShadow } from 'src/theme/baseStyles'
import { kebabText, primaryWhite, nativeSystemRed } from 'src/theme/colors'
import { textLink2_14 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function ChatThreadHeader({
  navigateToInbox,
  chatId,
  navigatorsSubject,
  isNavigatorOnline,
}) {
  const { t } = useTranslation()
  const i18n = {
    navigatorSubTitle: t('translation.chat.thread.navigator_sub_title'),
    kebabTitle: t('translation.chat.thread.kebab_title'),
    confirmTitle: t('translation.chat.thread.confirm_title'),
    confirmDescription: t('translation.chat.thread.confirm_description'),
    confirmDelete: t('translation.chat.thread.confirm_delete'),
    confirmCancel: t('translation.chat.thread.confirm_cancel'),
  }

  const [isShowKebabModal, setIsShowKebabModal] = useState(false)
  const [isShowModalConfirmation, setIsShowModalConfirmation] = useState(false)
  const { deactivateChatThread } = useChatDeactivateThreadMutation()

  const switchShowKebabModal = useCallback(() => {
    setIsShowKebabModal((prevState) => !prevState)
  }, [])

  const hideKebabModal = useCallback(() => {
    if (!isShowKebabModal) return
    setIsShowKebabModal(false)
  }, [isShowKebabModal])

  const handleRemoveChatThread = useCallback(() => {
    const { graphQLErrors } = deactivateChatThread(chatId)
    if (graphQLErrors?.message) {
      // eslint-disable-next-line
      console.log('Remove Chat Thread Error', graphQLErrors.message)
    }

    hideKebabModal()
    navigateToInbox()
  }, [chatId])

  return (
    <Root hasInboxTotalCount>
      <HeaderBox justifyContent>
        <ButtonBackWrapper>
          <ButtonBack40 onClick={navigateToInbox} />
        </ButtonBackWrapper>

        <NavigatorBox>
          <NavigatorIconLightTheme isPreventOnClick />

          <NavigatorTitleBox>
            <NavigatorTitle>{navigatorsSubject}</NavigatorTitle>

            {!!isNavigatorOnline && (
              <NavigatorSubTitle>{i18n.navigatorSubTitle}</NavigatorSubTitle>
            )}
          </NavigatorTitleBox>
        </NavigatorBox>

        <NavigatorHelp>
          <ButtonKebabWrapper
            tabIndex={0}
            onClick={switchShowKebabModal}
            onBlur={hideKebabModal}
          >
            <ButtonKebab24 />

            {isShowKebabModal && (
              <KebabModal>
                <KebabItemTitle
                  onClick={() => {
                    setIsShowModalConfirmation(true)
                  }}
                >
                  {i18n.kebabTitle}
                </KebabItemTitle>
              </KebabModal>
            )}
          </ButtonKebabWrapper>

          <ButtonCall40 withShadow />
        </NavigatorHelp>
      </HeaderBox>
      {isShowModalConfirmation && (
        <ModalActionConfirmation
          title={i18n.confirmTitle}
          description={i18n.confirmDescription}
          buttons={[
            {
              text: i18n.confirmDelete,
              onClick: handleRemoveChatThread,
              textColor: nativeSystemRed,
            },
            {
              text: i18n.confirmCancel,
              onClick: () => {
                setIsShowModalConfirmation(false)
              },
            },
          ]}
        />
      )}
    </Root>
  )
}

const ButtonKebabWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;
`
export const NavigatorHelp = styled.div`
  display: flex;
  align-items: baseline;
  margin-left: auto;
  position: relative;
`
const KebabModal = styled.div`
  position: absolute;
  top: ${convertPxToRem(65)};
  right: ${convertPxToRem(-10)};
  width: ${convertPxToRem(196)};
  background-color: ${primaryWhite};
  border-radius: ${convertPxToRem(8)};
  ${KebabBoxShadow};
  z-index: 1;
`
const KebabItemTitle = styled.div`
  padding: ${convertPxToRem(11)} ${convertPxToRem(16)};
  color: ${kebabText};
  cursor: pointer;
  ${textLink2_14};
`

const NavigatorBox = styled.div`
  display: flex;
  padding: 0 ${convertPxToRem(10)};
`
ChatThreadHeader.propTypes = {
  navigateToInbox: PropTypes.func.isRequired,
  chatId: PropTypes.string.isRequired,
  navigatorsSubject: PropTypes.string.isRequired,
  isNavigatorOnline: PropTypes.bool.isRequired,
}

export default memo(ChatThreadHeader)
