import { memo, useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import useChatsQuery from 'src/apollo/hooks/useChatsQuery'
import Spinner from 'src/components/Spinner'
import { ConversationIcon } from 'src/components/SvgIcons/ConversationIcon'
import { SearchBar } from 'src/features/SearchBar'
import InboxList from 'src/sections/SectionNavigator/Inbox/InboxList'
import EmptyInbox from 'src/sections/SectionNavigator/NavigatorHeader/EmptyInbox'
import InboxHeader from 'src/sections/SectionNavigator/NavigatorHeader/InboxHeader'
import { SpinnerWrapper } from 'src/sections/SectionNavigator/styled'
import {
  primaryWhite,
  secondaryBackgroundGrey,
  secondaryGrey,
  secondaryLightGrey,
} from 'src/theme/colors'
import { heading3_25 } from 'src/theme/fonts'
import { DEBOUNCE } from 'src/utils/debounceHelpers'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function Inbox({
  navigateToChatThread,
  navigateToChatNew,
  hasInboxTotalCount,
  newMessagesIdsList,
  isLoadingData,
}) {
  const { t } = useTranslation()
  const i18n = {
    headerTitle: t('translation.chat.inbox.with_messages.title'),
    searchPlaceholder: t(
      'translation.chat.inbox.with_messages.input_placeholder',
    ),
  }

  const [searchValue, setSearchValue] = useState('')
  const [deferredValue, setDeferredValue] = useState('')

  const {
    inboxData,
    inboxPageInfo,
    inboxFetchMore,
    inboxDataLoading,
    inboxFetchMoreLoading,
  } = useChatsQuery({
    search: deferredValue,
    // When not searching, rely on parent element (Navigator) to fetch this data
    // Changing policy to anything except `cache-only` will create an infinite loop
    policy: deferredValue.length === 0 ? 'cache-only' : 'cache-and-network',
  })

  const hasSearchInboxData = !!inboxData.length

  useEffect(() => {
    const timer = setTimeout(
      () => setDeferredValue(searchValue),
      DEBOUNCE.DELAY_500,
    )

    return () => clearTimeout(timer)
  }, [searchValue])

  if (!hasInboxTotalCount)
    return (
      <EmptyInbox
        navigateToChatNew={navigateToChatNew}
        hasInboxTotalCount={hasInboxTotalCount}
        isLoadingData={isLoadingData}
      />
    )

  return (
    <>
      <InboxHeader
        hasInboxTotalCount={hasInboxTotalCount}
        navigateToChatNew={navigateToChatNew}
        isLoadingData={isLoadingData}
      />
      {isLoadingData && (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      )}
      {!isLoadingData && (
        <Root>
          <HeaderWrapper>
            <Header>
              <Icon>
                <ConversationIcon />
              </Icon>
              <Title>{i18n.headerTitle}</Title>
            </Header>

            <Search
              id="chat-search"
              autoComplete="off"
              onChange={setSearchValue}
              value={searchValue}
              placeholder={i18n.searchPlaceholder}
            />
          </HeaderWrapper>
          {inboxDataLoading && !hasSearchInboxData ? (
            <Spinner />
          ) : (
            <InboxList
              searchValue={deferredValue}
              hasSearchInboxData={hasSearchInboxData}
              inboxData={inboxData}
              navigateToChatThread={navigateToChatThread}
              newMessagesIdsList={newMessagesIdsList}
              inboxPageInfo={inboxPageInfo}
              inboxFetchMore={inboxFetchMore}
              inboxFetchMoreLoading={inboxFetchMoreLoading}
            />
          )}
        </Root>
      )}
    </>
  )
}

const Root = styled.div`
  display: flex;
  flex: 1;
  overflow-y: auto;
  flex-direction: column;
  width: 100%;
  border-top: ${convertPxToRem(1)} solid ${secondaryLightGrey};
`
const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 0 24px 0 36px;
  margin-bottom: ${convertPxToRem(16)};
`
const HeaderWrapper = styled.div`
  background-color: ${primaryWhite};
  padding: ${convertPxToRem(29)} 0 ${convertPxToRem(24)} 0;
`

const Search = styled(SearchBar)`
  width: calc(100% - ${convertPxToRem(48)});
  margin: ${convertPxToRem(0, 24)};

  background-color: ${secondaryBackgroundGrey};

  & > input::placeholder {
    color: ${secondaryGrey};
  }
`

const Icon = styled.div`
  margin-right: ${convertPxToRem(12)};
  background-position: center;
  background-repeat: no-repeat;
`
const Title = styled.h2`
  margin-right: ${convertPxToRem(6)};
  ${heading3_25}
`

Inbox.propTypes = {
  navigateToChatThread: PropTypes.func.isRequired,
  navigateToChatNew: PropTypes.func.isRequired,
  hasInboxTotalCount: PropTypes.bool.isRequired,
  newMessagesIdsList: PropTypes.arrayOf(PropTypes.number).isRequired,
  isLoadingData: PropTypes.bool.isRequired,
}

export default memo(Inbox)
