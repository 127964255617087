import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'

import useChatReplyMutation from 'src/apollo/hooks/useChatReplyMutation'
import useChatStartMutation from 'src/apollo/hooks/useChatStartMutation'
import ChatMessageList from 'src/sections/SectionNavigator/Chat/ChatMessageList'
import ChatPrimaryQuestions from 'src/sections/SectionNavigator/Chat/ChatPrimaryQuestions'
import { setPrefilledMessage } from 'src/store/slices/chats'
import { secondaryBackgroundGrey } from 'src/theme/colors'

import ChatActions from './ChatActions'

function Chat({
  isChatNew = false,
  chatId = '',
  chatData = {},
  navigateToChatThread = () => {},
  chatDataLoading = false,
  fetchMore,
  selectPrefilledMessage = () => {},
}) {
  const { handleCreateChat, chatStartLoading } = useChatStartMutation()
  const { handleChatReply, chatReplyLoading } = useChatReplyMutation()

  return (
    <Root>
      {isChatNew ? (
        <ChatPrimaryQuestions setMessage={selectPrefilledMessage} />
      ) : (
        <ChatMessageList
          chatData={chatData}
          chatReplyLoading={chatReplyLoading}
          chatDataLoading={chatDataLoading}
          fetchMore={fetchMore}
        />
      )}
      {!chatData?.isResolved && (
        <ChatActions
          isChatNew={isChatNew}
          existingChatId={chatId}
          handleCreateChat={handleCreateChat}
          handleChatReply={handleChatReply}
          navigateToChatThread={navigateToChatThread}
          loading={chatStartLoading || chatReplyLoading}
        />
      )}
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex: 1;
  overflow-y: auto;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  background: ${secondaryBackgroundGrey};
`

Chat.propTypes = {
  isChatNew: PropTypes.bool,
  chatDataLoading: PropTypes.bool,
  chatId: PropTypes.string,
  chatData: PropTypes.shape({}),
  navigateToChatThread: PropTypes.func,
  fetchMore: PropTypes.func.isRequired,
  selectPrefilledMessage: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => ({
  selectPrefilledMessage: (message) => dispatch(setPrefilledMessage(message)),
})

export default connect(null, mapDispatchToProps)(Chat)
