import { gql } from '@apollo/client'

import { APPOINTMENT_TIME_SLOT_FRAGMENTS } from '../timeSlots/document'

export const APPOINTMENT_QUERY = gql`
  query appointment($id: ID!) {
    appointment(id: $id) {
      ...appointment
    }
  }

  fragment appointment on Appointment {
    __typename

    id

    displayStatus: display_status
    status

    savedAddress: saved_address {
      ...doctorAddress
    }

    assignee: assigned_entity {
      ...appointmentDoctor
      ...appointmentFacility
    }

    timeSlots: time_slots {
      ...asapTime
      ...specificTime
      ...timeBlock

      ...suggestedTime
      ...pendingTime
      ...confirmedTime
      ...cancelledTime
    }
  }

  ${APPOINTMENT_TIME_SLOT_FRAGMENTS}

  fragment appointmentDoctor on AppointmentDoctor {
    __typename
    id

    firstName: first_name
    lastName: last_name
    image: image_url
    rating

    cost {
      ...appointmentCost
    }

    addresses {
      ...doctorAddress
    }

    specialties {
      displayName: display_name
    }
  }

  fragment appointmentFacility on AppointmentFacility {
    __typename
    id

    name
    rating
    phone

    ...facilityAddress

    cost {
      ...appointmentCost
    }
  }

  fragment doctorAddress on DoctorAddresses {
    address
    latitude
    longitude
    distance
    phone

    isPreferred: is_preferred
  }

  fragment facilityAddress on AppointmentFacility {
    address
    latitude
    longitude
    distance
  }

  fragment appointmentCost on Cost {
    userPays: user_pays
  }
`
