import styled from 'styled-components'

import {
  primaryDarkNavy,
  primaryWhite,
  secondaryBackgroundGrey,
  secondaryGrey,
} from 'src/theme/colors'
import { heading4_21, heading6_16, heading8_12 } from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 26px;
  background: ${({ hasInboxTotalCount }) =>
    hasInboxTotalCount ? `${secondaryBackgroundGrey}` : `${primaryWhite}`};
`
export const HeaderBox = styled.div`
  display: flex;
  min-height: ${convertPxToRem(40)};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? '' : 'space-between'};
  z-index: ${({ isCurrentTutorialStep }) => isCurrentTutorialStep && 2};
  background-color: ${({ isCurrentTutorialStep }) =>
    isCurrentTutorialStep && primaryWhite};
  border-radius: ${({ isCurrentTutorialStep }) =>
    isCurrentTutorialStep && convertPxToRem(44)};
  box-shadow: ${({ isCurrentTutorialStep }) =>
    isCurrentTutorialStep && `0 0 0 ${convertPxToRem(10)} ${primaryWhite}`};
`
export const ButtonCloseWrapper = styled.div`
  width: 50%;
  ${mediaQueryFor.desktop} {
    opacity: 0;
    cursor: default;
  }
`
export const NavigatorTitleBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 12px;
`

export const NavigatorTitle = styled.div`
  color: ${primaryDarkNavy};
  ${heading6_16}
`
export const NavigatorSubTitle = styled.div`
  padding-top: ${convertPxToRem(4)};
  color: ${secondaryGrey};
  ${heading8_12}
`
export const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: ${convertPxToRem(25)};
`
export const Title = styled.h1`
  color: ${primaryDarkNavy};
  margin-bottom: ${convertPxToRem(4)};
  ${heading4_21}
`
export const ButtonBackWrapper = styled.div``
export const SpinnerWrapper = styled.div`
  display: flex;
  flex: 1;
  background-color: ${primaryWhite};
  width: 100%;
  max-width: 375px;
`
