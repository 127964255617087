import React, { useCallback, useState } from 'react'

import RatingTooltip from 'src/sections/SectionCareLocator/components/RatingTooltip'

import { Appointment } from '../../api/appointmentQuery/types'
import { isDoctorAssignee } from '../../lib/doctor'

import { AppointmentDoctorInfo } from './AppointmentDoctorInfo'
import { AppointmentFacilityInfo } from './AppointmentFacilityInfo'

interface AppointmentAssigneeInfoProps {
  appointment: Appointment
}

export function AppointmentAssigneeInfo({
  appointment,
}: AppointmentAssigneeInfoProps) {
  const [isRatingTooltipDisplayed, setRatingTooltipDisplayed] = useState(false)

  const { assignee, savedAddress } = appointment

  const showRatingTooltip = useCallback(
    () => setRatingTooltipDisplayed(true),
    [setRatingTooltipDisplayed],
  )

  const isDoctor = isDoctorAssignee(assignee)

  return (
    <>
      {isDoctor ? (
        <AppointmentDoctorInfo
          doctor={assignee}
          savedAddress={savedAddress}
          showRatingTooltip={showRatingTooltip}
        />
      ) : (
        <AppointmentFacilityInfo
          facility={assignee}
          showRatingTooltip={showRatingTooltip}
        />
      )}

      {isRatingTooltipDisplayed && (
        <RatingTooltip setIsVisible={setRatingTooltipDisplayed} />
      )}
    </>
  )
}
