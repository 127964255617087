export interface IconsMap {
  doctor:
    | 'allergist'
    | 'back_pain'
    | 'blood_test'
    | 'bone_density_test'
    | 'chiropractor'
    | 'dentist'
    | 'dermatologist'
    | 'endocrinologist'
    | 'gastroenterology'
    | 'general'
    | 'gynecologist'
    | 'mri'
    | 'neurology'
    | 'ophthalmology'
    | 'orthodontist'
    | 'orthopedics'
    | 'pediatrician'
    | 'physical_therapy'
    | 'podiatrist'
    | 'primary_care_doctor'
    | 'psychiatrist'
    | 'radiology'
    | 'specialist'
    | 'urgent_care'
    | 'wellness_visit'
  facility:
    | 'dental_care_facilities'
    | 'dialysis'
    | 'emergency_rooms'
    | 'hospitals'
    | 'long_term_care_facilities'
    | 'offices_and_clinics'
    | 'pharmacies'
    | 'physical_therapy_and_chiropractors'
    | 'rehabilitation_centers'
    | 'testing_and_diagnostics'
    | 'urgent_care'
    | 'vision_care_facilities'
  legacy:
    | 'billing_advocacy'
    | 'options_for_care'
    | 'primary_care_provider'
    | 'question_mark'
  misc:
    | 'attachment'
    | 'call'
    | 'chat'
    | 'chevron'
    | 'clipboard'
    | 'clock'
    | 'conversation'
    | 'exclamation_mark'
    | 'external'
    | 'file_image'
    | 'file_pdf'
    | 'globe'
    | 'health_profile'
    | 'heart'
    | 'hospital'
    | 'mail'
    | 'pencil'
    | 'phone_rotary'
    | 'physician_experience'
    | 'pill'
    | 'question'
    | 'rightway_text_logo'
    | 'rightway'
    | 'schedule'
    | 'search'
    | 'send'
    | 'star'
    | 'truck'
    | 'xmark'
  nav: 'back' | 'close' | 'drag_line' | 'more' | 'swipe'
  pbm: 'care_complete' | 'medication'
  settings: 'dependents' | 'feedback' | 'launch' | 'sign_out' | 'wellness'
}
export const IconsMeta: {
  [Key in keyof IconsMap]: {
    filePath: string
    items: Record<
      IconsMap[Key],
      {
        viewBox: string
        width: number
        height: number
      }
    >
  }
} = {
  doctor: {
    filePath: 'doctor.svg',
    items: {
      allergist: {
        viewBox: '0 0 40 40',
        width: 40,
        height: 40,
      },
      back_pain: {
        viewBox: '0 0 480 480',
        width: 480,
        height: 480,
      },
      blood_test: {
        viewBox: '0 0 40 40',
        width: 40,
        height: 40,
      },
      bone_density_test: {
        viewBox: '0 0 480 480',
        width: 480,
        height: 480,
      },
      chiropractor: {
        viewBox: '0 0 40 40',
        width: 40,
        height: 40,
      },
      dentist: {
        viewBox: '0 0 40 40',
        width: 40,
        height: 40,
      },
      dermatologist: {
        viewBox: '0 0 480 480',
        width: 480,
        height: 480,
      },
      endocrinologist: {
        viewBox: '0 0 40 40',
        width: 40,
        height: 40,
      },
      gastroenterology: {
        viewBox: '0 0 40 40',
        width: 40,
        height: 40,
      },
      general: {
        viewBox: '0 0 40 40',
        width: 40,
        height: 40,
      },
      gynecologist: {
        viewBox: '0 0 40 40',
        width: 40,
        height: 40,
      },
      mri: {
        viewBox: '0 0 480 480',
        width: 480,
        height: 480,
      },
      neurology: {
        viewBox: '0 0 40 40',
        width: 40,
        height: 40,
      },
      ophthalmology: {
        viewBox: '0 0 40 40',
        width: 40,
        height: 40,
      },
      orthodontist: {
        viewBox: '0 0 40 40',
        width: 40,
        height: 40,
      },
      orthopedics: {
        viewBox: '0 0 40 40',
        width: 40,
        height: 40,
      },
      pediatrician: {
        viewBox: '0 0 40 40',
        width: 40,
        height: 40,
      },
      physical_therapy: {
        viewBox: '0 0 40 40',
        width: 40,
        height: 40,
      },
      podiatrist: {
        viewBox: '0 0 40 40',
        width: 40,
        height: 40,
      },
      primary_care_doctor: {
        viewBox: '0 0 40 40',
        width: 40,
        height: 40,
      },
      psychiatrist: {
        viewBox: '0 0 40 40',
        width: 40,
        height: 40,
      },
      radiology: {
        viewBox: '0 0 40 40',
        width: 40,
        height: 40,
      },
      specialist: {
        viewBox: '0 0 40 40',
        width: 40,
        height: 40,
      },
      urgent_care: {
        viewBox: '0 0 40 40',
        width: 40,
        height: 40,
      },
      wellness_visit: {
        viewBox: '0 0 40 40',
        width: 40,
        height: 40,
      },
    },
  },
  facility: {
    filePath: 'facility.svg',
    items: {
      dental_care_facilities: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      dialysis: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      emergency_rooms: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      hospitals: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      long_term_care_facilities: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      offices_and_clinics: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      pharmacies: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      physical_therapy_and_chiropractors: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      rehabilitation_centers: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      testing_and_diagnostics: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      urgent_care: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      vision_care_facilities: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
    },
  },
  legacy: {
    filePath: 'legacy.svg',
    items: {
      billing_advocacy: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      options_for_care: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      primary_care_provider: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      question_mark: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
    },
  },
  misc: {
    filePath: 'misc.svg',
    items: {
      attachment: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      call: {
        viewBox: '0 0 20 20',
        width: 24,
        height: 24,
      },
      chat: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      chevron: {
        viewBox: '0 0 12 12',
        width: 12,
        height: 12,
      },
      clipboard: {
        viewBox: '0 0 18 24',
        width: 18,
        height: 24,
      },
      clock: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      conversation: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      exclamation_mark: {
        viewBox: '0 0 8 8',
        width: 8,
        height: 8,
      },
      external: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      file_image: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      file_pdf: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      globe: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      health_profile: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      heart: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      hospital: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      mail: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      pencil: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      phone_rotary: {
        viewBox: '0 0 25 24',
        width: 25,
        height: 24,
      },
      physician_experience: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      pill: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      question: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      rightway_text_logo: {
        viewBox: '0 0 95 26',
        width: 95,
        height: 26,
      },
      rightway: {
        viewBox: '0 0 100 100',
        width: 100,
        height: 100,
      },
      schedule: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      search: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      send: {
        viewBox: '0 0 18 18',
        width: 18,
        height: 18,
      },
      star: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      truck: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      xmark: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
    },
  },
  nav: {
    filePath: 'nav.svg',
    items: {
      back: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      close: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      drag_line: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      more: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      swipe: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
    },
  },
  pbm: {
    filePath: 'pbm.svg',
    items: {
      care_complete: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      medication: {
        viewBox: '0 0 43 34',
        width: 3.81,
        height: 2.88,
      },
    },
  },
  settings: {
    filePath: 'settings.svg',
    items: {
      dependents: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      feedback: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      launch: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      sign_out: {
        viewBox: '0 0 18 18',
        width: 18,
        height: 18,
      },
      wellness: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
    },
  },
}
