import styled from 'styled-components'

import PdfIcon from 'src/components/SvgIcons/PdfIcon'
import PlaceholderImageIcon from 'src/components/SvgIcons/PlaceholderImageIcon'
import { primaryDarkNavy } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface ChatAttachmentWrapperProps {
  isPdf: boolean
  isUnavailableFile: boolean
  fileSrc: string | null
}

function ChatAttachmentWrapper({
  isPdf,
  isUnavailableFile,
  fileSrc,
}: ChatAttachmentWrapperProps) {
  if (isPdf) {
    return (
      <PdfIcon
        fill={primaryDarkNavy}
        width={convertPxToRem(40)}
        height={convertPxToRem(40)}
      />
    )
  }

  if (isUnavailableFile) {
    return (
      <PlaceholderImageIcon
        fill={primaryDarkNavy}
        width={convertPxToRem(40)}
        height={convertPxToRem(40)}
      />
    )
  }

  if (fileSrc) {
    return <Attachment src={fileSrc} alt="" data-test="attachment" />
  }

  return null
}

const Attachment = styled.img`
  max-height: ${convertPxToRem(130)};
  max-width: 100%;
`

export default ChatAttachmentWrapper
