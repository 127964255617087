import { attach, createStore, sample } from 'effector'
import { and, not } from 'patronum'

import { ROUTES } from 'src/constants/routesConstants'
import { $isDrugCovered } from 'src/entities/drugInfo'
import { remap } from 'src/shared/lib/effector/remap'
import { $$drugSearchConfigurationStorage } from 'src/shared/storage/drugSearch'

import {
  $currentConfigState,
  DrugSearchConfigGate,
  configFields,
  defaultValidationState,
  drugSearchConfigApplied,
  drugSearchConfigChanged,
  openNextScreenFx,
} from './core'
import { ConfigType, ErrorKeys, ValidationErrors } from './types'

export const $validationErrors = createStore<ValidationErrors>(
  defaultValidationState,
).reset(DrugSearchConfigGate.close)

const $hasValidationError = $validationErrors.map((errors) =>
  Object.values(errors).some(Boolean),
)

const validators = {
  required: (value: string) => (value.trim() ? null : ErrorKeys.Required),
  minMax: (value: string) => {
    if (Number(value) < 1 && value !== '') return ErrorKeys.Min
    if (Number(value) > 9999) return ErrorKeys.Max
    return null
  },
}

// ToDo: refactor this to Zod
export const validationFx = attach({
  source: {
    [ConfigType.DaysSupply]: configFields.$daysSupply,
    [ConfigType.DaysSupplyCustom]: configFields.$daysSupplyCustom,
    [ConfigType.Form]: configFields.$form,
    [ConfigType.Dosage]: configFields.$dosage,
    [ConfigType.TypeOfQuantity]: configFields.$typeOfQuantity,
    [ConfigType.Quantity]: configFields.$quantity,
  },
  effect: (values) => {
    const keys = Object.keys(values) as ConfigType[]
    /* eslint-disable no-param-reassign */
    return keys.reduce(
      (acc, key) => {
        if (key === ConfigType.DaysSupplyCustom) {
          acc[key] =
            validators.required(values[key]) ?? validators.minMax(values[key])
        } else {
          acc[key] = validators.required(values[key])
        }
        return acc
      },
      { ...defaultValidationState },
    )
    /* eslint-enable no-param-reassign */
  },
})

sample({
  clock: drugSearchConfigApplied,
  target: validationFx,
})

sample({
  clock: validationFx.doneData,
  target: $validationErrors,
})

sample({
  clock: drugSearchConfigChanged,
  source: $validationErrors,
  filter: (_, { type }) => type !== ConfigType.DaysSupply,
  fn: (errors, { type }) => ({ ...errors, [type]: null }),
  target: $validationErrors,
})

sample({
  clock: configFields.$daysSupply,
  source: $validationErrors,
  fn: (errors) => ({
    ...errors,
    [ConfigType.DaysSupply]: null,
    [ConfigType.DaysSupplyCustom]: null,
  }),
  target: $validationErrors,
})

sample({
  clock: drugSearchConfigChanged,
  source: $validationErrors,
  fn: (errors, { type }) => ({ ...errors, [type]: null }),
  target: $validationErrors,
})

sample({
  clock: validationFx.doneData,
  source: {
    drugName: remap(DrugSearchConfigGate.state, 'drugName'),
    config: $currentConfigState,
    isDrugCovered: $isDrugCovered,
  },
  filter: not($hasValidationError),
  target: $$drugSearchConfigurationStorage.configSaved,
})

sample({
  clock: validationFx.doneData,
  filter: and(DrugSearchConfigGate.status, not($hasValidationError)),
  target: openNextScreenFx.prepend(() => ({
    to: `/${ROUTES.DRUG_SEARCH_PATH}/${ROUTES.DRUG_SEARCH_DETAILS}`,
  })),
})
