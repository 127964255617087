import { gql } from '@apollo/client'

const CHAT_DEACTIVATE_THREAD_MUTATION = gql`
  mutation deactivateChatMutation($id: ID!) {
    deactivateChat(id: $id) {
      id
    }
  }
`

export default CHAT_DEACTIVATE_THREAD_MUTATION
