import { secondaryGrey } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { IconProps } from './types'

export function ConversationIcon({
  width = convertPxToRem(24),
  height = convertPxToRem(24),
  fill = secondaryGrey,
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5 10.5H18.75C18.8881 10.5 19 10.3881 19 10.25V5.5C18.9972 3.01586 16.9841 1.00276 14.5 1H4.5C2.01586 1.00276 0.00275556 3.01586 0 5.5V9.5C0 11.9853 2.01472 14 4.5 14H5V17.5C5.00024 17.7081 5.12937 17.8943 5.3242 17.9675C5.51903 18.0407 5.73882 17.9855 5.876 17.829L9.152 14.086C9.19908 14.0312 9.26777 13.9998 9.34 14H10.34C10.4612 14.0004 10.5651 13.9134 10.586 13.794C10.9229 11.8943 12.5706 10.5076 14.5 10.5Z"
        fill={fill}
      />
      <path
        d="M24 14.5C24 13.837 23.7366 13.2011 23.2678 12.7322C22.7989 12.2634 22.163 12 21.5 12H14.5C13.1193 12 12 13.1193 12 14.5V17.5C12 18.8807 13.1193 20 14.5 20H16.293L19.146 22.854C19.289 22.9974 19.5044 23.0403 19.6915 22.9628C19.8785 22.8852 20.0004 22.7025 20 22.5V20H21.5C22.163 20 22.7989 19.7366 23.2678 19.2678C23.7366 18.7989 24 18.163 24 17.5V14.5Z"
        fill={fill}
      />
    </svg>
  )
}
