import React, { useCallback, useMemo } from 'react'

import styled from 'styled-components'

import { LargeMap, LargeMapHookParams } from 'src/features/LargeMap'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { Appointment } from '../api/appointmentQuery/types'
import { isDoctorAssignee, pickDoctorMarker } from '../lib/doctor'
import { pickFacilityMarker } from '../lib/facility'

import { AppointmentMapMarker } from './AppointmentMapMarker'

interface AppointmentMapProps {
  appointment: Appointment
}

export function AppointmentMap({ appointment }: AppointmentMapProps) {
  const { assignee, savedAddress } = appointment

  const markers = useMemo(
    () =>
      isDoctorAssignee(assignee)
        ? [pickDoctorMarker(assignee, savedAddress)]
        : [pickFacilityMarker(assignee)],
    [assignee, savedAddress],
  )

  const onMapLoaded = useCallback(({ map, center }: LargeMapHookParams) => {
    map.panTo(center)
  }, [])

  return (
    <StyledMap
      markers={markers}
      onLoaded={onMapLoaded}
      element={<AppointmentMapMarker />}
    />
  )
}

const StyledMap = styled(LargeMap)`
  width: 100%;
  height: 100%;

  ${mediaQueryFor.desktop} {
    border-radius: ${convertPxToRem(20, 20, 0, 0)};
  }
`
