import React, { memo, useCallback } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import useChatReadMutation from 'src/apollo/hooks/useChatReadMutation'
import AttachFileIcon from 'src/components/SvgIcons/AttachFileIcon'
import ActiveTag from 'src/sections/SectionNavigator/Inbox/ActiveTag'
import { ATTACHMENT_IMAGE_TYPE } from 'src/sections/SectionNavigator/navigatorHelpers'
import { ButtonNavigateTo16 } from 'src/shared/ui/buttons/ButtonNavigateTo16'
import {
  primaryDarkNavy,
  primaryWhite,
  secondaryGrey,
  secondaryLightGrey,
  primaryLightBlue,
  featurePaleBlueRGBA,
} from 'src/theme/colors'
import {
  heading6_16,
  heading6_16_Medium,
  text4_10,
  textLink2_14,
} from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

const i18n = {
  image: 'Image',
  document: 'Document',
}
function InboxItem({
  item = {},
  navigateToChatThread = () => {},
  newMessagesIdsList = [],
  nextMessageId,
}) {
  const {
    chatId,
    subject,
    lastMessage: { lastTimeUpdated, messageBody, attachment },
    isResolved,
  } = item
  const isMessageNew = newMessagesIdsList.includes(chatId)
  const isNextMessageNew = newMessagesIdsList.includes(nextMessageId)

  const isTwoNewMessagesInRow = isMessageNew && isNextMessageNew
  const isTwoReadMessagesInRow = !isMessageNew && !isNextMessageNew
  const isAddBorderBottom = isTwoNewMessagesInRow || isTwoReadMessagesInRow

  const { handleChatRead } = useChatReadMutation()

  const handleClick = useCallback(async () => {
    await handleChatRead(chatId)
    navigateToChatThread(chatId)
    // eslint-disable-next-line
  }, [handleChatRead, navigateToChatThread])

  const isImage = attachment?.contentType?.includes(ATTACHMENT_IMAGE_TYPE)
  return (
    <Root
      onClick={handleClick}
      isMessageNew={isMessageNew}
      isAddBorderBottom={isAddBorderBottom}
    >
      <MessageBox>
        <HeaderBox>
          <ReadStatusBox>
            <Title isMessageNew={isMessageNew}>{subject}</Title>
            {(isMessageNew || isResolved) && (
              <ActiveTag isMessageNew={isMessageNew} isResolved={isResolved} />
            )}
          </ReadStatusBox>
          <TimeBox>
            <LastUpdate>{lastTimeUpdated}</LastUpdate>
            <ButtonNavigateTo16 fill={primaryLightBlue} />
          </TimeBox>
        </HeaderBox>
        {messageBody ? (
          <Message>{messageBody}</Message>
        ) : (
          <Attachment>
            <AttachFileIcon
              height={convertPxToRem(16)}
              width={convertPxToRem(16)}
              fill={primaryDarkNavy}
            />
            <AttachmentType>
              {isImage ? i18n.image : i18n.document}
            </AttachmentType>
          </Attachment>
        )}
      </MessageBox>
    </Root>
  )
}

export default memo(InboxItem)

InboxItem.propTypes = {
  item: PropTypes.shape({
    lastMessage: PropTypes.shape({
      messageBody: PropTypes.string,
      lastTimeUpdated: PropTypes.string,
      attachment: PropTypes.shape({}),
    }),
    subject: PropTypes.string,
    chatId: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    isResolved: PropTypes.bool.isRequired,
  }),
  newMessagesIdsList: PropTypes.arrayOf(PropTypes.string),
  navigateToChatThread: PropTypes.func,
  nextMessageId: PropTypes.string.isRequired,
}

const Root = styled.li`
  display: flex;
  flex-shrink: 0;
  padding: ${convertPxToRem(18)} 16px ${convertPxToRem(20)} 24px;
  background-color: ${({ isMessageNew }) =>
    isMessageNew ? featurePaleBlueRGBA : primaryWhite};
  cursor: pointer;
  border-bottom: ${({ isAddBorderBottom }) =>
    isAddBorderBottom && `${convertPxToRem(1)} solid ${secondaryLightGrey}`};
`
const MessageBox = styled.div`
  width: 100%;
`
const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${convertPxToRem(6)};
`
const ReadStatusBox = styled.div`
  display: flex;
  align-items: center;
`
const Title = styled.h3`
  color: ${primaryDarkNavy};
  ${({ isMessageNew }) => (isMessageNew ? heading6_16 : heading6_16_Medium)};
`
const TimeBox = styled.div`
  display: flex;
  align-items: center;
`
const LastUpdate = styled.div`
  color: ${secondaryGrey};
  margin-right: ${convertPxToRem(1)};
  ${text4_10};
`
const Message = styled.div`
  height: ${convertPxToRem(48)};
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: ${secondaryGrey};
  ${textLink2_14};
`
const Attachment = styled.div`
  display: flex;
  align-items: center;
  color: ${secondaryGrey};
`
const AttachmentType = styled.span`
  margin-left: ${convertPxToRem(10)};
  ${textLink2_14};
`
