import { useEffect } from 'react'

import { useUnit } from 'effector-react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import {
  ChatSectionFlag,
  ThirdSectionChats,
} from 'src/pages/thirdSection/Chats'
import GuidesPhoneNumbersModal from 'src/sections/SectionDashboard/GuidesPhoneNumbersModal'
import SectionNavigator from 'src/sections/SectionNavigator'
import { Darkly } from 'src/shared/lib/darkly'
import { RootState } from 'src/store'
import { THIRD_SECTION_CONTENT } from 'src/store/slices/common'
import { ModalBase } from 'src/theme/baseStyles'
import { primaryWhite } from 'src/theme/colors'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface ThirdSectionProps {
  withModal: boolean
  content: (typeof THIRD_SECTION_CONTENT)[keyof typeof THIRD_SECTION_CONTENT]
}

function ThirdSection({ withModal, content }: ThirdSectionProps) {
  const aquire = useUnit(ChatSectionFlag.aquire)

  useEffect(() => void aquire(), [aquire])

  return <Root data-modal={withModal}>{Content[content] ?? null}</Root>
}

const Content = {
  [THIRD_SECTION_CONTENT.CHAT]: (
    <Darkly.Choose
      flag={ChatSectionFlag}
      enabled={<ThirdSectionChats />}
      disabled={<SectionNavigator />}
    />
  ),
  [THIRD_SECTION_CONTENT.SELECT_PHONE]: <GuidesPhoneNumbersModal />,
}

const Root = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  max-width: ${convertPxToRem(375)};
  background-color: ${primaryWhite};

  ${mediaQueryFor.tabletOrLess} {
    display: none;

    &[data-modal='true'] {
      // TODO: switch to block after refactoring
      display: flex;
      max-width: 100%;

      ${ModalBase};
    }
  }
`

const mapStateToProps = ({ common }: RootState) => ({
  withModal: common.isShowThirdSectionModal,
  content: common.thirdSectionContent,
})

export default connect(mapStateToProps, null)(ThirdSection)
