import { useCallback, useEffect } from 'react'

import { assocPath, isEmpty, path, pathOr, uniqBy } from 'ramda'

import { checkIsEndScrollReached } from 'src/utils/scrollHelpers'

const SCROLL_GAP = 150

const usePagination = ({
  dataPath,
  fetchMore,
  pageInfo,
  scrollRef,
  fetchMoreLoading,
  isReversePagination,
}) => {
  const { hasNextPage, endCursor } = pageInfo
  // Scroll handler
  const handleScroll = useCallback(
    (event) => {
      const isEndScrollReached = checkIsEndScrollReached(
        event,
        SCROLL_GAP,
        isReversePagination,
      )

      const isFetchMore = hasNextPage && !fetchMoreLoading && isEndScrollReached

      if (isFetchMore) {
        handleFetchMore({ fetchMore, dataPath, endCursor })
      }
    },
    [hasNextPage, endCursor, fetchMoreLoading],
  )

  // Scroll listener
  useEffect(() => {
    if (isEmpty(pageInfo)) return () => {}
    if (!scrollRef) return () => {}

    scrollRef.addEventListener('scroll', handleScroll)
    return () => scrollRef.removeEventListener('scroll', handleScroll)
  }, [handleScroll, scrollRef, pageInfo])
}

export default usePagination

const handleFetchMore = async ({ fetchMore, dataPath, endCursor }) => {
  try {
    fetchMore({
      variables: { cursor: endCursor },
      updateQuery: (currentCache, { fetchMoreResult }) => {
        let nextCache = currentCache
        const pageInfo = pathOr({}, [...dataPath, 'pageInfo'], fetchMoreResult) // get newPageInfo
        const getNodes = pathOr([], [...dataPath, 'nodes']) // merge currentNodes & newNodes
        const nodes = uniqBy(path(['id']), [
          ...getNodes(currentCache),
          ...getNodes(fetchMoreResult),
        ])
        nextCache = assocPath([...dataPath, 'pageInfo'], pageInfo, nextCache) // set newPageInfo
        nextCache = assocPath([...dataPath, 'nodes'], nodes, nextCache) // set newNodes

        return nextCache
      },
    })
  } catch (err) {
    // Ignoring Invariant Violations, anything else gets logged
    console.assert(err.name === 'Invariant Violation', err)
  }
}
