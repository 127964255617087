import { reflect } from '@effector/reflect'
import { useNavigate } from '@reach/router'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { ROUTES } from 'src/constants/routesConstants'
import { $currentDrugConfig } from 'src/entities/drugDetails'
import { remap } from 'src/shared/lib/effector/remap'
import {
  secondaryBackgroundGrey,
  primaryBlue,
  secondaryGrey,
} from 'src/theme/colors'
import { text2_14, textLink2_14 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { DrugSearchDetailsGate } from '../../model'

interface DrugDetailsSearchProps {
  days: string
  dosage: string
  drugName: string
}

function Search({ days, dosage, drugName }: DrugDetailsSearchProps) {
  const { t } = useTranslation()
  const i18n = {
    title: t('translation.medications.drug_search.labels.drug_price_header', {
      days,
      dosage,
      interpolation: { escapeValue: false },
    }),
    edit: t('translation.shared.buttons.edit'),
  }

  const navigate = useNavigate()

  const goToConfigurationScreen = () => {
    void navigate(ROUTES.DRUG_SEARCH_CONFIGURE_PATH, {
      state: { isPreconfigured: true, drugName },
    })
  }

  return (
    <Root>
      <span>{i18n.title}</span>
      <Edit
        onClick={goToConfigurationScreen}
        data-test="drugDetails.editConfiguration"
      >
        {i18n.edit}
      </Edit>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: ${convertPxToRem(9, 16)};

  border-radius: ${convertPxToRem(8)};
  background: ${secondaryBackgroundGrey};

  color: ${secondaryGrey};
  ${text2_14};
`

const Edit = styled.button`
  background: none;
  border: none;
  color: ${primaryBlue};
  ${textLink2_14};
`

export const DrugDetailsSearch = reflect({
  view: Search,
  bind: {
    days: $currentDrugConfig.map(({ config }) => config.daysSupplyCustom),
    dosage: $currentDrugConfig.map(({ config }) => config.dosage),
    drugName: remap(DrugSearchDetailsGate.state, 'drugName'),
  },
})
