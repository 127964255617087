import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { IconProps } from './types'

export function ConsumerRatingsIcon({
  width = convertPxToRem(17),
  height = convertPxToRem(17),
  fill = 'none',
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 17 17"
    >
      <path
        fill={fill}
        d="M7.648.274c-.606-.454-1.466-.331-1.92.274L3.386 3.675l-.529-.527c-.541-.49-1.372-.47-1.889.046-.517.516-.539 1.347-.05 1.89l1.646 1.645c.284.277.672.42 1.067.391.395-.028.76-.224 1-.538l3.292-4.389c.454-.606.33-1.465-.275-1.92zM7.648 10.149c-.606-.454-1.465-.331-1.92.274L3.387 13.55l-.53-.527c-.344-.357-.854-.5-1.333-.374-.48.126-.854.5-.98.98-.125.48.018.99.375 1.334l1.646 1.645c.284.276.673.417 1.067.388.395-.029.759-.225 1-.54l3.292-4.388c.453-.606.33-1.465-.276-1.92zM15.605 3.839h-4.938c-.757 0-1.371.614-1.371 1.371 0 .758.614 1.372 1.371 1.372h4.938c.757 0 1.372-.614 1.372-1.372 0-.757-.615-1.371-1.372-1.371zM15.605 12.617h-4.938c-.757 0-1.371.614-1.371 1.371 0 .758.614 1.372 1.371 1.372h4.938c.757 0 1.372-.614 1.372-1.372 0-.757-.615-1.371-1.372-1.371z"
      />
    </svg>
  )
}
