import { useCallback } from 'react'

import { useMutation } from '@apollo/client'
// need to return when we find a solution for problems with cache (NEX-5944)
// import moment from 'moment'
import { assocPath, uniqBy, path } from 'ramda'

import CHAT_REPLY_MUTATION from 'src/apollo/mutations/CHAT_REPLY_MUTATION'
import CHAT_QUERY from 'src/apollo/queries/CHAT_QUERY'
import { checkIsHasMessages } from 'src/sections/SectionNavigator/navigatorHelpers'

const useChatReplyMutation = () => {
  const [chatReplyM, { loading }] = useMutation(CHAT_REPLY_MUTATION)

  const handleChatReply = useCallback(
    async (variables, isChatNew) => {
      const chatId = variables.id
      // need to return when we find a solution for problems with cache (NEX-5944)
      // const { body } = variables.attributes

      // Since we don't know which will be the ID (primary key for apollo cache store)
      // we'll use fake one for optimistic response
      // will drop that cache record, once mutation will return response

      // need to return when we find a solution for problems with cache (NEX-5944)
      // const formattedTime = moment().utc().format()
      // const timestamp = moment().unix()
      // const fakeID = `NEW_MSG_${timestamp}_${chatId}`

      // since we use Apollo, the automatic transactions and traces creation does not work

      await chatReplyM(
        isChatNew
          ? {
              variables,
            }
          : {
              variables,
              // need to return when we find a solution for problems with cache (NEX-5944)
              // optimisticResponse: {
              //   replyToChat: {
              //     __typename: 'ReplyToChatPayload',
              //     errors: [],
              //     message: {
              //       __typename: 'ChatMessage',
              //       body,
              //       id: fakeID,
              //       is_self_sent: true,
              //       attachment: null,
              //       created_at: formattedTime,
              //       updated_at: formattedTime,
              //       receiver_read_at: formattedTime,
              //     },
              //   },
              // },
              update: (currentCache, { data: { replyToChat: newMessage } }) => {
                const chatQueryParams = {
                  query: CHAT_QUERY,
                  variables: {
                    id: chatId,
                    first: 15,
                  },
                }
                // Read the data from our cache for this query.
                const data = currentCache.readQuery(chatQueryParams)

                if (!checkIsHasMessages(newMessage, data)) return
                // Add our comment from the mutation to the end.
                const newNodes = [newMessage, ...data.chat.messages.nodes]
                // Just check for uniqueness since subscription could return this message already!
                const uniqNodes = uniqBy(path(['id']), newNodes)
                // Write our data back to the cache.
                const newData = assocPath(
                  ['chat', 'messages', 'nodes'],
                  uniqNodes,
                  data,
                )
                currentCache.writeQuery({ ...chatQueryParams, data: newData })
              },
            },
      ).catch((error) => {
        // eslint-disable-next-line
        console.log(error)
      })
    },
    [chatReplyM],
  )

  return { handleChatReply, chatReplyLoading: loading }
}
export default useChatReplyMutation
